import React from "react";

// Customizable Area Start
import SplashscreenController, { Props } from "./SplashscreenController";

import {
  createTheme,
  ThemeProvider,
  styled,
  withStyles,
} from "@material-ui/core/styles";
import { Box, Drawer, IconButton, Typography } from "@material-ui/core";
import { backgroundMobile, logo } from "../../customform/src/assets";
import { closeIcon, noInternetIcon, splashLoader } from "./assets";
export const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainContainer>
          <img
            style={webStyle.webLogo}
            src={logo}
            alt={configJSON.webLogoAlt}
          />
          <img
            style={webStyle.splashLoader}
            src={splashLoader}
            alt={configJSON.splashLoaderAlt}
          />
          <CustomDrawer
            anchor="bottom"
            open={this.state.isDrawerOpen}
            onClose={this.toggleDrawer(false)}
          >
            <IconBtn
              aria-label="close"
              data-test-id="btnCloseNoInternetModal"
              onClick={this.toggleDrawer(false)}
            >
              <img src={closeIcon} alt={configJSON.closeIconAlt} />
            </IconBtn>
            <Box style={webStyle.bottomContainer}>
              <img
                style={webStyle.noInternetIcon}
                src={noInternetIcon}
                alt={configJSON.noInternetIconAlt}
              />
              <Typography style={webStyle.oopsNotInternet}>
                {configJSON.oopsNotInternet}
              </Typography>
              <Typography style={webStyle.pleaseTryAgain}>
                {configJSON.pleaseTryAgain}
              </Typography>
              <button style={webStyle.btnTryAgain}>
                {configJSON.btnTryAgain}
              </button>
            </Box>
          </CustomDrawer>
        </MainContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  webLogo: {
    width: "287px",
    height: "99.07px",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  splashLoader: {
    position: "absolute" as "absolute",
    bottom: "114px",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  bottomContainer: {
    width: "327px",
    margin: "auto",
    padding: "0 24px 32px 24px",
  },
  noInternetIcon: {
    width: "56px",
    height: "56px",
    margin: "56px 0 16px 0",
  },
  oopsNotInternet: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#1E293B",
  },
  pleaseTryAgain: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475569",
    margin: "8px 0 40px 0",
  },
  btnTryAgain: {
    width: "327px",
    height: "56px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    color: "#FFFFFF",
    backgroundColor: "#395169",
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
  },
};

export const MainContainer = styled(Box)({
  maxWidth: "414px",
  background: `url(${backgroundMobile})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media only screen and (max-width: 414px)": {
    background: `url(${backgroundMobile})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
});

const IconBtn = styled(IconButton)({
  position: "absolute",
  right: 8,
  top: 8,
});

const CustomDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    maxWidth: "414px",
    borderTopLeftRadius: "24px",
    borderTopRightRadius: "24px",
  },
});
// Customizable Area End
