import React from 'react';
import { Box, Button, TextField, Typography, styled } from '@material-ui/core';

interface TimeInputProps {
  value: { hour: number; minute: number; period: 'AM' | 'PM' };
  onChange: (value: { hour: number; minute: number; period: 'AM' | 'PM' }) => void;
}

class TimeInput extends React.Component<TimeInputProps> {
  handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hour = e.target.value;
    if (hour === '' || (parseInt(hour, 10) >= 1 && parseInt(hour, 10) <= 12)) {
      this.props.onChange({ ...this.props.value, hour: hour === '' ? 0 : parseInt(hour, 10) });
    }
  };

  handleMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const minute = e.target.value;
    if (minute === '' || (parseInt(minute, 10) >= 0 && parseInt(minute, 10) <= 59)) {
      this.props.onChange({ ...this.props.value, minute: minute === '' ? 0 : parseInt(minute, 10) });
    }
  };

  handlePeriodChange = (period: 'AM' | 'PM') => {
    this.props.onChange({ ...this.props.value, period });
  };

  render() {
    const { value } = this.props;
    const formattedHour = value.hour === 0 ? '' : value.hour.toString();
    const formattedMinute = value.minute === 0 ? '' : value.minute.toString().padStart(2, '0');
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" alignItems="center" style={{ gap: '13px' }}>
          <TextFieldStyle
            value={formattedHour}
            onChange={this.handleHourChange} variant='outlined'
            inputProps={{ min: 1, max: 12, style: { appearance: 'textfield' } }}
            style={{ width: '47px', height: '28px' }}
          />
          <Typography style={{marginTop:'12px'}}>
            :
          </Typography>
          <TextFieldStyle
            value={formattedMinute}
            onChange={this.handleMinuteChange} variant='outlined'
            inputProps={{ min: 0, max: 59, style: { appearance: 'textfield' } }}
            style={{ width: '47px', height: '28px' }}
          />
          <Box display="flex" alignItems="center" style={{marginTop:'8px'}}>
            <Box
              onClick={() => this.handlePeriodChange('AM')}
              style={{ ...style.amBox, background: value.period === 'AM' ? '#2196F3' : '#fff', color: value.period === 'AM' ? "#fff" : "#424242", }}
            >
              AM
            </Box>
            <Box
              onClick={() => this.handlePeriodChange('PM')}
              style={{
                background: value.period === 'PM' ? '#2196F3' : '#fff', color: value.period === 'PM' ? "#fff" : "#424242",
                ...style.pmBox
              }}
            >
              PM
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default TimeInput;

const style = {
  amBox: {
    marginLeft: '13px',
    borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', padding: '10px',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '15px',
  },
  pmBox:{
    borderTopRightRadius: '4px', borderBottomRightRadius: '4px', padding: '10px',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '15px',
  }
}
const TextFieldStyle=styled(TextField)({
  "& .MuiOutlinedInput-input":{
    padding:'8px 14px'
  }
})