import { Box, Checkbox, Typography, styled } from '@material-ui/core';
import React, { Component } from 'react';

interface Option {
    id: number;
    label: string;
    checked: boolean;
}

interface Props {
    title: string;
    options: Option[];
    onCheckBoxChange: (index: number) => void;
    margin?: string;
}

class CheckBoxList extends Component<Props> {
    render() {
        const { title, options, onCheckBoxChange, margin } = this.props;
        const gridColumnCount = options.length > 4 ? 2 : 1;

        const MainBox = styled(Box)({
            display: 'flex',
            marginTop: margin,
            "@media only screen and (max-width: 798px)": {
                display: 'block',
                marginTop: '4px',
            },
        });

        const RightBox = styled(Box)({
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            "@media only screen and (max-width: 798px)": {
                gridTemplateColumns: `repeat(${gridColumnCount}, 1fr)`,
            },
        });

        return (
            <MainBox>
                <LeftBox>
                    <MainTitle>{title}</MainTitle>
                </LeftBox>
                <RightBox>
                    {options.map((option, index) => (
                        <CheckBoxContainer key={option.id}>
                            <Checkbox
                                id={option.label}
                                checked={option.checked}
                                onChange={() => onCheckBoxChange(index)}
                                style={webStyle.checkedPrivacyAndTerms}
                            />
                            <CheckBoxLabel>
                                {option.label}
                            </CheckBoxLabel>
                        </CheckBoxContainer>
                    ))}
                </RightBox>
            </MainBox>
        );
    }
}

const LeftBox = styled(Box)({
    width: '306px',
});

const MainTitle = styled(Typography)({
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    color: '#334155',
    "@media only screen and (max-width: 798px)": {
        marginBottom: '12px',
    },
    "@media only screen and (max-width: 414px)": {
        fontSize: '14px',
    },
});

const CheckBoxContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',
});

const CheckBoxLabel = styled(Typography)({
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    color: '#1E293B',
    "@media only screen and (max-width: 414px)": {
        color: '#0F172A',
    },
});

const webStyle = {
    checkedPrivacyAndTerms: {
        color: '#395169',
        marginRight: '16px',
        padding: '0px',
    },
};

export default CheckBoxList;
