import React from 'react';
import { Box, Typography } from '@material-ui/core';

interface Time {
  hour: number | string;
  minute: number | string;
  period: 'AM' | 'PM';
}

interface TimeDisplayProps {
  label: string;
  time: Time | undefined;
  onClick: () => void;
  testId: string;
}

const formatTime = (value: number | string) => {
  return value.toString().padStart(2, '0');
};

const TimeDisplay: React.FC<TimeDisplayProps> = ({ label, time, onClick, testId }) => (
  <Box data-test-id={testId} onClick={onClick} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
    <Typography style={webStyle.prefOption}>{label}</Typography>
    <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}>
      {`${formatTime(time?.hour || (label === 'Start time' ? 9 : 5))}:${formatTime(time?.minute || '00')} ${time?.period || (label === 'Start time' ? 'AM' : 'PM')}`}
    </Typography>
  </Box>
);

export default TimeDisplay;

const webStyle={
    prefOption: {
        color: '#1E293B',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
      },
}