Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account_block/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.pccLogoAlt = "pcc_logo";
exports.welcomeBackTxt = "Welcome back,";
exports.enterEmailAndPasswordTxt = "Enter your email and";
exports.toContinueTxt = "to continue...";
exports.emailPlaceholderTxt = "Enter email";
exports.enterTxt = "Enter";
exports.passTxt = "pass";
exports.wordTxt = "word";
exports.emailTxt = "Email";
exports.questionMarkTxt = "?";
exports.loginText = "Log in";
exports.dontHaveAccText = "Don’t have an account?";
exports.signupText = "Sign up";
exports.createAccountTxt = "Create account"
exports.aleadyHaveAccountTxt = "Already have an account?"
exports.alreadyhaveAccountloginText = "Login";
exports.mustIncludeTxt = "must include:";
exports.checkboxAlt = "checkbox_icon";
exports.passConditionCapitalTxt = 'At least one capital letter.';
exports.passConditionLowerTxt = 'At least one lowercase letter.';
exports.passConditionNumberTxt = 'At least one number.';
exports.passConditionLengthTxt = 'Minimum characters length is 8 characters.';
exports.privacyPolicyTxt = 'Privacy Policy';
exports.andTxt = 'and';
exports.termsOfUseTxt = "Terms of Use";
exports.policyAndTermsCheckboxTxt = "I hereby consent to the";
exports.dotTxt = "."
exports.commaTxt = ',';
exports.errorMsgPleaseEnterEmail = "*Please enter email address.";
exports.errorMsgInvalidEmail = "*Invalid email, please enter valid email.";
exports.invalidTxt = 'Invalid';
exports.starTxt = '*';
exports.errorMsgPleaseEnterPassword = `*Please enter account`;
exports.errorMsgInvalidPassword = 'please enter valid';
exports.checkBoxAlertMsg = "You have to agree with Privacy Policy and Terms of Use to Sign up.";
exports.somethingWentWrong = "Oops! Something went wrong on our end. Please try again later."

exports.mainHeading="Verification";
exports.subHeading="Please select your preferred verification method to continue..."
exports.email="Email"
exports.phone="Phone"
exports.continue="Continue"
exports.back="Back"
// Customizable Area End
