Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";


exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";

exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;

exports.mainHeading="School Admin Signup";
exports.subHeading="Please fill in below details to proceed...";
exports.memberId="Member Id"
exports.firstName="First Name"
exports.secondName="Last Name"
exports.organisation="Organization"
exports.street="Street Address"
exports.city="City"
exports.state="State"
exports.zipcode="Zip Code"
exports.Country="Country"
exports.phone="Business Phone Number"
exports.role="Role"
exports.employee="Number of Employees"
exports.program="Program type"
exports.aboutUs="How Did You Hear About Us?"
exports.capacity="Program Capacity"
exports.webLogoAlt = "web_logo";
exports.backIconAlt = "back_icon";
exports.backTxt = "Back";
exports.workPhoneNumberTxt = "Work Phone Number";
exports.preferredModalityTxt = "Preferred Modality";
exports.preferredAgeGrpTxt = "Preferred Age Group";
exports.preferredWorkingDays = "Preferred Working Days";
exports.preferredTimeShiftTxt = "Preferred  Time Shift";
exports.preferredNatureOfJobTxt = "Preferred  Nature of Job";
exports.preferredTravelDistanceTxt = "Preferred Travel Distance";
exports.documentSharingPreferenceTxt = "Document Sharing Preference";
exports.selectOptionTxt = "Select option";
exports.substituteTeacherHeading = "Substitute Teacher Form";
exports.substituteTeacherSubHeading = "Please fill in below details to proceed...";
exports.profilePictureTxt = "Profile Picture";
exports.continueTxt = 'Continue';
exports.documentsTxt = "Documents";
exports.uploadResumeTxt = "Upload Resume";
exports.lettersOfInterestTxt = "Letters of Interest";
exports.credentialsTxt = "Credentials";
exports.transcriptsTxt = "Transcripts";
exports.notesPSSATxt = "Notes-page only seen by PSSA";
exports.letterReferenceEmailTxt = "Letter of reference email";
exports.pleaseFillAllFields = "Please fill all the fields";
exports.somethingWentWrong = "Something went wrong!"
// Customizable Area End