Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.backTxt = "Back";
exports.passTxt = "pass";
exports.capPassTxt = "Pass";
exports.wordTxt = "word";
exports.questionMark = "?";
exports.forgotTxt = 'Forgot';
exports.subHeadingText = "No worries, provide your email and we’ll send a recovery link to your email address.";
exports.enterEmailTxt = "Enter email";
exports.emailTxt = "Email";
exports.sendTxt = "Send";
exports.resetLinkTxt = "reset link"
exports.toTxt = 'to';
exports.loginTxt = 'Login';
exports.setNewTxt = "Set new";
exports.subHeadingTxt = "Create a new"
exports.forTxt = "for";
exports.dummyEmail = "mail@email.com";
exports.newTxt = "New";
exports.newSmallTxt = "new";
exports.enterTxt = "Enter";
exports.againTxt = "again";
exports.confirmTxt = "Confirm";
exports.mustIncludeTxt = "must include:";
exports.passConditionCapitalTxt = 'At least one capital letter.';
exports.passConditionLowerTxt = 'At least one lowercase letter.';
exports.passConditionNumberTxt = 'At least one number.';
exports.passConditionLengthTxt = 'Minimum characters length is 8 characters.';
exports.resetTxt = "Reset";
exports.checkYourEmailTxt = "Check your email";
exports.resendTxt = "Resend";
exports.resetLinkSentTo = "reset link sent to";
exports.instructionForReset = "Please check your email and follow instructions to reset.";
exports.dotTxt = ".";
exports.emailErrorMsg = '*Please enter correct email'
exports.errorMsgPleaseEnterEmail = "*Please enter email address.";
exports.errorMsgPleaseEnterPassword = `*Please enter account`;
exports.commaTxt = ',';
exports.starTxt = '*';
exports.invalidTxt = 'Invalid';
exports.errorMsgInvalidPassword = 'please enter valid';
exports.isNotMatching = "is not matching";
exports.resetLinkTime = 1000;
exports.redirectingTimeToLogin = 3000;
exports.sendResetLinkEndPoint = "bx_block_forgot_password/passwords/forgot_password";
exports.setNewPasswordEndPoint = "bx_block_forgot_password/passwords/reset_password";
exports.sendResetLinkMethod = "POST";
exports.setNewPasswordMethod = "POST";
exports.apiContentType = "application/json";
exports.somethingWentWrong = "Oops! Something went wrong on our end. Please try again later."
exports.redirectingMsg = "reset successfully, Redirecting you to the login page."
// Customizable Area End