import React, { Component } from "react";

import {
    Box,
    Grid,
    Typography,
    styled, Avatar
} from "@material-ui/core";
import storage from "../../framework/src/StorageProvider"

export interface Props {
    navigation: any;
    id: string;
    NaviagationPage: string
}
interface FooterState {
    token: string;
    userId: number;
    fistLetter: string;
}

export default class Footer extends Component<Props, FooterState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            token: '',
            userId: 0,
            fistLetter: ''
        };
    }
    async componentDidMount(): Promise<void> {
        const token = await storage.get("loginToken")
        this.setState({ token: token })
        const userId = await storage.get('loginId')
        this.setState({ userId: userId })
        const fistLetter = await storage.get('fistLetter')
        this.setState({ fistLetter: fistLetter })
    }
    navigatoProfile = () => {
        this.props.navigation.navigate("CustomisableUserProfiles")
    }
    navigateToHome = () => {
        this.props.navigation.navigate("JobListing")
    }

    navigateToChat = () => {
        this.props.navigation.navigate("Chat")
    }
    navigateToSavedJob = () => {
        this.props.navigation.navigate("SavedJob")
    }

    render() {
        return (
            <>
                <MainContent>
                    <Box style={webStyle.mainBox}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} lg={5} md={5}>
                                <img src={require("./PCC_Tree.png")} />
                                <AboutTypo>
                                    At Palmetto Childcare Center, we are dedicated to providing exceptional childcare services. Are you passionate about working with children?
                                </AboutTypo>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={2} md={2}>
                                <Typography style={webStyle.heading}>Our Services</Typography>
                                <FooterContent style={webStyle.textstyle}>Home</FooterContent>
                                <FooterContent style={webStyle.textstyle}>About us</FooterContent>
                                <FooterContent style={webStyle.textstyle}>Browse jobs</FooterContent>
                                <FooterContent style={webStyle.textstyle}>Dummy option</FooterContent>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3} style={webStyle.thirdGrid}>
                                <Typography style={webStyle.heading}>Legal</Typography>
                                <FooterContent style={webStyle.textstyle}>Terms and conditions</FooterContent>
                                <FooterContent style={webStyle.textstyle}>Privacy policy</FooterContent>
                                <FooterContent style={webStyle.textstyle}>Frequently asked questions</FooterContent>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={2} md={2}>
                                <Typography style={webStyle.heading}>Contact us</Typography>
                                <FooterContent style={webStyle.textstyle}>admin@mail.com</FooterContent>
                                <FooterContent style={webStyle.textstyle}>+1-555-966-8751</FooterContent>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box style={webStyle.lastBox}>
                        © 2024 Palmetto Childcare Connect
                    </Box>
                </MainContent>
                <MobileContent>
                    <div style={{ ...webStyle.boxStyle, borderTop: this.props.NaviagationPage == "Home" ? "2px solid black" : '' }} onClick={this.navigateToHome}>
                        <img src={require('./image_(1).png')} />
                        <Typography style={webStyle.typo}>Home</Typography>
                    </div>
                    {this.state.userId == 2 && <>
                        <div style={{ ...webStyle.boxStyle, borderTop: this.props.NaviagationPage == "savedjob" ? "2px solid black" : '' }} onClick={this.navigateToSavedJob}>
                            <img src={require('./image_(2).png')} />
                            <Typography style={webStyle.typo}>Saved Jobs</Typography>
                        </div>
                    </>}
                    <div style={{ ...webStyle.boxStyle, borderTop: this.props.NaviagationPage == "Chat" ? "2px solid black" : '' }} onClick={this.navigateToChat}>
                        <img src={require('./chat_bubble_outline_24px.png')} />
                        <Typography style={webStyle.typo}>Chat</Typography>
                    </div>
                    <div style={{ ...webStyle.boxStyle, borderTop: this.props.NaviagationPage == "Profile" ? "2px solid black" : '' }} onClick={this.navigatoProfile}>
                        <Avatar style={webStyle.avatar}>{this.state.fistLetter}</Avatar>
                        <Typography style={webStyle.typo}>Profile</Typography>
                    </div>
                </MobileContent>
            </>
        );
    }
}
const AboutTypo = styled(Typography)({
    fontFamily: "Inter",
    color: "#FFFFFF",
    fontWeight: 400,
    lineHeight: "22px",
    fontSize: "14px",
    marginTop: "16px",
    width: "348px",
    "@media only screen and (max-width: 1036px)": {
        fontSize: 13
    },
})
const FooterContent = styled(Typography)({
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginTop: "16px",
    "@media only screen and (max-width: 1036px)": {
        fontSize: 13
    },
})
const MainContent = styled(Box)({
    "@media only screen and (max-width: 571px)": {
        display: 'none'
    },
})
const MobileContent = styled(Box)({
    display: 'none',
    "@media only screen and (max-width: 571px)": {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        background: '#fff',
        paddingLeft: '10px',
        paddingRight: '10px',
        gap: 10,
        position:"fixed",
        width:"100%",
        bottom:0,
        zIndex:1
    },
})
const webStyle = {
    mainBox: {
        background: "#1D2935",
        padding: "64px 120px"
    },
    textstyle: {
        color: "#FFFFFF",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        marginTop: "16px"
    },
    heading: {
        color: "#FFFFFF",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
    },
    lastBox: {
        background: "#5A6E82",
        height: "40px",
        color: "#FFFFFF",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "center" as "center",
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    thirdGrid: {
        paddingLeft: "25px"
    },
    typo: {
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: 500,
        lineHeight: '12.1px',
        color: '#94A3B8',
        marginTop: '4px'
    },
    boxStyle: {
        padding: "10px"
    },
    avatar: {
        background: '#E2E8F0',
        color: '#64748B',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'center' as 'center',
        width:'31px',
        height:'31px'
    }
}