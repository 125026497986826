// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import JobListingDetailsController from "../../../blocks/joblisting/src/JobListingDetailsController";
import { runEngine } from "../../../framework/src/RunEngine";
import { OptionTypeBase, ValueType } from "react-select";

import storage from '../../../framework/src/StorageProvider';
import moment from "moment";
export interface Time {
  minute: number;
  period: 'AM' | 'PM';
  hour: number;
}

export interface JobWorkingDay {
  start_time: string;
  id: number;
  end_time: string;
  full_day: boolean;
  working_day_id: number;
}

interface AccountImage {
  type: string;
  id: number;
  filename: string;
  url: string;
}

export interface JobAttributes {
  job_title: string;
  id: number;
  education_level: string;
  job_description: string;
  experience_required: number;
  organization: string;
  street_address: string;
  city: string;
  country: string;
  zip_code: string;
  location: string | null;
  state: string;
  about_organization: string;
  role_name: string;
  job_status: string;
  account_image: AccountImage;
  posted_by: string;
  job_image: string;
  key_responsibilities: string | null;
  qualifications: string | null;
  posted_at: string;
  created_at: string;
  benefits: string | null;
  admin_email: string;
  job_applied_at: string | null;
  post_created_at: string;
  end_date: string;
  start_date: string;
  job_working_days: JobWorkingDay[];
  created_by: number;
}

interface JobData {
  type: string;
  attributes: JobAttributes;
  id: string;
}

interface Jobs {
  data: JobData[];
}

interface Job {
  jobs : Jobs
}


export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  token: string;
  PostData: [];
  name: string;
  description: string;
  currency: string;
  price: string;
  image: string | undefined;
  category_id: string;
  AllCategory: [];
  uploadedImages: [];
  refresh: boolean;
  id: string|number;
  profileImageData: { data: string | null | undefined, content_type:string, filename:string };
  file: string;
  allCategories: { value: string, label: string}[];
  selectedCategory: ValueType<OptionTypeBase, false>;
  experienceList:number[];
  educationList:string[];
  countryList:string[];
  currentStep:number;
  organisation:string;
  jobTitle:string;
  experience:number|string;
  education:string;
  state:string;
  city:string;
  jobdescription:string;
  country:string;
  zipcode:string;
  street:string;
  aboutOrg:string;
  openPreview:boolean;
  savedAddress:boolean;
  jobTitleError: string;
  organisationError: string;
  schoolname:string;
  experienceError: string;
  educationError: string;
  streetError: string;
  jobDesciptionError:string;
  stateError: string;
  cityError: string;
  countryError: string;
  zipcodeError: string;
  workingDays: {
    [key: string]: boolean;
    'Monday': boolean;
    'Tuesday': boolean;
    'Wednesday': boolean;
    'Thursday': boolean;
    'Friday': boolean;
    'Saturday': boolean;
    'Sunday': boolean;
  };
  firstname:string;
  aboutOrgError: string;
  lastname:string;
  creditError:boolean;
  editAvailbility:boolean;
  selectedWorkingDays:string[] ;
  workingDaysArray:string[];
  workTimes: JobWorkingDay[];
  selectedWorkingDayIndices: number[];
  endClicked: boolean;
  startClicked: boolean;
  anchorElDocStart: HTMLElement | null;
  activeDay: string | null;
  textFieldValueDocStart: string;
  calendarValueDocStart: Date | null;
  calendarValueDocEnd: Date | null;
  textFieldValueDocEnd: string;
  anchorElDocEnd: HTMLElement | null;
  daysOfWeek:{label: string; day: string;}[];
  endTimeError:string;
  workingdayError:string;
  posts:JobData[],
  startTimeError:string;
  anchorEl: null | HTMLElement;
  job_working_times:JobWorkingDay[]
  time: { [key: string]: { start: Time; end: Time } };
  days :string[],
}

interface SS {
  id: any;
}

export default class PostEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: string = "";;
  addpostApiCallId: string = "";;
  updatePostApiCallId: string = "";;
  addePostId:string='';
  getProfileDataId:string='';
  getProfileAddressId:string='';
  getMyJob:string=''
  detailObj:JobListingDetailsController= new JobListingDetailsController({id:'', navigation:''});
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      PostData: [],
      token: "",
      description: "",
      name: "",
      currency: "$",
      price: "",
      image: "",
      category_id: "",
      uploadedImages: [],
      id: "",
      file: "",
      AllCategory: [],
      profileImageData: {data:null, content_type:"", filename:""},
      refresh: false,
      allCategories: [],
      selectedCategory: {},
      experienceList:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
      educationList:["Bachelor's degree","Associate's degree","High school diploma or equivalent","Certification in substitute teaching"],
      countryList:['USA','Canada','Mexico'],
      currentStep:1,
      organisation:'',
      jobTitle:'',
      experience:'Select option',
      jobdescription:'',
      education:'Select option',
      city:'',
      state:'',
      country:'Select option',
      zipcode:'',
      street:'',
      aboutOrg:'',
      openPreview:false,
      savedAddress:false,
      jobTitleError: '',
      schoolname:'Riverdale Elementary',
      educationError: '',
      organisationError: '',
      jobDesciptionError:'',
      experienceError: '',
      cityError: '',
      streetError: '',
      zipcodeError: '',
      stateError: '',
      aboutOrgError: '',
      countryError: '',
      lastname:'',
      firstname:'',
      editAvailbility:false,
      creditError:false,
      selectedWorkingDays:[],
      workingDaysArray: [
       'Monday',
       'Tuesday',
       'Wednesday',
       'Thursday',
       'Friday',
       'Saturday',
       'Sunday',
     ],
     selectedWorkingDayIndices: [],
     workTimes: [],
     workingDays: {
        'Monday': true,
        'Tuesday': true,
        'Wednesday': true,
        'Thursday': true,
        'Friday': true,
        'Saturday': true,
        'Sunday': true,
      },
      endClicked: false,
      startClicked: false,
      anchorElDocStart: null,
      activeDay:null,
      textFieldValueDocStart: '',
      calendarValueDocStart: null,
      calendarValueDocEnd: null,
      anchorElDocEnd: null,
      textFieldValueDocEnd: '',
      workingdayError:'',
      daysOfWeek : [
        { label: 'M', day: 'Monday' },
        { label: 'T', day: 'Tuesday' },
        { label: 'W', day: 'Wednesday' },
        { label: 'T', day: 'Thursday' },
        { label: 'F', day: 'Friday' },
        { label: 'S', day: 'Saturday' },
        { label: 'S', day: 'Sunday' },
      ],
      endTimeError:'',
      startTimeError:'',
      posts:[],
      anchorEl:null,
      days:[
        '',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      time:{},
      job_working_times:[]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
    this.getMyJobList()
    const data = JSON.parse(await storage.get("jobedit"));
    this.setEditJobData(data)
  }
  convertTimeStringToTimeObject(timeString: string): Time {
    const [time, period] = timeString.split(' ');
    const [hour, minute] = time.split(':').map(Number);
    return {
      hour: hour % 12 === 0 ? 12 : hour % 12, 
      minute,
      period: period as 'AM' | 'PM'
    };
  }
  setTimeForDay(day: string, startTime: string, endTime: string) {
    const start = this.convertTimeStringToTimeObject(startTime);
    const end = this.convertTimeStringToTimeObject(endTime);
    return {
      [day]: {
        start,
        end
      }
    };
  }
  
  setAllWorkingDays(data: { job_working_days: JobWorkingDay[] }) {
    const time = data.job_working_days.reduce((acc, work) => {
      const day = this.state.days[work.working_day_id];
      return {
        ...acc,
        ...this.setTimeForDay(day, work.start_time, work.end_time)
      };
    }, {});
  
    this.setState({ time });
  }

  formatDate(dateString:string) {
    const [day, month, year] = dateString.split('/').map(Number);
    const fullYear = year >= 50 ? 1900 + year : 2000 + year;
  
    const date = new Date(fullYear, month - 1, day);
  
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
  }
  async setEditJobData(data: JobAttributes) {
    if (data?.id) {
      const defaultEndDate = this.parseDate(data.end_date);
      const defaultStartDate = this.parseDate(data.start_date);
      this.setState({
        ...this.getBasicJobInfo(data),
        ...this.getAddressInfo(data),
        ...this.getProfileImageData(data),
        textFieldValueDocStart: this.formatDate(data.start_date) || '',
        textFieldValueDocEnd: this.formatDate(data.end_date) || '',
        selectedWorkingDays: this.getSelectedWorkingDays(data.job_working_days),
        workTimes: data.job_working_days || [],
        calendarValueDocEnd: defaultEndDate,
        calendarValueDocStart: defaultStartDate
      });
  
      this.setAllWorkingDays(data);
    }
  }
  
  parseDate(dateString: string) {
    return moment(dateString, 'DD/MM/YY').toDate();
  }
  
  getBasicJobInfo(data: JobAttributes) {
    return {
      id: data.id || '',
      jobTitle: data.job_title || '',
      jobdescription: data.job_description || '',
      organisation: data.organization || '',
      education: data.education_level || 'Select option',
      experience: data.experience_required || 'Select option',
    };
  }
  
  getAddressInfo(data: JobAttributes) {
    return {
      street: data.street_address || '',
      city: data.city || '',
      state: data.state || '',
      zipcode: data.zip_code || '',
      country: data.country || 'Select option',
      aboutOrg: data.about_organization || '',
    };
  }
  
  getProfileImageData(data: JobAttributes) {
    return {
      profileImageData: {
        data: data.account_image ? data.account_image.url : null,
        content_type: data.account_image ? data.account_image.type : "",
        filename: data.account_image ? data.account_image.filename : "",
      }
    };
  }
  
  getSelectedWorkingDays(jobWorkingDays: JobWorkingDay[]) {
    return jobWorkingDays
      ? jobWorkingDays.map((day: JobWorkingDay) => this.state.days[day.working_day_id])
      : [];
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const item = message.getData(getName(MessageEnum.PostDetailDataMessage));
      if (item) {
        this.setState({
          description: item.attributes.description,
          name: item.attributes.name,
          currency: "$",
          price: item.attributes.price?.toString(),
          id: item.attributes.id,
          category_id: item.attributes.category_id,
          profileImageData: {
            data: item.attributes.product_image,
            content_type: "image/jpeg",
            filename: "image.jpeg"
          },
          image: ""
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.handleUpdatebutton(apiRequestCallId)
      runEngine.debugLog("API Message Recived", message);
      this.getApiJobs(responseJson,apiRequestCallId)
       this.getApiReceive(responseJson)
    }
  }

  getApiJobs = (responseJson:Job , apiRequestCallId :string)=>{
    if (apiRequestCallId  == this.getMyJob) {
      this.setState({
        posts:responseJson.jobs.data 
      })
    }
  }

  handleUpdatebutton =(apirequest:string)=>{
    if(apirequest==this.addePostId){
      this.handleBack("JobCreated")
    }
  }

  getApiReceive=(responseJson: { data: { attributes: { state: string; zip_code: string; country: string; city: string; street_address: string;  first_name: string; last_name: string; }; }; })=>{
    if(this.getProfileAddressId){
      if(this.state.savedAddress){
        this.setState({
          country:responseJson.data.attributes.country, 
          city:responseJson.data.attributes.city, 
          zipcode:responseJson.data.attributes.zip_code,
          street:responseJson.data.attributes.street_address,
          state:responseJson.data.attributes.state, 
          
      })
      } else if(!this.state.savedAddress) {
        this.setState({
          country:'Select option', 
          city:'', 
          state:'', 
          zipcode:'',
          street:'',
        })
      }
    } 
    if(this.getProfileDataId){
      this.setState({firstname:responseJson.data.attributes.first_name,
        lastname:responseJson.data.attributes.last_name})
    }
  }

  navigateToHome=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),'JobListing');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    message.addData(getName(MessageEnum.NavigationRaiseMessage), 
    raiseMessage);
    this.send(message);
  }
  
  handleCancleTwo=()=>{
    this.setState({
      currentStep:2
    })
  }
  onValueChange = (name: string, value: string | unknown) => {
    let valuenumber = value as string;
    let cleanedValue = valuenumber;
    let errorMessage = '';
    if (name === 'jobTitle' || name === 'city' || name === 'state' || name === 'organisation') 
    {
      cleanedValue = valuenumber.replace(/\d/g,'');
      if (value !== cleanedValue) {errorMessage = 'Only characters are allowed.'}
    } 
    this.setState({ ...this.state, [name]: cleanedValue, [`${name}Error`]: errorMessage });
  };

  validateFields = () => {
    const { jobTitle, organisation, education, experience, jobdescription } = this.state;
    let isValid = true;
    if (!jobTitle) {
      this.setState({ jobTitleError: 'This field is required.' });
      isValid = false;}
    if (!organisation) {
      this.setState({ organisationError: 'This field is required.' });
      isValid = false;}
    if (education == 'Select option') {
      this.setState({ educationError: 'This field is required.' });
      isValid = false;}
    if (experience == 'Select option') {
      this.setState({ experienceError: 'This field is required.' });
      isValid = false;}
    if (!jobdescription) {
      this.setState({ jobDesciptionError: 'This field is required.' });
      isValid = false;}
    return isValid;
  };

  stepOneContinue = () => {
    this.setState({ currentStep: 2 });
    window.scrollTo(0,0)
  };
  backStepOne=()=>{
    this.setState({ currentStep: 1 });
  }
  stepTwoContinue=()=>{
    this.setState({currentStep:3})
    window.scrollTo(0,0)
  }
  savedAddressClick=async ()=>{
    this.setState({ savedAddress: !this.state.savedAddress })
    const token = await storage.get("loginToken")
    const userId=await storage.get('loginId')
    const header = {
      token: token,
    };
    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileAddressId = getProfileMsg.messageId;
    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/custom_forms/${userId}/show_profile`);
    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`);
    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }
  handlePreview=async ()=>{
    if(this.validateFieldsTwo()){
    const jobWorkingDays = this.state.workTimes.map((workTime) => {
      const startTime = this.state.time[this.state.days[workTime.working_day_id]]?.start || { hour: 9, minute: 0, period: 'AM' };
      const endTime = this.state.time[this.state.days[workTime.working_day_id]]?.end || { hour: 5, minute: 0, period: 'PM' };
      
      return {
        id: workTime.id,
        working_day_id: workTime.working_day_id,
        start_time: `${startTime.hour}:${startTime.minute < 10 ? '0' + startTime.minute : startTime.minute} ${startTime.period}`,
        end_time: `${endTime.hour}:${endTime.minute < 10 ? '0' + endTime.minute : endTime.minute} ${endTime.period}`,
        full_day: workTime.full_day
      };
    });
    this.setState({openPreview:true , job_working_times : jobWorkingDays})
    this.getUserProfile()
    }
  }
  closePreview=()=>{
    this.setState({openPreview:false})
  }
  validateFieldsTwo = () => {
    const { street, city, country, zipcode, state, aboutOrg } = this.state;
    let isValid = true;
    if (!street) {
      this.setState({ streetError: 'This field is required.' });
      isValid = false;}
    if (!aboutOrg) {
      this.setState({ aboutOrgError: 'This field is required.' });
      isValid = false;}
    if (country == 'Select option') {
      this.setState({ countryError: 'This field is required.' });
      isValid = false;}
    if (!state) {
      this.setState({ stateError: 'This field is required.' });
      isValid = false;}
    if (!city) {
      this.setState({ cityError: 'This field is required.' });
      isValid = false;}
    if (!zipcode) {
      this.setState({ zipcodeError: 'This field is required.' });
      isValid = false;}
    return isValid;
  };
  convertTimeToString = (time: { hour: number; minute: number; period: 'AM' | 'PM' }): string => {
    const formattedHour = time.hour.toString().padStart(2, '0');
    const formattedMinute = time.minute.toString().padStart(2, '0');
    return `${formattedHour}:${formattedMinute} ${time.period}`;
  };
  publishBtn=async ()=>{
      const header = {
        token: await storage.get('loginToken')
      };
      const formData = new FormData();
      formData.append("job_creation[job_title]", this.state.jobTitle);
      formData.append("job_creation[experience_required]", this.state.experience.toString());
      formData.append("job_creation[about_organization]", this.state.aboutOrg);
      formData.append("job_creation[education_level]", this.state.education);
      formData.append("job_creation[street_address]", this.state.street);
      formData.append("job_creation[organization]", this.state.organisation);
      formData.append("job_creation[job_description]", this.state.jobdescription);
      formData.append("job_creation[state]", this.state.state);
      formData.append("job_creation[city]", this.state.city);
      formData.append("job_creation[country]", this.state.country);
      formData.append("job_creation[zip_code]", this.state.zipcode);
      
      this.state.workTimes.forEach((workTime) => {
        const startTime = this.state.time[this.state.days[workTime.working_day_id]]?.start || { hour: 9, minute: 0, period: 'AM' };
        const endTime = this.state.time[this.state.days[workTime.working_day_id]]?.end || { hour: 5, minute: 0, period: 'PM' };
        formData.append(`job_creation[working_days_attributes][][working_day_id]`, JSON.stringify(workTime.working_day_id));
        formData.append(`job_creation[working_days_attributes][][start_time]`, `${startTime.hour}:${startTime.minute} ${startTime.period}`);
        formData.append(`job_creation[working_days_attributes][][end_time]`, `${endTime.hour}:${endTime.minute} ${endTime.period}`);
        formData.append(`job_creation[working_days_attributes][][full_day]`, workTime.full_day ? 'true' : 'false');
      });
      formData.append("job_creation[start_date]", this.state.textFieldValueDocStart);
      formData.append("job_creation[end_date]", this.state.textFieldValueDocEnd);
      formData.append("job_creation[account_id]", await storage.get("loginId"));
      formData.append("job_creation_params[use_my_save_address]", this.state.savedAddress.toString());
      const setIntroMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addePostId = setIntroMsg.messageId;
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_joblisting/job_creations/${this.state.id}/edit_job`
      );
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      setIntroMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  getUserProfile = async () => {
    const token = await storage.get("loginToken")
    const userId = await storage.get('loginId')
    const header = {token: token,};
    const getProfileMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProfileDataId = getProfileMsg.messageId;
    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),`bx_block_custom_forms/custom_forms/${userId}/show_profile`);
    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),`GET`);
    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }

  workingDaysClick = (providerType: string, index: number) => {
    this.setState((prevState) => {
      const isCurrentlySelected = prevState.selectedWorkingDays.includes(providerType);
      const newSelectedWorkingDays = isCurrentlySelected
        ? prevState.selectedWorkingDays.filter(day => day !== providerType)
        : [...prevState.selectedWorkingDays, providerType];
      const newSelectedWorkingDayIndices = isCurrentlySelected
        ? prevState.selectedWorkingDayIndices.filter(i => i !== index)
        : [...prevState.selectedWorkingDayIndices, index];
      const dayId = index + 1;
      const newWorkDay = {
        id: dayId,
        start_time: "09:00 AM",
        working_day_id: dayId,
        full_day: true,
        end_time: "05:00 PM",
      };
      const updatedWorkTimes = isCurrentlySelected
        ? prevState.workTimes.filter(day => day.working_day_id !== dayId)
        : [...prevState.workTimes, newWorkDay];
      return {
        selectedWorkingDayIndices: newSelectedWorkingDayIndices,
        workTimes: updatedWorkTimes,
        selectedWorkingDays: newSelectedWorkingDays,
      };
    });
  };

  saveAvailbility = () => {
    this.setState({ editAvailbility: false })
  }

  dayToggle = (working_day_id: number) => {
    this.setState((prevState) => {
      const updatedWorkTimes = prevState.workTimes.map((day) => {
        if (day.working_day_id === working_day_id) {
          const updatedDay = { ...day, full_day: !day.full_day };
          return updatedDay;
        }
        return day;
      });
      return {
        workTimes: updatedWorkTimes,
      };
    });
  };

  editAvailability = () => {
    this.setState({ editAvailbility: true })
  }

  handleTimeChange = (day: string, type: 'start' | 'end', value: Time) => {
    this.setState((prevState) => ({
      time: {
        ...prevState.time,
        [day]: {
          ...prevState.time[day],
          [type]: value
        }
      }
    }));
  };

  convertTimeStringToTime(timeString: string): Time {
    const [time, period] = timeString.split(' ');
    const [hour, minute] = time.split(':').map(Number);
  
    return {
      hour: hour === 12 ? 12 : hour % 12,
      minute,
      period: period as 'AM' | 'PM',
    };
  }

  clickTime = (day: string) => {this.setState({ startClicked: true, activeDay: day });};

  clickEndTime = (day: string) => {this.setState({ endClicked: true, activeDay: day });}

  closeTimeDialog = () => {this.setState({ startClicked: false, endClicked: false, activeDay: null });};

  saveStartTime = () => {this.closeTimeDialog()};
  saveEndTime = () => {this.closeTimeDialog()};

  handleStartCalender = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({anchorElDocStart: event.currentTarget});
  };
  
  handleCloseDocanchorElDocStart = () => {
    this.setState({anchorElDocStart: null,anchorElDocEnd:null});
  };
  handleDateChangesdoc = (stateKey: string, textFieldKey: string, anchorKey: string) => (date: Date) => {
    const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
    this.setState({
      [textFieldKey]: formattedDate,
      [anchorKey]: null,
      [stateKey]: date} as any);
  };
  handleendCalender = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({anchorElDocEnd: event.currentTarget,});
  };

  handleBack = (to:string) => {
    storage.set("jobId" , this.state.id)
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      to
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
    this.detailObj.getJobDeatils(Number(this.state.id))
  }

  async getMyJobList (){
    const header = {
      token: await storage.get("loginToken"),
    };
    const getMyJobs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMyJob = getMyJobs.messageId;

    getMyJobs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/job_creations/user_jobs`
    );

    getMyJobs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
    getMyJobs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    runEngine.sendMessage(getMyJobs.id, getMyJobs);}
  
getTimeFromState(day:number, type:string) {
  const workDay = this.state.workTimes.find(item => item.working_day_id === day);
  if (!workDay) return null;

  return type === 'start' ? this.convertTimeStringToTimeInput(workDay.start_time) : this.convertTimeStringToTimeInput(workDay.end_time);
}

convertTimeStringToTimeInput(timeString:string) {
  const [time, period] = timeString.split(' ');
  const [hour, minute] = time.split(':').map(Number);
  return { hour, minute, period };
}

}
// Customizable Area End