import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Grid, Typography, styled, Box, Slider, Collapse, Menu, MenuItem, Select, InputAdornment, Dialog,
  IconButton,
  Avatar,
  Popover
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";

import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";
import { Add, AddCircleOutlineOutlined, Cached, CheckCircle, Close, DeleteOutlineOutlined, Edit, EditOutlined, KeyboardArrowDown, VisibilityOutlined } from "@material-ui/icons";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import { profilePic, flaficon, addicon, calendericon, unchecked, checked, radiochecked, radiounchecked, upload, pdfIcon, uncheckedR, checkedR } from "./assets";
import TimeInput from "../../../components/src/TimeInput.web";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "react-datepicker/dist/react-datepicker.css";
import DrawerComponent from "../../../components/src/Drawer.web";
import MenuIcon from '@material-ui/icons/Menu'
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  eduSbmitted = () => {
    const { educationRecords } = this.state;

    return (
      <>
        {educationRecords.map((education, index) => (
          <Box key={index}>
            <Box style={{ height: 221, border: '0.5px solid #E2E8F0', borderRadius: 15.2, marginTop: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', position: 'relative' }}>
              <Box>
                <Box style={{ display: 'flex', gap: '5px', position: 'absolute', top: 15, right: 15 }}>
                  <DeleteOutlineOutlined data-test-id="deleteEduOne" onClick={() => this.handleDeleteIconClick(education.id)} style={{ color: '#DC2626', cursor: 'pointer' }} />
                  <EditOutlined data-test-id="handleEditEducationone" onClick={() => this.editEducation(index)} style={{ color: '#1E293B', cursor: 'pointer' }} />
                </Box>
                <Typography style={webStyle.schoolname}>{education.schoolName}</Typography>
                <Typography style={webStyle.course}>{education.course} in {education.specialization}</Typography>
                <Typography style={webStyle.duration}>{education.textFieldValueEduFrom} - {education.textFieldValueEduTo}</Typography>
              </Box>
            </Box>
          </Box>
        ))}
        {this.state.educationRecords.length > 0 && (
          <Typography data-test-it="handleMoreEdu"
            onClick={this.handleMoreEdu}
            style={webStyle.addMoreEdu}
          >
            + Add another
          </Typography>
        )}

      </>
    );
  }
  showIntroBox = () => {
    return (
      <>
        {(!this.state.openIntroduction && this.state.introductionValueBox) && (
          <Box style={webStyle.introValueBox}>
            {this.state.introductionText}
          </Box>
        )}
      </>
    )
  }

  showIntroBoxAdmin = () => {
    return (
      <>
        {(!this.state.openIntroduction && this.state.introductionText) && (
          <Box style={webStyle.introValueBox} data-test-id='showIntroBoxAdmin'>
            {this.state.introductionText}
          </Box>
        )}
      </>
    )
  }

  firstWorkExpBox = () => {
    return (
      <>
        {this.state.workExpRecords.map((work,index) =>(

          <>
            <Box style={webStyle.showBoxData}>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={webStyle.showBoxTtile}>{work.position}</Typography>
                <Box style={{ display: 'flex', gap: '5px' }}>
                  <DeleteOutlineOutlined data-test-id="deleteWorkExpOne" onClick={() => this.handleDeleteIconClickWork(work.id)} style={{ color: '#DC2626', cursor: 'pointer' }} />
                  <EditOutlined data-test-id="handleEditExpOne" onClick={() => this.editWorkExp(index)} style={{ color: '#1E293B', cursor: 'pointer' }} />
                </Box>
              </Box>
              <Typography style={webStyle.organisationTxt}>{work.organization}</Typography>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={webStyle.organisationTxt}>{work.textFieldValue} - {work.textFieldValueTo}</Typography>
                <Typography style={webStyle.expTxt}>{work.experience} year</Typography>
              </Box>
              <Typography style={webStyle.repotitle}>Job responsibilities</Typography>
              <Typography style={webStyle.jobRespon}>{work.jobResponsibility}</Typography>
            </Box>
          </>
        ))
        }
        {this.state.workExpRecords.length > 0 && (
          <Typography data-test-it="addMoreWorkExp"
            onClick={this.handleMorework}
            style={webStyle.addMoreEdu}
          >
            + Add another
          </Typography>
        )}
      </>
    )
  }
 
  workExperience = () => {
    const { anchorEl2, calendarValue } = this.state;
    const open = Boolean(anchorEl2);
    const id = open ? 'calendar-popover' : undefined;

    const {organization,position,experience,jobResponsibility,textFieldValue,textFieldValueTo}=this.state.currentWorkExp

    return (
      <>

        <Box style={webStyle.space}>
          <Typography style={webStyle.editHeading}>Work Experience</Typography>
          {this.state.openWorkexperience || this.state.wrokExpOneSubmitted ?
            <Box onClick={this.handleWorkExp} data-test-id="handleWorkExp">
              <MoreVertIcon />
            </Box>
            :
            <Typography style={{ ...webStyle.editHeading, fontWeight: 700, cursor: 'pointer' }} data-test-id="openWorkExp" onClick={this.openWorkExp}>+ Add details</Typography>
          }
        </Box>
        {this.state.openWorkexperience && (
          <Box style={{ marginTop: 32 }}>
            <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>Organization</Typography>
            <TextField fullWidth variant="outlined" placeholder="Enter organization" value={organization}
              data-test-id="organizationOne"
              onChange={(event) => {
                const value = event.target.value;
                if (/^[a-zA-Z\s]*$/.test(value)) {
                  this.handleWorkChange('organization', value);
                }
              }} />

            <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>Position</Typography>
            <TextField variant="outlined" fullWidth placeholder="ex. assistant teacher" data-test-id="positionOne" value={position}
              onChange={(event) => {
                const value = event.target.value;
                if (/^[a-zA-Z\s]*$/.test(value)) {
                  this.handleWorkChange('position', value);
                }
              }} />
            <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>From</Typography>
            <TextField variant="outlined" fullWidth data-test-id="openCalenderFrom"
              placeholder="Select date"
              value={textFieldValue}
              onClick={this.handleClick}
              style={{ cursor: 'pointer' }}
              InputProps={{
                style: { pointerEvents: 'none' },
                endAdornment: (
                  <InputAdornment position="end" style={{ pointerEvents: 'auto', cursor: 'pointer' }} >
                    <img src={calendericon} data-test-id="openCalenderFrom" style={{ pointerEvents: 'auto', cursor: 'pointer' }} onClick={this.handleClick} />
                  </InputAdornment>
                ),
              }} />
            <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>To</Typography>
            <TextField variant="outlined" fullWidth data-test-id="openCalenderTwo"
              value={textFieldValueTo}
              placeholder="Select date"
              style={{ cursor: 'pointer' }}
              onClick={this.handleClickTo}
              InputProps={{
                style: { pointerEvents: 'none' },
                endAdornment: (
                  <InputAdornment position="end" style={{ pointerEvents: 'auto', cursor: 'pointer' }} >
                    <img src={calendericon} data-test-id="openCalenderTwo" onClick={this.handleClickTo} />
                  </InputAdornment>
                ),
              }} />
            <CustomPopover
              id="to-popover"
              open={Boolean(this.state.anchorEl2To)}
              anchorEl={this.state.anchorEl2To}
              data-test-id="handleCloseTo"
              onClose={this.handleCloseTo}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Calendar
                value={this.state.calendarValueTo}
                onClickMonth={this.handleDateChangesWork('calendarValueTo', 'textFieldValueTo', 'anchorEl2To')}
                data-test-id="handleDateChangeTo"
                view="year"
                maxDetail="year"
                tileContent={({ date }) => {
                  const isSelectedMonth = date.getMonth() === this.state.calendarValueTo?.getMonth() && date.getFullYear() === this.state.calendarValueTo?.getFullYear();
                  const style = {
                    borderRadius: '10px',
                    backgroundColor: isSelectedMonth ? '#395169' : undefined,
                    color: isSelectedMonth ? 'white' : undefined,
                  };
                  return <div style={style} />;
                }}
              />
            </CustomPopover>


            <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>Childcare Experience (in years)</Typography>
            <TextField fullWidth variant="outlined" placeholder="Enter experience in years" value={experience}
              data-test-id="experienceOne"
              onChange={(event) => {
                const value = event.target.value;
                if (/^\d*$/.test(value)) {
                  this.handleWorkChange('experience', value);
                }
              }}
            />

            <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>Job responsibilities</Typography>
            <TextField fullWidth variant="outlined" placeholder="Write something..." value={jobResponsibility}
              multiline minRows={4}
              data-test-id="jobResponsibilityOne"
              onChange={(event) => {
                this.handleWorkChange('jobResponsibility', event.target.value);
              }} />
            <Typography style={webStyle.errorMsg}>{this.state.workOneError}</Typography>
            <Box style={{ display: 'flex', justifyContent: 'end' }}>
              <Button style={webStyle.saveBtn} data-test-id="handleWorkExpSubmitOne" onClick={this.handleWorkExpSubmitOne}>Submit</Button>
            </Box>
          </Box>
        )}

        {this.firstWorkExpBox()}

        <CustomPopover
          id={id}
          open={open}
          anchorEl={anchorEl2}
          data-test-id="handleClose"
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Calendar
            value={calendarValue}
            onClickMonth={this.handleDateChangesWork('calendarValue', 'textFieldValue', 'anchorEl2')}
            view="year"
            maxDetail="year"
            data-test-id="handleDateChange2"
            tileContent={this.tileContent}
          />
        </CustomPopover>

        <CustomDialog open={this.state.deleteWorkConfirm}>
          <Close data-test-id="handleDeleteWorkConfirm" style={{ position: 'absolute', right: 26, top: 29, cursor: 'pointer' }} onClick={() => { this.setState({ deleteWorkConfirm: false }) }} />
          <Typography style={webStyle.confirmheading}>
            Are you sure you want to delete this Work Experience ?
          </Typography>
          <Button data-test-id="handleDeleteWorkConfirm" onClick={() => { this.setState({ deleteWorkConfirm: false }) }} style={{ ...webStyle.saveBtn, fontSize: '18px', width: '100%', height: '46px', marginTop: '15px' }}>
            No, Keep it
          </Button>
          <Button data-test-id="handleDeleteWorkConfirm" onClick={() => this.handleDeleteWorkExp()} style={{ ...webStyle.confirmDelete, width: '100%' }}>
            Yes, Delete
          </Button>
        </CustomDialog>
      </>
    )
  }
  tileContent = ({ date, view }: any) => {
    const { calendarValue } = this.state;
    const isSelectedMonth = date.getMonth() === calendarValue?.getMonth() && date.getFullYear() === calendarValue?.getFullYear();
    const style = {
      borderRadius: '10px',
      backgroundColor: isSelectedMonth ? '#395169' : undefined,
      color: isSelectedMonth ? 'white' : undefined,
    };

    return <div style={style} />;
  };
  skills = () => {
    const { openSkills, newSkill, skills } = this.state;
    return (
      <>
        <Box style={webStyle.space}>
          <Typography style={webStyle.editHeading}>Skills</Typography>
          {openSkills || this.state.skillsPresent ? (
            <Box onClick={this.handleSkills} data-test-id="handleSkills" style={{ cursor: 'pointer' }}>
              <MoreVertIcon />
            </Box>
          ) : (
            <Typography
              style={{ ...webStyle.editHeading, fontWeight: 700, cursor: 'pointer' }}
              data-test-id="openSkills"
              onClick={this.openSkills}
            >
              + Add details
            </Typography>
          )}
        </Box>

        {openSkills
          &&
          <>
            {this.state.showTextField &&
              <>
                <Typography style={webStyle.addskill}>Add skill</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={newSkill}
                  data-test-id="handleSkillChange"
                  placeholder="Write skill"
                  onChange={this.handleSkillChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography style={webStyle.addbutton} onClick={this.addSkill}>Add</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography style={webStyle.errorMsg}>{this.state.skillError}</Typography>
                <Box>
                  {skills.length > 0 &&
                    <Typography style={{ ...webStyle.addskill, marginTop: '25px' }}>My skills</Typography>
                  }
                  {skills.map((skill) => (
                    <Box key={skill.id} display="flex" alignItems="center" justifyContent='space-between' style={webStyle.skillNameDelete}>
                      <Typography style={webStyle.skillname}>{skill.name}</Typography>
                      <IconButton data-test-id="deleteSkill" style={{ cursor: 'pointer' }} onClick={() => this.deleteSkill(skill.id)} color="secondary">
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </Box>
                  ))}
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button style={webStyle.saveBtn} data-test-id="submitSkills" onClick={this.submitSkills}>Submit</Button>
                </Box>
              </>}

            {!this.state.showTextField && this.state.skillsPresent &&
              <Box style={webStyle.skillBox}>
                {skills.map((item) => (
                  <Typography style={{ padding: '15px', borderBottom: '1px solid #F1F5F9' }}>{item.name}</Typography>
                ))}
              </Box>}

          </>
        }

        <Dropdown
          anchorEl={this.state.anchorElSkills}
          open={Boolean(this.state.anchorElSkills)}
          data-test-id="handleEdiDeleSkills"
          onClose={this.handleEdiDeleSkills}
        >
          <MenuItem data-test-id="handleEditSkills" onClick={this.handleEditSkills}>
            <EditOutlinedIcon />
            <Typography style={webStyle.editText}>Edit</Typography>
          </MenuItem>
        </Dropdown>

      </>
    )
  }
  teachingIntrest = () => {
    return (
      <>
        <Box style={webStyle.space}>
          <Typography style={webStyle.editHeading}>Teaching Interests</Typography>
          {this.state.openTeaching || this.state.interestPresent ? (
            <Box onClick={this.handleInterest} data-test-id="handleInterest" style={{ cursor: 'pointer' }}>
              <MoreVertIcon />
            </Box>
          ) : (
            <Typography
              style={{ ...webStyle.editHeading, fontWeight: 700, cursor: 'pointer' }}
              data-test-id="openInterest"
              onClick={this.openInterest}
            >
              + Add details
            </Typography>
          )}
        </Box>

        {this.state.openTeaching &&
          <>
            {this.state.showTextFieldInterest &&
              <>
                <Typography style={webStyle.addskill}>Add interests</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={this.state.newInterest}
                  placeholder="Write interest"
                  onChange={this.handleIntrestChange}
                  data-test-id="handleIntrestChange"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography style={webStyle.addbutton} onClick={this.addIntrest}>Add</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography style={webStyle.errorMsg}>{this.state.interestError}</Typography>

                <Box>
                  {this.state.teachinInterests.length > 0 &&
                    <Typography style={{ ...webStyle.addskill, marginTop: '25px' }}>My Teaching Interests</Typography>
                  }
                  {this.state.teachinInterests.map((item) => (
                    <Box key={item.id} display="flex" alignItems="center" justifyContent='space-between' style={webStyle.skillNameDelete}>
                      <Typography style={webStyle.skillname}>{item.name}</Typography>
                      <IconButton style={{ cursor: 'pointer' }} onClick={() => this.deleteIntrest(item.id)} color="secondary">
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </Box>
                  ))}
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button style={webStyle.saveBtn} data-test-id="submitIntrest" onClick={this.submitIntrest}>Submit</Button>
                </Box>
              </>}

            {!this.state.showTextFieldInterest && this.state.interestPresent &&
              <Box style={webStyle.skillBox}>
                {this.state.teachinInterests.map((item) => (
                  <Typography style={{ padding: '15px', borderBottom: '1px solid #F1F5F9' }}>{item.name}</Typography>
                ))}
              </Box>}

          </>
        }

        <Dropdown
          anchorEl={this.state.anchorElInnterest}
          open={Boolean(this.state.anchorElInnterest)}
          onClose={this.handleEdiDeleInterest}
          data-test-id="handleEdiDeleInterest"
        >
          <MenuItem data-test-id="handleEditInterest" onClick={this.handleEditInterest}>
            <EditOutlinedIcon />
            <Typography style={webStyle.editText}>Edit</Typography>
          </MenuItem>
        </Dropdown>
      </>
    )
  }
  jobPreferencesHandler = (id:number)=>(this.state.profileRoleId == 2 ? this.savePreferences() : this.handleUpdateFromAdmin())
  jobPreferences = () => {
    const providerTypes = [
      'Family Provider',
      'Group Provider',
      'Center Based Program',
      'School Age Program',
    ];
    const ageGroup = [
      'Infants',
      'Toddlers',
      'Preschool',
      'School Age',
    ]
    const workingDays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]
    const timeShit = ['Morning', 'Afternoon', 'Any']
    const natureJob = ['Full-time', 'Part-time', 'Any']
    return (
      <>
        <div ref={this.jobPreferRef} style={{ ...webStyle.optionLabel, borderBottom: this.state.openJobPrefer ? 'unset' : '1px solid #F1F5F9' }} data-test-id="handlejobPrefer" onClick={this.handlejobPrefer}>
          <Typography style={webStyle.optionLabelText}>Job Preferences</Typography>
          <ArrowForwardIosIcon
            style={{
              ...webStyle.expandArrow,display: this.state.openJobPrefer ? 'none' : ''
            }} />
            {this.state.openJobPrefer && <KeyboardArrowDown style={{fontSize: "1.6rem"}} />}
        </div>
        <Collapse in={this.state.openJobPrefer} style={{ marginTop: -14, marginBottom: 16 }}>
          {!this.state.displaySummary &&
            <>
              <Box style={webStyle.jobprefeBox}>
                <Typography style={webStyle.preferTitle}>Modality</Typography>
                {providerTypes.map((providerType, index) => {
                  const isChecked = this.state.selectedModalities.includes(providerType);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #F1F5F9',
                        padding: '10px 16px',
                        alignItems: 'center'
                      }}
                    >
                      <Typography style={webStyle.prefOption}>{providerType}</Typography>
                      <ThemeProvider theme={theme2}>
                        <Checkbox
                          checked={isChecked}
                          style={{ borderRadius: "10px" }}
                          data-test-id='savedAddressClick'
                          onChange={() => this.savedAddressClick(providerType, index +1 )}
                        />
                      </ThemeProvider>
                    </Box>
                  );
                })}
              </Box>
              <Box style={webStyle.jobprefeBox}>
                <Typography style={webStyle.preferTitle}>Age Group</Typography>
                {ageGroup.map((providerType, index) => {
                  const isChecked = this.state.selectedAgeGroups.includes(providerType)
                  return (
                    <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }}>
                      <Typography style={webStyle.prefOption}>{providerType}</Typography>
                      <ThemeProvider theme={theme2}>
                        <Checkbox
                          checked={isChecked}
                          style={{ borderRadius: "10px" }}
                          data-test-id="savedAddressClick"
                          onChange={() => this.ageGroupClick(providerType, index + 1)}
                        />
                      </ThemeProvider>
                    </Box>
                  )
                })}
              </Box>
              <Box style={webStyle.jobprefeBox}>
                <Typography style={webStyle.preferTitle}>Working Days</Typography>
                {workingDays.map((providerType, index) => {
                  const isChecked = this.state.selectedWorkingDays.includes(providerType)
                  return (
                    <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }}>
                      <Typography style={webStyle.prefOption}>{providerType}</Typography>
                      <ThemeProvider theme={theme2}>
                        <Checkbox
                          checked={isChecked}
                          style={{ borderRadius: "10px" }}
                          data-test-id="savedAddressClick"
                          onChange={() => this.handleCheckboxChange(providerType, index)}
                        />
                      </ThemeProvider>
                    </Box>
                  )
                })}
              </Box>
              <Box style={webStyle.jobprefeBox}>
                <Typography style={{ ...webStyle.preferTitle, fontSize: '14px' }}>Time Shift</Typography>
                {timeShit.map((option, index) => {
                  const isChecked = this.state.selectedTimeShift == option
                  return (
                    <Box
                      key={option}
                      data-test-id='radioCheck'
                      style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }}
                      onClick={() => this.timeShiftChange(option, index + 1 + '')}
                    >
                      <Typography style={webStyle.prefOption}>{option}</Typography>
                      <img src={isChecked ? radiochecked : radiounchecked} alt={this.state.selectedTimeShift === option ? "Checked" : "Unchecked"} />
                    </Box>
                  )
                })}
              </Box>

              <Box style={webStyle.jobprefeBox}>
                <Typography style={{ ...webStyle.preferTitle, fontSize: '14px' }}>Nature of Job</Typography>
                {natureJob.map((option, index) => {
                  const isChecked = this.state.selectedNatureJob == option
                  return (
                    <Box
                      key={option}
                      data-test-id="radioCheck"
                      style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }}
                      onClick={() => this.natureJobChange(option, index + 1 + '')}
                    >
                      <Typography style={webStyle.prefOption}>{option}</Typography>
                      <img src={isChecked ? radiochecked : radiounchecked} alt={this.state.selectedNatureJob === option ? "Checked" : "Unchecked"} />
                    </Box>
                  )
                })}
              </Box>

              <Typography style={webStyle.travel}>Travel Distance</Typography>
              <TextField variant="outlined" fullWidth value={this.state.distance} data-test-id="handleDistance" onChange={(event) => this.handleDistance(event.target.value)} />

              <Box style={{ display: 'flex', justifyContent: 'end' }}>
                <Button style={webStyle.saveBtn} data-test-id="savePreferences" onClick={()=> this.jobPreferencesHandler(this.state.LogoId)}>Save</Button>
              </Box>
            </>
          }
          {this.state.displaySummary &&
            <Box style={{ position: 'relative' }}>
              <Box data-test-id="editPreferences" onClick={this.editPreferences} style={{ position: 'absolute', top: '-56px', right: '18px', display: 'flex', gap: '13px' }}>
                <Edit />
                <Typography
                  style={{...webStyle.editTextPreferesnce , marginRight:"12px"}}
                  >
                  Edit
                </Typography>
              </Box>
              <Box style={webStyle.jobprefeBox}>
                <Typography style={webStyle.preferTitle}>Modality</Typography>
                <Box>

                  {this.state.selectedModalities.map((providerType, index) => (
                    <Typography style={{ ...webStyle.prefOption, borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }} key={index}>{providerType}</Typography>
                  ))}
                </Box>
              </Box>
              <Box style={webStyle.jobprefeBox}>
                <Typography style={webStyle.preferTitle}>Age Groups</Typography>
                <Box>
                  {this.state.selectedAgeGroups.map((ageGroup, index) => (
                    <Typography style={{ ...webStyle.prefOption, borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }} key={index}>{ageGroup}</Typography>
                  ))}
                </Box>
              </Box>

              <Box style={webStyle.jobprefeBox}>
                <Typography style={webStyle.preferTitle}>Working Days</Typography>
                <Box>
                  {this.state.selectedWorkingDays.map((days, index) => (
                    <Typography style={{ ...webStyle.prefOption, borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }} key={index}>{days}</Typography>
                  ))}
                </Box>
              </Box>
              <Box style={webStyle.jobprefeBox}>
                <Typography style={webStyle.preferTitle}>Time Shift</Typography>
                <Typography style={{ ...webStyle.prefOption, borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }}>{this.state.selectedTimeShift}</Typography>
              </Box>
              <Box style={webStyle.jobprefeBox}>
                <Typography style={webStyle.preferTitle}>Nature of Job</Typography>
                <Typography style={{ ...webStyle.prefOption, borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }}>{this.state.selectedNatureJob}</Typography>
              </Box>
              <Box style={webStyle.jobprefeBox}>
                <Typography style={webStyle.preferTitle}>Travel Distance</Typography>
                <Typography style={{ ...webStyle.prefOption, borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }}>{this.state.distance}</Typography>
              </Box>
            </Box>}

        </Collapse>
        <Dialog open={this.state.saveSuccessPrefer} data-test-id="saveSuccessPrefer"
          onClose={() => this.setState({ saveSuccessPrefer: false })}>
          <Box style={{ display: 'flex', gap: '5px', padding: '10px' }}>
            <CheckCircle style={{ color: '#34D399' }} />
            <Typography>Changes saved successfully!</Typography>
          </Box>
        </Dialog>
      </>
    )
  }
  avtar = () => {
    return (
      <Box style={{ display: 'flex', gap: '13px', justifyContent: 'center', padding: '24px' }}>
        <Avatar style={{
          ...webStyle.avtarColor, background: this.state.selectedWorkingDays.includes('Monday') ? '#D1FAE5' : '#F1F5F9',
          color: this.state.selectedWorkingDays.includes('Monday') ? '#059669' : '#94A3B8'
        }}>M</Avatar>
        <Avatar style={{
          ...webStyle.avtarColor, background: this.state.selectedWorkingDays.includes('Tuesday') ? '#D1FAE5' : '#F1F5F9',
          color: this.state.selectedWorkingDays.includes('Tuesday') ? '#059669' : '#94A3B8'
        }}>T</Avatar>
        <Avatar style={{
          ...webStyle.avtarColor, background: this.state.selectedWorkingDays.includes('Wednesday') ? '#D1FAE5' : '#F1F5F9',
          color: this.state.selectedWorkingDays.includes('Wednesday') ? '#059669' : '#94A3B8'
        }}>W</Avatar>
        <Avatar style={{
          ...webStyle.avtarColor, background: this.state.selectedWorkingDays.includes('Thursday') ? '#D1FAE5' : '#F1F5F9',
          color: this.state.selectedWorkingDays.includes('Thursday') ? '#059669' : '#94A3B8'
        }}>T</Avatar>
        <Avatar style={{
          ...webStyle.avtarColor, background: this.state.selectedWorkingDays.includes('Friday') ? '#D1FAE5' : '#F1F5F9',
          color: this.state.selectedWorkingDays.includes('Friday') ? '#059669' : '#94A3B8'
        }}>F</Avatar>
        <Avatar style={{
          ...webStyle.avtarColor, background: this.state.selectedWorkingDays.includes('Saturday') ? '#D1FAE5' : '#F1F5F9',
          color: this.state.selectedWorkingDays.includes('Saturday') ? '#059669' : '#94A3B8'
        }}>S</Avatar>
        <Avatar style={{
          ...webStyle.avtarColor, background: this.state.selectedWorkingDays.includes('Sunday') ? '#D1FAE5' : '#F1F5F9',
          color: this.state.selectedWorkingDays.includes('Sunday') ? '#059669' : '#94A3B8'
        }}>S</Avatar>
      </Box>
    )
  }
  toggleBox = (day: string) => {
    return (
      <>
        {!this.state.workingDays[day] && (
          <>
            <Box data-test-id="clickTime" onClick={() => this.clickTime(day)} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
              <Typography style={webStyle.prefOption}>Start time</Typography>
              <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}>{`${this.state.workTimes[day]?.start?.hour || 9}:${this.state.workTimes[day]?.start?.minute || '00'} ${this.state.workTimes[day]?.start?.period || 'AM'}`}</Typography>
            </Box>
            <Box data-test-id="clickEndTime" onClick={() => this.clickEndTime(day)} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
              <Typography style={webStyle.prefOption}>End time</Typography>
              <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}>{`${this.state.workTimes[day]?.end?.hour || 5}:${this.state.workTimes[day]?.end?.minute || '00'} ${this.state.workTimes[day]?.end?.period || 'PM'}`}</Typography>
            </Box>

            <TimeDialog open={this.state.startClicked && this.state.activeDay === day} data-test-id="closeTimeDialog" onClose={this.closeTimeDialog}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '24px', borderBottom: '1px solid #E2E8F0' }}>
                <Typography style={webStyle.popHeading}>
                  Add Start Time
                </Typography>
                <Close onClick={this.closeTimeDialog} />
              </Box>
              <Box style={{ padding: '20px' }}>
                <TimeInput data-test-id="handleTimeChange"
                  value={this.state.workTimes[day]?.start || { hour: 9, minute: 0, period: 'AM' }}
                  onChange={(value) => this.handleTimeChange(day, 'start', value)}
                />
                <Button data-test-id="saveStartTime" onClick={() => this.saveStartTime(day, this.state.workTimes[day]?.start || { hour: 9, minute: 0, period: 'AM' })}
                  fullWidth style={{ ...webStyle.saveBtn, marginTop: '37px', }}>Save</Button>
              </Box>
            </TimeDialog>
            <TimeDialog open={this.state.endClicked && this.state.activeDay === day} onClose={this.closeTimeDialog}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '24px', borderBottom: '1px solid #E2E8F0' }}>
                <Typography style={webStyle.popHeading}>
                  Add End Time
                </Typography>
                <Close onClick={this.closeTimeDialog} />
              </Box>
              <Box style={{ padding: '20px' }}>
                <TimeInput data-test-id="handleTimeChange"
                  value={this.state.workTimes[day]?.end || { hour: 5, minute: 0, period: 'PM' }}
                  onChange={(value) => this.handleTimeChange(day, 'end', value)}
                />
                <Button data-test-id="saveEndTime"
                  onClick={() => this.saveEndTime(day, this.state.workTimes[day]?.end || { hour: 5, minute: 0, period: 'PM' }, this.state.workTimes[day]?.start || { hour: 9, minute: 0, period: 'AM' })}
                  fullWidth style={{ ...webStyle.saveBtn, marginTop: '37px', }}>Save</Button>
              </Box>
            </TimeDialog>
          </>
        )}
      </>
    )
  }
  availability = () => {
    const workingDays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]
    return (
      <>
        <div ref={this.availabilityRef} style={{ ...webStyle.optionLabel, borderBottom: this.state.openAvailbility ? 'unset' : '1px solid #F1F5F9' }} data-test-id="handleAvailibilty" onClick={this.handleAvailibilty}>
          <Typography style={webStyle.optionLabelText}>Availability</Typography>
          <ArrowForwardIosIcon
            style={{
              ...webStyle.expandArrow,
              transform: this.state.openAvailbility ? 'rotate(90deg)' : 'rotate(0deg)',
            }} />
        </div>
        <Collapse in={this.state.openAvailbility}>
          {!this.state.editAvailbility &&
            <Box style={webStyle.jobprefeBox}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '16px', borderBottom: '1px solid #F1F5F9' }}>
                <Typography style={webStyle.optionLabelText}>Preferred Working Days</Typography>
                <Box style={{ display: 'flex', gap: '13px' }} data-test-id="editAvailability" onClick={this.editAvailability}>
                  <Edit />
                  <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>Edit</Typography>
                </Box>
              </Box>
              {this.avtar()}
            </Box>
          }
          {this.state.editAvailbility &&
            <>
              <Box style={webStyle.jobprefeBox}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '16px', borderBottom: '1px solid #F1F5F9' }}>
                  <Typography style={webStyle.optionLabelText}>Preferred Working Days</Typography>
                </Box>
                {workingDays.map((providerType, index) => {
                  const isChecked = this.state.selectedWorkingDays.includes(providerType)
                  return (
                    <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }}>
                      <Typography style={webStyle.prefOption}>{providerType}</Typography>
                      <ThemeProvider theme={theme2}>
                        <Checkbox
                          checked={isChecked}
                          style={{ borderRadius: "10px" }}
                          data-test-id="workingDaysClick"
                          onChange={() => this.handleCheckboxChange(providerType, index)}
                        />
                      </ThemeProvider>
                    </Box>
                  )
                })}
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'end' }}>
                <Button style={webStyle.saveBtn} data-test-id="saveAvailbility" onClick={this.saveAvailbility}>Save</Button>
              </Box>
            </>
          }
          {this.state.selectedWorkingDays.length > 0 &&
            <Typography style={webStyle.preferTime}>Preferred Time</Typography>
          }
          <Box>
            {this.state.selectedWorkingDays.map((day) => (
              <Box key={day} style={{ ...webStyle.jobprefeBox, display: 'flex', flexDirection: 'column', padding: '10px 16px' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography style={webStyle.prefOption}>{day}</Typography>
                  <Box style={{ display: 'flex', gap: '80px', alignItems: 'center' }}>
                    <Typography style={{ ...webStyle.preferTime, marginTop: 'unset' }}>Full day</Typography>
                    <div style={styles.container as React.CSSProperties}>
                      <input
                        type="checkbox"
                        data-test-id="dayToggle"
                        style={styles.checkbox as React.CSSProperties}
                        id={`checkbox${day}`}
                        checked={this.state.workingDays[day]}
                        onChange={() => this.dayToggle(day)}
                      />
                      <label
                        htmlFor={`checkbox${day}`}
                        style={{
                          ...styles.switch,
                          ...(this.state.workingDays[day] && styles.switchChecked),
                        }}
                      >
                        <span
                          style={{
                            ...styles.slider,
                            ...(this.state.workingDays[day] && styles.sliderChecked),
                          } as React.CSSProperties}
                        ></span>
                      </label>
                    </div>
                  </Box>
                </Box>

                {this.toggleBox(day)}
              </Box>
            ))}
          </Box>

        </Collapse>

      </>
    )
  }
  certifications = () => {
    const { files, currentCertificateName, currentFile, fileSubmitted } = this.state;

    const options = [
      'Share with Palmetto',
      'Share with Job Providers',
      'Share with both (Palmetto and Job Providers)'
    ]
    return (
      <>
        <div ref={this.certificateRef} style={webStyle.optionLabel} data-test-id="handleCertificatBox" onClick={this.handleCertificatBox}>
          <Typography style={webStyle.optionLabelText}>Certifications</Typography>
          <ArrowForwardIosIcon
            style={{
              ...webStyle.expandArrow,
              transform: this.state.openCertificate ? 'rotate(90deg)' : 'rotate(0deg)',
            }} />
        </div>
        <Collapse in={this.state.openCertificate} style={{ marginTop: -14, marginBottom: 16 }}>
          <Box style={webStyle.sharewith}>
            <Box style={webStyle.sharewithHeading}>
              <Typography style={webStyle.sharewithTxt}>Share Documents With</Typography>
              <Box style={{ display: 'flex', gap: 2 }} data-test-id="openEditShareWith" onClick={this.openEditShareWith}>
                <Edit style={{ color: '#395169' }} />
                <Typography style={webStyle.editText}>Edit</Typography>
              </Box>
            </Box>
            {this.state.openEditShareWith ? (
              <>
                {options.map((option) => (
                  <Box
                    key={option}
                    style={webStyle.sharewithHeading}
                    data-test-id="handleOptionClick"
                    onClick={() => this.handleOptionClick(option)}
                  >
                    <Typography style={{ ...webStyle.sharewithOptions, padding: 'unset' }}>{option}</Typography>
                    <img src={this.state.selectedShareWith == option ? checkedR : uncheckedR}
                      alt={this.state.selectedShareWith === option ? "Checked" : "Unchecked"}
                      style={{ width: '20px', height: '20px' }} />
                  </Box>
                ))}

              </>
            ) : (
              <Typography style={{ ...webStyle.sharewithOptions, display: this.state.selectedShareWith == '' ? 'none' : '' }}>{this.state.selectedShareWith}</Typography>
            )}
          </Box>
          {this.state.openEditShareWith &&
            <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
              <Button style={webStyle.saveBtn} data-test-id="handleSaveShareWith" onClick={this.handleSaveShareWith}>Save</Button>
            </Box>
          }

          <>
            {!fileSubmitted && (
              <>
                <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', marginBottom: '6px' }}>
                  <Typography style={webStyle.editHeading}>Certificate name</Typography>
                  <MoreVertIcon />
                </Box>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={currentCertificateName}
                  placeholder="Enter school/university"
                  data-test-id="handleCertificateName"
                  onChange={(event) => this.handleCertificateName(event.target.value)}
                />
                <Typography style={webStyle.errorMsg}>{this.state.certificateNameError}</Typography>
                <Typography style={{ ...webStyle.editHeading, marginTop: '30px' }}>Certificate</Typography>
                {!currentFile && (
                  <Box style={webStyle.uploadBox} data-test-id="handleFileUploadClick" onClick={() => this.handleFileUploadClick()}>
                    <img src={upload} />
                    <Typography style={{ ...webStyle.optionLabelText, marginTop: '16px' }}>Upload certificate</Typography>
                    <Typography style={webStyle.jpeg}>JPG, JPEG, PNG or PDF file</Typography>
                    <Box style={{ display: 'flex', justifyContent: 'center', gap: '8px', marginTop: '16px' }}>
                      <AddCircleOutlineOutlined />
                      <Typography style={webStyle.uploadFileTxt}>Upload file</Typography>
                    </Box>
                    <input
                      type="file"
                      ref={this.fileInputRef}
                      style={{ display: 'none' }}
                      accept=".png, .jpg, .jpeg, .pdf"
                      data-test-id="handleFileChange"
                      onChange={this.handleFileChange}
                    />
                  </Box>
                )}
                {this.currentFile()}
              </>
            )}
            {this.certificateUploaded()}
          </>

        </Collapse>
        <Dialog open={this.state.deleteSuccess} data-test-id="deleteSuccess" onClose={() => this.setState({ deleteSuccess: false })}>
          <Box style={{ display: 'flex', gap: '5px', padding: '10px' }}>
            <CheckCircle style={{ color: '#34D399' }} />
            <Typography>Certificate deleted successfully!</Typography>
          </Box>
        </Dialog>
        <Dialog open={this.state.uploadSuccess} dataitest-id="uploadSuccess" onClose={() => this.setState({ uploadSuccess: false })}>
          <Box style={{ display: 'flex', gap: '5px', padding: '10px' }}><CheckCircle style={{ color: '#34D399' }} /> <Typography>New certificate added successfully!</Typography>
          </Box>
        </Dialog>
      </>
    )
  }


  certificationsAdmin = () => {
    return (
      <>
        <div ref={this.certificateRef} style={webStyle.optionLabel}  onClick={this.handleCertificatBox}>
          <Typography style={webStyle.optionLabelText}>School Logo</Typography>
          <ArrowForwardIosIcon
            style={{
              ...webStyle.expandArrow,
              transform: this.state.openCertificate ? 'rotate(90deg)' : 'rotate(0deg)',
            }} />
        </div>
        <Collapse in={this.state.openCertificate} style={{ marginTop: -14, marginBottom: 16 }}>
          <>
              <>
                {(this.state.currentFileUrl == '' || this.state.currentFileUrl == undefined) && (
                  <Box style={webStyle.uploadBox}  data-test-id='certificationsAdmin' onClick={()=>this.handleFileUploadClick()}>
                    <img src={upload} />
                    <Typography style={{ ...webStyle.optionLabelText, marginTop: '16px' }}>Upload certificate</Typography>
                    <Typography style={webStyle.jpeg}>JPG, JPEG, PNG</Typography>
                    <Box style={{ display: 'flex', justifyContent: 'center', gap: '8px', marginTop: '16px' }}>
                      <AddCircleOutlineOutlined />
                      <Typography style={webStyle.uploadFileTxt}>Upload file</Typography>
                    </Box>
                    <input
                      type="file"
                      ref={this.fileInputRef}
                      data-test-id='handleFileChangeAdmin'
                      style={{ display: 'none' }}
                      accept=".png, .jpg, .jpeg"
                      
                      onChange={this.handleFileChangeAdmin}
                    />
                  </Box>
                )}
                {this.currentFileAdmin()}
              </>
          
            {this.state.currentFileUrl && this.certificateUploadedAdmin()}
          </>

        </Collapse>
        <Dialog open={this.state.deleteSuccess} data-test-id="deleteSuccessAdmin" onClose={() => this.setState({ deleteSuccess: false })}>
          <Box style={{ display: 'flex', gap: '5px', padding: '10px' }}>
            <CheckCircle style={{ color: '#34D399' }} />
            <Typography>Certificate deleted successfully!</Typography>
          </Box>
        </Dialog>
        <Dialog open={this.state.uploadSuccess} data-test-id="uploadSuccessAdmin" onClose={() => this.setState({ uploadSuccess: false })}>
          <Box style={{ display: 'flex', gap: '5px', padding: '10px' }}><CheckCircle style={{ color: '#34D399' }} /> <Typography>New certificate added successfully!</Typography>
          </Box>
        </Dialog>
      </>
    )
  }


  currentFile = () => {
    return (
      <>
        {this.state.currentFile && (
          <Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
              <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
                {this.state.currentFile.type === 'application/pdf' ? (
                  <img src={pdfIcon} style={{ width: '40px', height: '40px' }} alt="PDF Icon" />
                ) : (
                  <img src={this.state.currentFileUrl ? this.state.currentFileUrl : URL.createObjectURL(this.state.currentFile)} alt="Uploaded file" style={{ width: '40px', height: '40px' }} />
                )}
                <Box>
                  <Typography style={webStyle.filename}>{this.state.currentFile.name}</Typography>
                  {this.state.SelectedSize==''?
                  <Typography style={webStyle.fileSize}>{(this.state.currentFile.size / 1024).toFixed(2)} KB,{this.state.currentUploadDate}</Typography>
                  : <Typography style={webStyle.fileSize}>{this.state.SelectedSize},{this.state.currentUploadDate}</Typography>
                  }
                </Box>
              </Box>
              <Box data-test-id="anchorElCertificate" onClick={(event) => { this.setState({ anchorElCertificate: event.currentTarget }) }} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                <MoreVertIcon style={{ color: '#64748B' }} />
              </Box>
            </Box>
            <Typography style={webStyle.textheading}>Start Date</Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Select option"
              value={this.state.textFieldValueDocStart}
              data-test-id="handleStartCalenderr"
              style={{ cursor: 'pointer' }}
              onClick={this.handleStartCalender}
              InputProps={{
                style: { pointerEvents: 'none' },
                endAdornment: (
                  <InputAdornment position="end" style={{ pointerEvents: 'auto', cursor: 'pointer' }} onClick={this.handleStartCalender}>
                    <KeyboardArrowDown />
                  </InputAdornment>
                ),
              }}
            />
            <CustomPopover
              id="to-popover"
              open={Boolean(this.state.anchorElDocStart)}
              anchorEl={this.state.anchorElDocStart}
              data-test-id="handleCloseDocanchorElDocStart"
              onClose={this.handleCloseDocanchorElDocStart}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Calendar
                value={this.state.calendarValueDocStart}
                onClickDay={this.handleDateChangesdoc('calendarValueDocStart', 'textFieldValueDocStart', 'anchorElDocStart')}
                data-test-id="handleDateChangesdoc"
                view="month"
                maxDetail="month"
                tileContent={({ date }) => {
                  const isSelectedDate =
                    date.getDate() === this.state.calendarValueDocStart?.getDate() &&
                    date.getMonth() === this.state.calendarValueDocStart?.getMonth() &&
                    date.getFullYear() === this.state.calendarValueDocStart?.getFullYear();
                  const style = {
                    borderRadius: '10px',
                    backgroundColor: isSelectedDate ? '#395169' : undefined,
                    color: isSelectedDate ? 'white' : undefined,
                  };
                  return <div style={style} />;
                }}
              />
            </CustomPopover>
            <Typography style={webStyle.errorMsg}>{this.state.stardateError}</Typography>
            <Typography style={webStyle.textheading}>End Date</Typography>
            <TextField variant="outlined" fullWidth placeholder="Select option"
              value={this.state.textFieldValueDocEnd}
              style={{ cursor: 'pointer' }}
              data-test-id="handleendCalender"
              onClick={this.handleendCalender}
              InputProps={{
                style: { pointerEvents: 'none' },
                endAdornment: (
                  <InputAdornment position="end" style={{ pointerEvents: 'auto', cursor: 'pointer' }} onClick={this.handleendCalender}>
                    <KeyboardArrowDown />
                  </InputAdornment>
                ),
              }} />
            <CustomPopover
              id="to-popover"
              open={Boolean(this.state.anchorElDocEnd)}
              anchorEl={this.state.anchorElDocEnd}
              onClose={this.handleCloseDocanchorElDocStart}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Calendar
                value={this.state.calendarValueDocStart}
                onClickDay={this.handleDateChangesdoc('calendarValueDocEnd', 'textFieldValueDocEnd', 'anchorElDocEnd')}
                data-test-id="handleDateChangesdocTo"
                view="month"
                maxDetail="month"
                tileContent={({ date }) => {
                  const isSelectedDate =
                    date.getDate() === this.state.calendarValueDocEnd?.getDate() &&
                    date.getMonth() === this.state.calendarValueDocEnd?.getMonth() &&
                    date.getFullYear() === this.state.calendarValueDocEnd?.getFullYear();
                  const style = {
                    borderRadius: '10px',
                    backgroundColor: isSelectedDate ? '#395169' : undefined,
                    color: isSelectedDate ? 'white' : undefined,
                  };
                  return <div style={style} />;
                }}
              />
            </CustomPopover>
            <Typography style={webStyle.errorMsg}>{this.state.enddateError}</Typography>
            <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
              <Button style={webStyle.saveBtn} data-test-id="handleSubmitFile" onClick={this.handleSubmitFile}>Submit</Button>
            </Box>
            <DropdownDoc
              anchorEl={this.state.anchorElCertificate}
              open={Boolean(this.state.anchorElCertificate)}
              data-test-id="handleEditPopUpClose2"
              onClose={this.handleEditPopUpClose}
            >

              <MenuItem data-test-id="currentFileReupload" onClick={() => { this.setState({ currentFile: null, currentFileUrl: '' }) }}>
                <Cached />
                <Typography style={webStyle.editText}>Reupload</Typography>
              </MenuItem>
              <MenuItem datat-test-id="currentfiledlete" onClick={() => { this.setState({ currentFile: null, currentCertificateName: '' }) }}>
                <DeleteOutlineOutlinedIcon style={{ color: '#DC2626' }} />
                <Typography style={{ ...webStyle.editText, color: '#DC2626' }}>Delete</Typography>
              </MenuItem>
            </DropdownDoc>
          </Box>
        )}</>
    )
  }
  currentFileAdmin = () => {
    return (
      <>
        {!this.state.currentFileUrl && this.state.currentFile && (
          <Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
              <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
              <img src={URL.createObjectURL(this.state.currentFile)} alt="Uploaded file" style={{ width: '40px', height: '40px' }} />
              </Box>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
              <Button style={webStyle.saveBtn} data-test-id='currentFileAdmin'  onClick={()=>this.handleUpdateFromAdmin()}>Submit</Button>
            </Box>
          </Box>
        )}
        </>
    )
  }
  certificateUploaded = () => {
    return (
      <>
        {this.state.fileSubmitted && (
          <>
            {this.state.files.map((fileDetail) => (
              <Box key={fileDetail.id} style={{ ...webStyle.sharewith, marginTop: '33px' }}>
                <Box style={webStyle.sharewithHeading}>
                  <Typography style={webStyle.sharewithTxt}>{fileDetail.certificateName}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
                    {fileDetail.file.type === 'application/pdf' ? (
                      <img src={pdfIcon} style={{ width: '40px', height: '40px' }} />
                    ) : (
                      <img src={fileDetail.url} alt="Uploaded file" style={{ width: '40px', height: '40px' }} />
                    )}
                    <Box>
                      <Typography style={webStyle.filename}>{fileDetail.file.name}</Typography>
                      <Typography style={webStyle.fileSize}>{fileDetail.size}, {fileDetail.uploadDate}</Typography>
                    </Box>
                  </Box>
                  <Box data-test-id="handleEditPopUp" onClick={(event) => this.handleEditPopUp(event, fileDetail.id)} style={{ display: 'flex', alignItems: 'center' }}>
                    <MoreVertIcon style={{ color: '#64748B' }} />
                  </Box>
                  <DropdownDoc
                    anchorEl={this.state.anchorElCertificate && this.state.anchorElFileId === fileDetail.id ? this.state.anchorElCertificate : null}
                    open={Boolean(this.state.anchorElCertificate && this.state.anchorElFileId === fileDetail.id)}
                    data-test-id="EditPopUpClose"
                    onClose={this.handleEditPopUpClose}
                  >
                    <MenuItem data-test-id="handleViewClick"
                      onClick={() => this.handleViewClick(fileDetail.url as string)}>
                      <VisibilityOutlined />
                      <Typography style={webStyle.editText}>View</Typography>
                    </MenuItem>
                    <MenuItem data-test-id="handleEditCerificate"
                      onClick={() => this.handleEditClick(fileDetail.certificateName, fileDetail.file, fileDetail.certification_id,
                        fileDetail.attachment_id, fileDetail.uploadDate, fileDetail.url as string, fileDetail.attachment_type, fileDetail.start_date, fileDetail.end_date,fileDetail.size)}>
                      <EditOutlinedIcon />
                      <Typography style={webStyle.editText}>Edit</Typography>
                    </MenuItem>
                    <MenuItem data-test-id="handleReuploadClick"
                      onClick={() => this.handleReuploadClick(fileDetail.certificateName, fileDetail.certification_id, fileDetail.attachment_id, fileDetail.attachment_type)}>
                      <Cached />
                      <Typography style={webStyle.editText}>Reupload</Typography>
                    </MenuItem>
                    <MenuItem data-test-id="handleDeletCerificate" onClick={() => this.handleDeletCerificate(fileDetail.attachment_type, fileDetail.certification_id, fileDetail.attachment_id)}>
                      <DeleteOutlineOutlinedIcon style={{ color: '#DC2626' }} />
                      <Typography style={{ ...webStyle.editText, color: '#DC2626' }}>Delete</Typography>
                    </MenuItem>
                  </DropdownDoc>
                </Box>
              </Box>
            ))}

            <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
              <Button style={webStyle.saveBtn} data-test-id="addeNewFile" onClick={this.addeNewFile}> <Add /> Add New</Button>
            </Box>
          </>
        )}
      </>
    )
  }
  certificateUploadedAdmin = () => {
    return (
      <>
        {this.state.currentFileUrl && (
          <>
              <Box  style={{ ...webStyle.sharewith, marginTop: '33px' }}>
                <Box style={webStyle.sharewithHeading}>
                  <Typography style={webStyle.sharewithTxt}>Logo</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
                    
                      <img src={this.state.currentFileUrl} alt="Uploaded file" style={{ width: '40px', height: '40px' }} />
                  
                  </Box>
                  <Box data-test-id='handleEditPopUpAdminAdmin'  onClick={(event) => this.handleEditPopUpAdmin(event)} style={{ display: 'flex', alignItems: 'center' }}>
                    <MoreVertIcon style={{ color: '#64748B' }} />
                  </Box>
                  <DropdownDoc
                    anchorEl={this.state.anchorElCertificate }
                    open={Boolean(this.state.anchorElCertificate)}
                    data-test-id='anchorElCertificateAdmin'
                    onClose={this.handleEditPopUpClose}
                  >
                    <MenuItem 
                    data-test-id='handleViewClickAdmin'
                        onClick={() => this.handleViewClick(this.state.currentFileUrl)}>
                      <VisibilityOutlined />
                      <Typography style={webStyle.editText}>View</Typography>
                    </MenuItem>
                    <MenuItem  
                    data-test-id='handleDeleteLogoAdmin'
                    onClick={()=>this.handleDeleteLogo(this.state.LogoId)} >
                      <DeleteOutlineOutlinedIcon style={{ color: '#DC2626' }} />
                      <Typography style={{ ...webStyle.editText, color: '#DC2626' }}>Delete</Typography>
                    </MenuItem>
                  </DropdownDoc>
                </Box>
              </Box>
          </>
        )}
      </>
    )
  }
  openPopover = () => {
    return (
      <>
        <CustomPopover
          id="to-popover"
          open={Boolean(this.state.anchorElEduFrom)}
          anchorEl={this.state.anchorElEduFrom}
          data-test-id="handleCloseEduFrom"
          onClose={this.handleCloseEduFrom}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Calendar
            value={this.state.calendarValueEduFrom}
            data-test-id="handleDateChangeEduFrom"
            onClickMonth={this.handleDateChanges('calendarValueEduFrom', 'textFieldValueEduFrom', 'anchorElEduFrom')}
            view="year"
            maxDetail="year"
            tileContent={({ date }) => {
              const isSelectedMonth = date.getMonth() === this.state.calendarValueEduFrom?.getMonth() && date.getFullYear() === this.state.calendarValueEduFrom?.getFullYear();
              const style = {
                borderRadius: '10px',
                backgroundColor: isSelectedMonth ? '#395169' : undefined,
                color: isSelectedMonth ? 'white' : undefined,
              };
              return <div style={style} />;
            }}
          />
        </CustomPopover></>
    )
  }
  openPopover2 = () => {
    return (
      <>
        <CustomPopover
          id="to-popover"
          open={Boolean(this.state.anchorElEduTo)}
          anchorEl={this.state.anchorElEduTo}
          data-test-id="handleCloseEduTo"
          onClose={this.handleCloseEduTo}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Calendar
            value={this.state.calendarValueEduTo}
            onClickMonth={this.handleDateChanges('calendarValueEduTo', 'textFieldValueEduTo', 'anchorElEduTo')}
            data-test-id="handleDateChangeEduTo"
            view="year"
            maxDetail="year"
            tileContent={({ date }) => {
              const isSelectedMonth = date.getMonth() === this.state.calendarValueEduTo?.getMonth() && date.getFullYear() === this.state.calendarValueEduTo?.getFullYear();
              const style = {
                borderRadius: '10px',
                backgroundColor: isSelectedMonth ? '#395169' : undefined,
                color: isSelectedMonth ? 'white' : undefined,
              };
              return <div style={style} />;
            }}
          />
        </CustomPopover></>
    )
  }

  showTitle = ()=>{
    const {saved_jobs_count ,saved_substitute_teachers_count,  viewed_jobs_count , job_created_count , applied_jobs_count , job_accepted_by_substitute_count} = this.state    
    const data = {
      title :{
        first : "Job Created",
        second : "Job Response",
        third : "Saved Teachers"
       },
       value :{
        first : job_created_count,
        second : job_accepted_by_substitute_count,
        third : saved_substitute_teachers_count
       }
    }

    const data2 = {
      title :{
        first : "Job View",
        second : "Job Applied",
        third : "Saved Jobs"
       },
       value :{
        first : viewed_jobs_count,
        second : applied_jobs_count,
        third : saved_jobs_count
       }
    }

    return  this.state.profileRoleId == 1 ?data:data2 
  }

  linksByRole: Record<number, any[]> = {
    1: [
      { label: 'Personal Information', handler: 'personalInfoClick' },
      { label: 'School Bio', handler: 'personalLink' },
      { label: 'School Location', handler: 'resumeLink' },
      { label: 'School Logo', handler: 'certificateLink' },
      { label: 'Job Preferences', handler: 'preferLink' }
    ],
    2: [
      { label: 'Personal Information', handler: 'personalLink' },
      { label: 'Resume', handler: 'resumeLink' },
      { label: 'Certifications', handler: 'certificateLink' },
      { label: 'Job Preferences', handler: 'preferLink' },
      { label: 'Availability', handler: 'availabilityLink' }
    ]
  };


  ProfileLinks = () => {
    const onClickHandlers: any = {
      personalLink: () => { this.personalLink() },
      resumeLink: () => { this.resumeLink() },
      certificateLink: () => { this.certificateLink() },
      preferLink: () => { this.preferLink() },
      availabilityLink: () => { this.availabilityLink() },
      personalInfoClick:() => {this.personalInfoClick()}
    };
    const links = this.linksByRole[this.state.profileRoleId] || [];
    return (
      <QuickLinks item xs={12} sm={4}>
        <Box style={webStyle.profileInfo}>
          <Typography style={webStyle.quicklink}>
            {this.state.profileRoleId == 1 ? 'School Information' : 'Quick Links'}
          </Typography>
          {links.map(({ label, handler }) => (
            <Typography
              key={label}
              data-test-id={`quicklink`}
              onClick={() => onClickHandlers[handler]()}
              style={webStyle.links}
            >
              {label}
            </Typography>
          ))}
        </Box>
      </QuickLinks>
    );
  };

  AddressFields = (dataTestId:string) => {
    const {street , profileCity , zipCode , country, state , editModeAdd} = this.state
    return (
        <div style={{ marginTop: 20 }}>
            {editModeAdd ? (
                <>
                    <Typography style={webStyle.editHeading}>Street</Typography>
                    <TextField
                        name="street"
                        variant="outlined"
                        fullWidth
                        value={street}
                        style={{ marginTop: 6 }}
                        onChange={this.handleEditChange}
                    />
                    <Typography style={webStyle.editHeading}>City</Typography>
                    <TextField
                        name="profileCity"
                        variant="outlined"
                        fullWidth
                        value={profileCity}
                        style={{ marginTop: 6 }}
                        onChange={this.handleEditChange}
                    />
                    <Typography style={webStyle.editHeading}>State</Typography>
                    <TextField
                        name="state"
                        variant="outlined"
                        fullWidth
                        value={state}
                        style={{ marginTop: 6 }}
                        onChange={this.handleEditChange}
                    />
                    <Typography style={webStyle.editHeading}>Country</Typography>
                    <TextField
                        name="country"
                        variant="outlined"
                        fullWidth
                        value={country}
                        style={{ marginTop: 6 }}
                        onChange={this.handleEditChange}
                    />
                    <Typography style={webStyle.editHeading}>Zip Code</Typography>
                    <TextField
                        name="zipCode"
                        variant="outlined"
                        fullWidth
                        value={zipCode}
                        style={{ marginTop: 6 }}
                        onChange={this.handleEditChange}
                    />
                    <Box style={{ display: "flex", justifyContent: "end" }}>
                        <Button
                            style={webStyle.saveBtn}
                            data-test-id={dataTestId}
                            onClick={()=>this.updateProfileDataSecond()}
                        >
                            Save Changes
                        </Button>
                    </Box>
                </>
            ) : (
                <>
                    <Box style={webStyle.space}>
                        <Typography style={webStyle.editHeading}>Street</Typography>
                        <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>
                            {street}
                        </Typography>
                    </Box>
                    <Box style={webStyle.space}>
                        <Typography style={webStyle.editHeading}>City</Typography>
                        <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>
                            {profileCity}
                        </Typography>
                    </Box>
                    <Box style={webStyle.space}>
                        <Typography style={webStyle.editHeading}>State</Typography>
                        <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>
                            {state}
                        </Typography>
                    </Box>
                    <Box style={webStyle.space}>
                        <Typography style={webStyle.editHeading}>Country</Typography>
                        <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>
                            {country}
                        </Typography>
                    </Box>
                    <Box style={webStyle.space}>
                        <Typography style={webStyle.editHeading}>Zip Code</Typography>
                        <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>
                            {zipCode}
                        </Typography>
                    </Box>
                </>
            )}
        </div>
    );
};

AddressAdminFields = ( dataTestId:string) => {
  const {s_street , s_city , s_country , s_state, s_zip , editModeAdd} = this.state
  return (
    <div style={{ marginTop: 20 }}>
      {editModeAdd ? (
        <>
          <Typography style={webStyle.editHeading}>Street</Typography>
          <TextField
            name="s_street"
            variant="outlined"
            fullWidth
            value={s_street}
            style={{ marginTop: 6 }}
            onChange={this.handleEditChange}
          />
          <Typography style={webStyle.editHeading}>City</Typography>
          <TextField
            name="s_city"
            variant="outlined"
            fullWidth
            value={s_city}
            style={{ marginTop: 6 }}
            onChange={this.handleEditChange}
          />
          <Typography style={webStyle.editHeading}>State</Typography>
          <TextField
            name="s_state"
            variant="outlined"
            fullWidth
            value={s_state}
            style={{ marginTop: 6 }}
            onChange={this.handleEditChange}
          />
          <Typography style={webStyle.editHeading}>Country</Typography>
          <TextField
            name="s_country"
            variant="outlined"
            fullWidth
            value={s_country}
            style={{ marginTop: 6 }}
            onChange={this.handleEditChange}
          />
          <Typography style={webStyle.editHeading}>Zip Code</Typography>
          <TextField
            name="s_zip"
            variant="outlined"
            fullWidth
            value={s_zip}
            style={{ marginTop: 6 }}
            onChange={this.handleEditChange}
          />
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={webStyle.saveBtn}
              data-test-id={dataTestId}
              onClick={() => this.handleUpdateFromAdmin()}
            >Save Changes</Button>
          </Box>
        </>
      ) : (
          <>
            <Box style={webStyle.space}>
              <Typography style={webStyle.editHeading}>Street</Typography>
              <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>{s_street}</Typography>
            </Box>
            <Box style={webStyle.space}>
              <Typography style={webStyle.editHeading}>City</Typography>
              <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>{s_city}</Typography>
            </Box>
            <Box style={webStyle.space}>
              <Typography style={webStyle.editHeading}>State</Typography>
              <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>{s_state}</Typography>
            </Box>
            <Box style={webStyle.space}>
              <Typography style={webStyle.editHeading}>Country</Typography>
              <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>{s_country}</Typography>
            </Box>
            <Box style={webStyle.space}>
              <Typography style={webStyle.editHeading}>Zip Code</Typography>
              <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>{s_zip} </Typography>
                    </Box>
                </>
            )}
        </div>
    );
};

renderArrowIcon = (isOpen:boolean) => (
  <ArrowForwardIosIcon
    style={{
      ...webStyle.expandArrow,
      transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
    }}
  />
);

renderEditBox = (onClickHandler:any, text:string) => (
  <Box style={{ display: "flex", gap: 13 }} onClick={onClickHandler}>
    <EditIcon style={webStyle.editIcon} />
    <Typography style={{ ...webStyle.editType, fontWeight: 400 }}>{text}</Typography>
  </Box>
);

renderTextField = () => (
  <>
    <TextField
      fullWidth
      variant="outlined"
      multiline
      minRows={7}
      name="introduction"
      style={{ marginTop: 16 }}
      placeholder="Write something..."
      value={this.state.introductionText}
      onChange={this.handleTextChange}
    />
    <Typography style={webStyle.errorMsg}>{this.state.introError}</Typography>
    <Box style={{ display: 'flex', justifyContent: 'end' }}>
      <Button style={webStyle.saveBtn} data-test-id='isIntroEditClickedAdmin' onClick={this.handleUpdateFromAdmin}>
        Submit
      </Button>
    </Box>
  </>
);

renderCollapseSection = (isOpen:boolean, content:any) => (
  <Collapse in={isOpen} style={{ marginTop: -14, marginBottom: 16 }}>
    {content}
  </Collapse>
);

renderSchoolBio = () => (
  <>
    <div ref={this.personalInfoRef} style={webStyle.optionLabel} onClick={this.handlePersonalInfo}>
      <Typography style={webStyle.optionLabelText}>School Bio</Typography>
      {this.renderArrowIcon(this.state.openPersonalInfo)}
    </div>
    {this.renderCollapseSection(
      this.state.openPersonalInfo,
      <>
        <Box style={webStyle.space}>
          <Typography style={webStyle.editHeading}>School Introduction</Typography>
          <Box onClick={this.handleEditIntro} data-test-id="isIntorEditClickedAdmin" style={{ display: "flex" }}>
            <EditOutlinedIcon />
            <Typography style={webStyle.editText}>Edit</Typography>
          </Box>
        </Box>
        {this.state.openIntroduction && !this.state.introductionValueBox && this.renderTextField()}
        {this.showIntroBoxAdmin()}
        <Dropdown anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleEdiDeleClose}>
          <MenuItem onClick={this.handleEditIntro}>
            <EditOutlinedIcon />
            <Typography style={webStyle.editText}>Edit</Typography>
          </MenuItem>
        </Dropdown>
      </>
    )}
  </>
);

renderSchoolLocation = () => (
  <>
    <div ref={this.resumeRef} style={webStyle.optionLabel} onClick={this.handleResumeBox}>
      <Typography style={webStyle.optionLabelText}>School Location</Typography>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        {this.renderArrowIcon(this.state.openResume)}
      </Box>
    </div>
    {this.renderCollapseSection(
      this.state.openResume,
      <>
        <Box style={{ borderBottom: "1px solid #F1F5F9", paddingBottom: 16 }}>
          <Box style={webStyle.space}>
            <Typography style={webStyle.editType}>Address</Typography>
            {this.renderEditBox(this.toggleEditModeAdd, "Edit")}
          </Box>
          {this.AddressAdminFields("handleUpdateFromAdmin")}
        </Box>
      </>
    )}
  </>
);
  renderPersonalInfrormation = () => {
    return (
      <>
        <div ref={this.personalRef} style={webStyle.optionLabel} data-test-id="handlePersonalInfo" onClick={this.handleSInfo}>
          <Typography style={webStyle.optionLabelText}>Personal Information</Typography>
          <ArrowForwardIosIcon
            style={{
              ...webStyle.expandArrow,
              transform: this.state.openSinfo ? 'rotate(90deg)' : 'rotate(0deg)',
            }}
          />
        </div>
        <Collapse in={this.state.openSinfo} style={{ marginTop: -14, marginBottom: 16 }}>
          <Box style={{ borderBottom: "1px solid #F1F5F9", paddingBottom: 16 }}>
            <Box style={webStyle.space}>
              <Typography style={webStyle.editType}>
                Account
              </Typography>
              <Box style={{ display: "flex", gap: 13 }} data-test-id="toggleEditMode" onClick={this.toggleEditMode}>
                <EditIcon style={webStyle.editIcon} />
                <Typography style={{ ...webStyle.editType, fontWeight: 400 }}>Edit</Typography>
              </Box>
            </Box>
            {this.state.editModeAcc ?
              <div style={{ marginTop: 20 }}>
                <Typography style={webStyle.editHeading}>First Name</Typography>
                <TextField
                  name="firstName" variant="outlined" fullWidth
                  value={this.state.firstName}
                  style={{ marginTop: 6 }}
                  data-test-id="handleEditChange"
                  onChange={this.handleEditChange}
                />
                <Typography style={webStyle.editHeading}>Last Name</Typography>
                <TextField
                  name="last_Name" variant="outlined" fullWidth
                  value={this.state.last_Name}
                  style={{ marginTop: 6 }}
                  onChange={this.handleEditChange}
                />
                <Typography style={webStyle.editHeading}>Phone Number</Typography>
                <TextField
                  name="phoneNum" variant="outlined" fullWidth
                  value={this.state.phoneNum}
                  style={{ marginTop: 6 }}
                  onChange={this.handleEditChange}
                />
                <Typography style={webStyle.editHeading}>Email</Typography>
                <TextField
                  name="profileEmail" variant="outlined" fullWidth
                  value={this.state.profileEmail}
                  style={{ marginTop: 6 }}
                  onChange={this.handleEditChange}
                />
                <Box style={{ display: "flex", justifyContent: "end" }}>
                  <Button style={webStyle.saveBtn} data-test-id="editModeAccid" onClick={this.updateProfileData}>Save Changes</Button>
                </Box>
              </div>
              :
              <>
                <Box style={webStyle.space}>
                  <Typography style={webStyle.editHeading}>First Name</Typography>
                  <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>{this.state.firstName}</Typography>
                </Box>
                <Box style={webStyle.space}>
                  <Typography style={webStyle.editHeading}>Last Name</Typography>
                  <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>{this.state.last_Name}</Typography>
                </Box>
                <Box style={webStyle.space}>
                  <Typography style={webStyle.editHeading}>Phone Number</Typography>
                  <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>{this.state.phoneNum}</Typography>
                </Box>
                <Box style={webStyle.space}>
                  <Typography style={webStyle.editHeading}>Email</Typography>
                  <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }}>{this.state.profileEmail}</Typography>
                </Box></>}
          </Box>
          <Box style={{ borderBottom: "1px solid #F1F5F9", paddingBottom: 16 }}>
            <Box style={webStyle.space}>
              <Typography style={webStyle.editType}>
                Address
              </Typography>
              <Box style={{ display: "flex", gap: 13 }} data-test-id="toggleEditModeAdd" onClick={this.toggleEditModeAdd}>
                <EditIcon style={webStyle.editIcon} />
                <Typography style={{ ...webStyle.editType, fontWeight: 400 }}>Edit</Typography>
              </Box>
            </Box>
            {this.AddressFields("editModeAdd")}
          </Box>
        </Collapse>
      </>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { sliderValue } = this.state;
    const percentageValue = Math.round(sliderValue * 10)
    const { anchorEl } = this.state;
    const { schoolName, course, specialization, textFieldValueEduFrom, textFieldValueEduTo } = this.state.currentEducation;
    return (
      <ThemeProvider theme={theme}>
        <>
          <CustomNavbar>
            <Navbar navigation={this.props.navigation} id={""} NaviagationPage={""} />
          </CustomNavbar>
          <CustomProfileHead>
            <ArrowBackIosIcon data-test-id="navigateToHome" onClick={this.navigateToHome} style={{ color: "#fff" }} />
            <NavbarTitle>Profile</NavbarTitle>
            <MenuIcon
          style={{ color: '#fff', position: 'absolute', right: '47px',cursor:'pointer' }}
          data-test-id="toggleDrawer"
          onClick={() => this.toggleDrawer(true)}
        />
          </CustomProfileHead>
          <DrawerComponent
          isOpen={this.state.isDrawerOpen}
          toggleDrawer={this.toggleDrawer} navigation={this.props.navigation}        />
          <MainBox>
            <Navigation>
              <Typography data-test-id="navigateToHome" onClick={this.navigateToHome} style={webStyle.home}>Home</Typography>
              <ArrowForwardIosIcon style={webStyle.forwardArrow} />
              <Typography style={webStyle.joblisting}>Profile</Typography>
            </Navigation>
            <ProfileHeading>Profile</ProfileHeading>
            <ProfileInfo>
              <Grid container spacing={2}>
                <DeskCustomeGrid item xs={12} sm={2}>
                  <img
                    src={this.state.prfilePic || profilePic}
                    style={webStyle.profilepic}
                    data-test-id="handleImageClick1"
                    onClick={this.handleImageClick}
                  />
                  <input
                    type="file"
                    ref={this.profileInputRef}
                    style={{ display: 'none' }}
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={this.handleProfileChange}
                    data-test-id="handleProfileChange1"
                  />
                </DeskCustomeGrid>
                <DeskCustomeGrid item xs={12} sm={12} md={5}>
                  <Box>
                  <Typography style={webStyle.name}>{this.state.firstName} {this.state.last_Name}</Typography>
                  <Typography style={webStyle.email}>{this.state.profileEmail}</Typography>
                  </Box>
                  <DeskCustomeGrid container spacing={1} style={{ marginTop: "30px", display: "flex"}}>
                    <DeskCustomeGrid item>
                      <Box style={webStyle.countingBox}>
                        <Typography style={webStyle.numbers}>{this.showTitle().value.first}</Typography>
                        <CountType>{this.showTitle().title.first}</CountType>
                      </Box>
                    </DeskCustomeGrid>
                    <DeskCustomeGrid item>
                      <Box style={webStyle.countingBox}>
                        <Typography style={webStyle.numbers}>{this.showTitle().value.second}</Typography>
                        <CountType>{this.showTitle().title.second}</CountType>
                      </Box>
                    </DeskCustomeGrid>
                    <DeskCustomeGrid item>
                      <Box style={webStyle.countingBox}>
                        <Typography style={webStyle.numbers}>{this.showTitle().value.third}</Typography>
                        <CountType>{this.showTitle().title.third}</CountType>
                      </Box>
                    </DeskCustomeGrid>
                  </DeskCustomeGrid>
                </DeskCustomeGrid>
                {/* -------------- */}
                <MobileCustomGrid style={{width:"100%" ,display:"flex" , padding:"8px"}}>
                  <Box style={{display:"flex" , marginRight:"20px"}}>
                  <img
                    src={this.state.prfilePic || profilePic}
                    style={webStyle.profilepicMob}
                    data-test-id="handleImageClick"
                    onClick={this.handleImageClick}
                    />
                  <input
                    type="file"
                    ref={this.profileInputRef}
                    style={{ display: 'none' }}
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={this.handleProfileChange}
                    data-test-id="handleProfileChange"
                    />
                    </Box>
                   <Box style={{display:"flex" , justifyContent : "center" , flexDirection:"column"}}>
                  <Typography style={webStyle.nameMob}>{this.state.firstName} {this.state.last_Name}</Typography>
                  <Typography style={webStyle.emailMob}>{this.state.profileEmail}</Typography>
                  </Box>
                </MobileCustomGrid>
                <MobileCustomGrid item xs={12} sm={12} md={5}> 
                  <MobileCustomGrid container spacing={1} style={{ marginTop: "30px", display: "flex" , justifyContent: "center"}}>
                  <MobileCustomGrid item>
                      <Box style={webStyle.countingBox}>
                        <Typography style={webStyle.numbers}>{this.showTitle().value.first}</Typography>
                        <CountType>{this.showTitle().title.first}</CountType>
                      </Box>
                    </MobileCustomGrid>
                    <MobileCustomGrid item>
                      <Box style={webStyle.countingBox}>
                        <Typography style={webStyle.numbers}>{this.showTitle().value.second}</Typography>
                        <CountType>{this.showTitle().title.second}</CountType>
                      </Box>
                    </MobileCustomGrid>
                    <MobileCustomGrid item>
                      <Box style={webStyle.countingBox}>
                        <Typography style={webStyle.numbers}>{this.showTitle().value.third}</Typography>
                        <CountType>{this.showTitle().title.third}</CountType>
                      </Box>
                    </MobileCustomGrid>
                  </MobileCustomGrid>
                </MobileCustomGrid>
                {/* --------------------- */}

                <Grid item xs={12} sm={12} md={5}>
                  <Box style={webStyle.profileStatusBox}>
                    <Typography style={webStyle.profileCompletion}>Profile Completion:</Typography>
                    <Box style={webStyle.sliderBox}>
                      <Box style={{ width: "214px" }}>
                        <SliderComponent
                          value={sliderValue}
                          min={0}
                          max={10}
                          step={1}
                          data-test-id="slider"
                          valueLabelDisplay="on"
                          valueLabelFormat={(value) => `${percentageValue}%`}
                          onChange={() => { }}
                          onChangeCommitted={() => { }}
                        />
                      </Box>
                      <img src={flaficon} />
                    </Box>
                    <Box style={{ marginTop: 5 }}>
                      {this.state.emptyProfile.map((item: string) => (
                        <Box style={webStyle.remainingItemBox}>
                          <img src={addicon} />
                          <Typography style={webStyle.addmore}>{item}</Typography>
                        </Box>
                      ))}
                    </Box>

                  </Box>
                  <Box style={webStyle.remaining}>
                    <Typography style={webStyle.text}>Your profile has {this.state.emptyProfile.length} areas for improvement.
                    </Typography>
                    <ArrowForwardIosIcon style={webStyle.arrowWhite} />
                  </Box>
                </Grid>
              </Grid>
            </ProfileInfo>
            <Grid container style={{ marginTop: "24px" }} spacing={2}>
             {this.ProfileLinks()}
              {this.state.profileRoleId == 2 && <Grid item xs={12} sm={12} md={8}>
                <Box style={webStyle.profileInfo}>
                {this.renderPersonalInfrormation()}
                  <div ref={this.resumeRef} style={webStyle.optionLabel} data-test-id="handleResumeBox" onClick={this.handleResumeBox}>
                    <Typography style={webStyle.optionLabelText}>Resume</Typography>
                    <ArrowForwardIosIcon
                      style={{
                        ...webStyle.expandArrow,
                        transform: this.state.openResume ? 'rotate(90deg)' : 'rotate(0deg)',
                      }} />
                  </div>
                  <Collapse in={this.state.openResume} style={{ marginTop: -14, marginBottom: 16 }}>
                    <Box style={{ borderBottom: "1px solid #F1F5F9", paddingBottom: 16 }}>


                      <Box style={webStyle.space}>
                        <Typography style={webStyle.editHeading}>Introduce Yourself</Typography>
                        {this.state.introductionText || this.state.openIntroduction ? (
                          <Box data-test-id="handleThreedotSelect" onClick={this.handleThreedotSelect}>
                            <MoreVertIcon />
                          </Box>
                        ) : (
                          <Typography style={{ ...webStyle.editHeading, fontWeight: 700, cursor: "pointer" }} data-test-id="handleAddDetails" onClick={this.handleAddDetails}>
                            + Add details
                          </Typography>
                        )}
                      </Box>

                      {this.state.openIntroduction && !this.state.introductionValueBox && (
                        <>
                          <TextField
                            fullWidth
                            variant="outlined"
                            multiline
                            data-test-id="handleTextChange"
                            minRows={7}
                            style={{ marginTop: 16 }}
                            placeholder="Write something..."
                            value={this.state.introductionText}
                            onChange={this.handleTextChange}
                          />
                          <Typography style={webStyle.errorMsg}>{this.state.introError}</Typography>
                          <Box style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button style={webStyle.saveBtn} data-test-id="handleIntroSubmit" onClick={this.handleIntroSubmit}>Submit</Button>
                          </Box>
                        </>
                      )}
                      {this.showIntroBox()}
                      <Dropdown
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleEdiDeleClose}
                      >
                        <MenuItem data-test-id="handleEdit" onClick={this.handleEditIntro}>
                          <EditOutlinedIcon />
                          <Typography style={webStyle.editText}>Edit</Typography>
                        </MenuItem>
                        <MenuItem data-test-id="handleDelete" onClick={this.handleDelete}>
                          <DeleteOutlineOutlinedIcon style={{ color: '#DC2626' }} />
                          <Typography style={{ ...webStyle.editText, color: '#DC2626' }}>Delete</Typography>
                        </MenuItem>
                      </Dropdown>


                      <Box style={webStyle.space}>
                        <Typography style={webStyle.editHeading}>Education</Typography>
                        {this.state.openEducation || this.state.eduSubmitted ?
                          <Box>
                            <MoreVertIcon />
                          </Box>
                          :
                          <Typography style={{ ...webStyle.editHeading, fontWeight: 700 }} data-test-id="openEducationBox" onClick={this.openEducationBox}>+ Add details</Typography>
                        }
                      </Box>
                      {this.state.openEducation && (
                        <Box style={{ marginTop: 32 }}>
                          <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>School / University</Typography>
                          <TextField fullWidth variant="outlined" placeholder="Enter school / university" value={schoolName}
                            data-test-id="onValueChange"
                            onChange={(event) => {
                              this.handleInputChange('schoolName', event.target.value);
                            }} />
                          <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>Course</Typography>
                          <Select variant="outlined" IconComponent={KeyboardArrowDown} fullWidth value={course}
                            onChange={(event) => {
                              this.handleInputChange('course', event.target.value);
                            }}
                            data-test-id="onValueChange2"
                            style={{ color: course == 'Select option' ? 'darkgray' : '#000' }}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                            }}>
                            <MenuItem key='Select option' value='Select option' style={{ display: 'none' }}>Select option</MenuItem>
                            {this.state.courseList.map((item) => (
                              <MenuItem key={item} value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                          <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>Specialization</Typography>
                          <TextField variant="outlined" fullWidth placeholder="Enter specialization" data-test-id="specialization" value={specialization}
                            onChange={(event) => {
                              this.handleInputChange('specialization', event.target.value);
                            }} />
                          <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>From</Typography>
                          <TextField variant="outlined" fullWidth
                            value={textFieldValueEduFrom}
                            placeholder="Select date" data-test-id="handleClickEduFrom"
                            onClick={this.handleClickEduFrom}
                            style={{ cursor: 'pointer' }}
                            InputProps={{
                              style: { pointerEvents: 'none' },
                              endAdornment: (
                                <InputAdornment position="end" style={{ pointerEvents: 'auto', cursor: 'pointer' }}>
                                  <img src={calendericon} data-test-id="openCalender" onClick={this.handleClickEduFrom} />
                                </InputAdornment>
                              ),
                            }} />
                          {this.openPopover()}
                          <Typography style={{ ...webStyle.editHeading, marginTop: 10 }}>To</Typography>
                          <TextField variant="outlined" fullWidth data-test-id="handleClickEduTo"
                            placeholder="Select date"
                            value={textFieldValueEduTo}
                            style={{ cursor: 'pointer' }}
                            onClick={this.handleClickEduTo}
                            InputProps={{
                              style: { pointerEvents: 'none' },
                              endAdornment: (
                                <InputAdornment position="end" style={{ pointerEvents: 'auto', cursor: 'pointer' }}>
                                  <img src={calendericon} data-test-id="openCalenderTwo" onClick={this.handleClickEduTo} />
                                </InputAdornment>
                              ),
                            }} />
                          {this.openPopover2()}
                          <Typography style={webStyle.errorMsg}>{this.state.eduOneError}</Typography>
                          <Box style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button style={webStyle.saveBtn} data-test-id="handleEducationSubmit" onClick={this.handleEducationSubmit}>Submit</Button>
                          </Box>
                        </Box>
                      )}

                      {this.eduSbmitted()}
                     

                      <CustomDialog open={this.state.deleteEduConfirmOne}>
                        <Close data-test-id="handleDeleteEduConf" style={{ position: 'absolute', right: 26, top: 29, cursor: 'pointer' }} onClick={() => { this.setState({ deleteEduConfirmOne: false }) }} />
                        <Typography style={webStyle.confirmheading}>
                          Are you sure you want to delete this education qualification?
                        </Typography>
                        <Button data-test-id="handleDeleteEduConf" onClick={() => { this.setState({ deleteEduConfirmOne: false }) }} style={{ ...webStyle.saveBtn, fontSize: '18px', width: '100%', height: '46px', marginTop: '15px' }}>
                          No, Keep it
                        </Button>
                        <Button data-test-id="handleDeleteEduConf" onClick={this.handleDeleteEducationOne} style={{ ...webStyle.confirmDelete, width: '100%' }}>
                          Yes, Delete
                        </Button>
                      </CustomDialog>
                      

                      {this.workExperience()}

                      {this.skills()}

                      {this.teachingIntrest()}

                    </Box>
                  </Collapse>

                  {this.certifications()}
                  {this.jobPreferences()}
                  {this.availability()}

                </Box>
                <Box style={{ ...webStyle.profileInfo, marginTop: "24px" }}>
                  <Box style={webStyle.optionLabel} data-test-id="handleSavedJobsApp" onClick={this.handleSavedJobsApp}>
                    <Typography style={webStyle.optionLabelText}>My Job applications</Typography>
                    <ArrowForwardIosIcon style={webStyle.expandArrow} />
                  </Box>
                  <Box style={{ ...webStyle.optionLabel, paddingBottom: "unset", borderBottom: "unset", marginBottom: "unset" }} data-test-id="handleSavedJob" onClick={this.handleSavedJob}>
                    <Typography style={webStyle.optionLabelText}>Saved Jobs</Typography>
                    <ArrowForwardIosIcon style={webStyle.expandArrow} />
                  </Box>
                </Box>
              </Grid>}
              {this.state.profileRoleId == 1 && (
                <Grid item xs={12} sm={12} md={8}>
                  <Box style={webStyle.profileInfo}>
                    {this.renderPersonalInfrormation()}
                    {this.renderSchoolBio()}
                    {this.renderSchoolLocation()}
                    {this.certificationsAdmin()}
                    {this.jobPreferences()}
                  </Box>
                </Grid>
              )}
            </Grid>
          </MainBox>
          <Footer navigation={this.props.navigation} id={""} NaviagationPage={"Profile"} />
        </>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomPopover = styled(Popover)({
  "& .MuiPaper-rounded": {
    borderRadius: '16px',
    padding: 10,
  },
  width: '100%',
  "& .react-calendar": {
    border: 'unset',
  },
  "& .react-calendar__tile--active:enabled": {
    background: '#395169',
    borderRadius: '10px'
  },
  "& .react-calendar__tile:enabled:hover": {
    borderRadius: '10px'
  },
  "& .react-calendar__year-view .react-calendar__tile": {
    padding: '1rem 0.5rem',
  },
  "& .react-calendar button": {
    border: '1px solid #E2E8F0',
    borderRadius: '10px',
  },
  "& .react-calendar__navigation button": {
    border: 'none'
  }

})
const theme2 = createTheme({
  props: {
    MuiCheckbox: {
      icon: <img src={unchecked} />,
      checkedIcon: <img src={checked} />,
    },
  },
});
const QuickLinks = styled(Grid)({
  "@media only screen and (max-width: 688px)": {
    display: 'none',
  }
})
const DeskCustomeGrid = styled(Grid)({
  display :"block",
  "@media only screen and (max-width: 687px)": {
    display: 'none',
  }
})
const MobileCustomGrid = styled(Grid)({
  display :"none !important" ,
  "@media only screen and (max-width: 687px)": {
    display: 'flex !important',
  }
})
const NavbarTitle = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  color: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
const MainBox = styled(Box)({
  background: "#F8F8F8",
  position: "relative",
  padding: "140px 222px 120px 222px",
  "@media only screen and (max-width: 1330px)": {
    padding: 100,
  },
  "@media only screen and (max-width: 688px)": {
    paddingLeft: 29,
    paddingRight: 29
  },
  "@media only screen and (max-width: 571px)": {
    paddingLeft: 29,
    paddingRight: 29,
  },
  "@media only screen and (max-width: 404px)": {
    paddingLeft: 10,
    paddingRight: 10
  },
  "@media only screen and (max-width: 375px)": {
    paddingLeft: 4,
    paddingRight: 4
  }
})
const CustomDialog = styled(Dialog)({
  borderRadius: '16px',
  padding: '40px',
  '& .MuiDialog-paperWidthSm': {
    minWidth: '300px',
    borderRadius: '16px',
    padding: '31px',
    maxWidth: '379px',
    position: 'relative',
    paddingBottom: '27px',
    "@media only screen and (max-width: 816px)": {
      minWidth: '200px'
    },
    "@media only screen and (max-width: 553px)": {
      minWidth: '200px'
    }
  }

})

const Dropdown = styled(Menu)({
  "& .MuiPopover-paper": {
    marginTop: "40px",
    border: '1px solid #E2E8F0',
    borderRadius: "8px",
    marginLeft: -124,
    width: 146,
  },
  "& .MuiPaper-elevation8": {
    boxShadow: "0px 4px 8px 0px #00000008"
  }
})

const DropdownDoc = styled(Menu)({
  "& .MuiPopover-paper": {
    border: '1px solid #E2E8F0',
    borderRadius: "8px",
    marginLeft: -154,
    width: 146,
  },
  "& .MuiPaper-elevation8": {
    boxShadow: "0px 4px 8px 0px #00000008"
  }
})
const SliderComponent = styled(Slider)({
  "& .MuiSlider-thumb": {
    width: "46px",
    height: "20px",
    backgroundColor: "#C6DC68",
    marginTop: -8,
    marginLeft: -10,
    borderRadius: "40px",
    "&:hover": {
      boxShadow: "unset"
    }
  },
  "& .MuiSlider-rail": {
    height: "4px",
    backgroundColor: "#E2E8F0"
  },
  "& .MuiSlider-track": {
    height: "4px",
    backgroundColor: "#C6DC68"
  },
  '& .MuiSlider-valueLabel': {
    color: '#395169',
    marginLeft: 9,
    left: 'calc(-50% + 12px)',
    top: '50%',
    transform: 'translateY(-50%)',
    '& *': {
      background: 'transparent',
      color: '#395169',
      top: "2.5px",
      position: "absolute",
      left: "9px",
      fontsize: "12px",
      fontWeight: 500,
    },
  },
  "& .PrivateValueLabel-label-18": {
    transform: 'rotate(46deg);'
  }
})
const TimeDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    width: 375,
    height: '256px'
  }
})
const DialogComponent = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    height: 354,
    width: 424,
    padding: 8
  }
})
const CustomNavbar = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const CustomProfileHead = styled(Box)({
  display: 'none',
  "@media only screen and (max-width: 571px)": {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#395169',
    padding: 20,
    position: 'relative',
  }
})
const Navigation = styled(Box)({
  display: "flex",
  gap: "10px",
  position: "absolute" as "absolute",
  top: 32,
  left: 114,
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const ProfileHeading = styled(Box)({
  color: "#1E293B",
  fontFamily: "Inter",
  fontSize: "30px",
  fontWeight: 700,
  lineHeight: "40px",
  marginBottom: "32px",
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  }
})
const CountType = styled(Typography)({
  color: "#475569",
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  "@media only screen and (max-width: 543px)": {
    fontSize: "13px",
  }
})

const ProfileInfo = styled(Box)({
  background: "#fff",
  padding: "32px",
  borderRadius: "16px",
  "@media only screen and (max-width: 414px)": {
    background: "#fff",
    marginTop: -99
  },
  "@media only screen and (max-width: 543px)": {
    padding:"0"
  }
})
const webStyle = {
  confirmheading: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '20.8px',
    fontWeight: 700,
    lineHeight: '41.6px',
    marginTop: '22px',
  },
  confirmDelete: {
    color: '#DC2626',
    fontFamily: 'Inter',
    fontSize: '18.6px',
    fontWeight: 700,
    lineHeight: '38.4px',
    textAlign: 'center' as 'center',
    textTransform: 'none' as 'none',
    marginTop: '10px',
    cursor: 'pointer'
  },
  popHeading: {
    color: '#1E293B',
    fontFamily: 'Inter' as 'Inter',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
  },
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4
  },
  profileInfo: {
    background: "#fff",
    padding: "32px",
    borderRadius: "16px"
  },
  heading: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    marginBottom: "32px"
  },
  profilepic: {
    borderRadius: "50%",
    width: 120,
    height: 120,
    cursor: 'pointer'
  },
  editText :
    { color: '#395169', fontFamily: 'Inter', fontSize: '16px', fontWeight: 400, lineHeight: '24px'  },
  
  profilepicMob:{
    borderRadius: "50%",
    width: 56,
    height: 56,
    cursor: 'pointer'
  },
  name: {
    color: '#1E293B',
    fontFamily: "Inter",
    fontSize: "24px",
    fontweight: 600,
    lineHeight: "32px",
  },
  email: {
    color: '#475569',
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  nameMob: {
    color: '#1E293B',
    fontFamily: "Inter",
    fontSize: "18px",
    fontweight: 600,
    lineHeight: "32px",
  },
  emailMob: {
    color: '#475569',
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "14px",
  },
  countingBox: {
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    padding: "12px",
    textAlign: "center" as "center",
    background: "#fff"
  },
  profileStatusBox: {
    background: "#EBF3C6",
    borderRadius: "12px",
    padding: "24px",
    borderBottomLeftRadius: "unset",
    borderBottomRightRadius: "unset"
  },
  profileCompletion: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  remaining: {
    background: "linear-gradient(90.62deg, #4788B7 0%, #669CBA 42.9%, #C6DC68 100%)",
    paddingTop: 5,
    paddingLeft: 10,
    paddingBottom: 5,
    paddingRight: 10,
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    display: "flex",
    justifyContent: "space-between"
  },
  addmore: {
    color: '#334155',
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  text: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  quicklink: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    paddingBottom: '16px',
    borderBottom: "1px solid #F1F5F9"
  },
  links: {
    color: '#395169',
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textDecoration: "underline",
    marginTop: "16px",
    cursor: 'pointer'
  },
  optionLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "16px",
    borderBottom: "1px solid #F1F5F9",
    marginBottom: 16,
    cursor: "pointer"
  },
  numbers: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  optionLabelText: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  sliderBox: {
    marginTop: 16,
    display: "flex",
    gap: 26,
    justifyContent: "space-between"
  },
  remainingItemBox: {
    display: "flex",
    gap: 6,
    marginTop: 8
  },
  arrowWhite: {
    color: "#fff",
    height: 12,
    marginTop: 2
  },
  expandArrow: {
    height: 16,
    marginTop: 5
  },
  editType: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  editIcon: {
    color: "#395169"
  },
  space: { display: "flex", justifyContent: "space-between", marginTop: 22 },
  editHeading: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  saveBtn: {
    color: '#FFFFFF',
    background: '#395169',
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: 16,
    textTransform: "none" as "none",
    padding: "10px 16px",
    borderRadius: 8,
    cursor: 'pointer'
  },
  editTextPreferesnce: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginLeft: 5,
    cursor: 'pointer'
  },
  introValueBox: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    height: 160,
    padding: 16,
    border: '0.5px solid #CBD5E1',
    borderRadius: 8,
    marginTop: 16
  },
  addMoreEdu: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: 'center' as 'center',
    justifyContent: "center",
    cursor: 'pointer',
  },
  monthstyle: {
    border: "1px solid #E2E8F0", textAlign: 'center' as 'center', padding: 10, borderRadius: 8, width: 100, textTransform: 'none' as 'none',
  },
  monthYearHeading: {
    color: '#0F172A',
    fontFamily: "Inter",
    fontSize: "26.6px",
    fontWeight: 700,
    lineHeight: "32.47px",
  },
  yearHeading: {
    display: 'flex', justifyContent: 'space-between', paddingTop: 30, paddingBottom: 30, paddingInline: 23
  },
  errorMsg: {
    color: 'red',
    fontSize: 12,
    fontFamily: 'Inter'
  },
  showBoxData: {
    border: '1px solid #E2E8F0',
    borderRadius: '12px',
    padding: '24px',
    marginTop: '30px'
  },
  showBoxTtile: {
    color: '1E293B',
    fontFamily: 'Inter',
    fontSize: '21px',
    fontWeight: 600
  },
  organisationTxt: {
    color: "#334155",
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '27px',
  },
  repotitle: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '27px',
    marginTop: '15px'
  },
  jobRespon: {
    color: '#334155',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '27px',
    marginTop: '12px'
  },
  expTxt: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  addskill: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: '15px'
  },
  addbutton: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    cursor: 'pointer'
  },
  skillname: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  skillNameDelete: {
    padding: '3px 16px',
    borderBottom: '1px solid #F1F5F9'
  },
  skillBox: {
    border: '1px solid #E2E8F0',
    borderRadius: '16px',
    marginTop: '9px'
  },
  jobprefeBox: {
    borderRadius: '8px',
    border: '1px solid #E2E8F0',
    marginTop: '16px'
  },
  preferTitle: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    borderBottom: '#F1F5F9',
    padding: '15px 16px'
  },
  prefOption: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  travel: {
    color: '#475569',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '20px',
    marginBottom: '10px'
  },
  avtarColor: {
    width: '32px', height: '32px', fontWeight: 700, fontFamily: 'Inter', fontSize: '14px'
  },
  preferTime: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '16px',
    marginLeft: '16px'
  },
  schoolname: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
  },
  course: {
    color: '#334155',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: "20px"
  },
  duration: {
    color: '#475569',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: '20px'
  },
  sharewith: {
    border: '1px solid #E2E8F0',
    borderRadius: '8px'
  },
  sharewithHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 16px',
    borderBottom: '1px solid #F1F5F9'
  },
  sharewithTxt: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  sharewithOptions: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    padding: '12px 16px'
  },
  uploadBox: {
    padding: '32px 24px',
    borderRadius: '4px',
    border: '1px solid #CBD5E1',
    borderStyle: 'dashed',
    textAlign: 'center' as 'center',
    marginTop: '12px',
    cursor: 'pointer'
  },
  filename: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  fileSize: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  jpeg: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '5px'
  },
  uploadFileTxt: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textheading: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    marginBottom: 6,
    marginTop: '23px'
  },
}
const styles = {
  container: {
    width: '51px',
    height: '31px',
    position: 'relative',
  },
  checkbox: {
    opacity: 0,
    width: 0,
    height: 0,
    position: 'absolute',
    cursor: 'pointer'
  },
  checkRecom: {
    opacity: 0,
    width: 0,
    height: 0,
    position: 'absolute',
  },
  switch: {
    width: '100%',
    height: '100%',
    display: 'block',
    backgroundColor: '#E2E8F0',
    borderRadius: '16px',
    cursor: 'pointer',
    transition: 'all 0.2s ease-out',
  },
  desktopViewContainer: {
    "@media (min-width:768px)": {
      display:"none"
    }
  },
  mobileViewContainer: {
    "@media (max-width:768px)": {
      display:"none"
    }
  },
  slider: {
    width: '27px',
    height: '27px',
    position: 'absolute',
    left: 'calc(50% - 27px/2 - 10px)',
    top: 'calc(50% - 27px/2)',
    borderRadius: '50%',
    background: '#FFFFFF',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)',
    transition: 'all 0.2s ease-out',
    cursor: 'pointer',
  },
  switchChecked: {
    backgroundColor: '#395169',
  },
  sliderChecked: {
    left: 'calc(50% - 27px/2 + 10px)',
  },
};
// Customizable Area End
