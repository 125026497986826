import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start,
  styled,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { background, teacherUnselect, teacherSelect, schooladminSelected, schooladminUnselect, background_img_for_mobile, arrow_back_ios_24px } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Rolesandpermissions2Controller, {
  Props,
  configJSON,
} from "./Rolesandpermissions2Controller";

export default class Rolesandpermissions2 extends Rolesandpermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainContainerBox>
          <Box>
            <BackButton data-test-id="handleback" onClick={this.handleBack}>
              <div style={{width:"24px",height:"24px"}}>
              <img src={arrow_back_ios_24px} />
              </div>
              <BackTypography>{configJSON.back}</BackTypography>
            </BackButton >
            <MainHeading>
              {configJSON.heading}
            </MainHeading>
            <SubHeading>
              {configJSON.subHeading}
            </SubHeading>
            <RoleBox >
            <Grid container spacing={2} style={webStyle.rolesDiv}>
              {this.state.selectedRole == 1 ?
                <>
                  <Grid item xs={6} sm={6}>
                    <img src={schooladminSelected} style={{ cursor: "pointer", width: "100%" }}  data-test-id="handleRoleSwitchOne"  onClick={this.handleRoleSwitchOne} />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <img src={teacherUnselect} onClick={this.handleRoleSwitchTwo} data-test-id="handleRoleSwitchTwo" style={{ cursor: "pointer", width: "100%" }} />
                  </Grid>
                </>
                :
                <>
                  <Grid item xs={6} sm={6}>
                    <img src={schooladminUnselect} onClick={this.handleRoleSwitchOne} style={{ cursor: "pointer", width: "100%" }} />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <img src={teacherSelect} style={{ cursor: "pointer", width: "100%" }} onClick={this.handleRoleSwitchTwo} />
                  </Grid>
                </>

              }
            </Grid>
            </RoleBox>
            
            <ButtonContinue  data-test-id="handleContinue" onClick={()=>this.handleContinue()}>{configJSON.continueBtn}</ButtonContinue>
            <AlreadyAccount>
              <Typography>{configJSON.alreadyAccount}</Typography>
              <Typography style={webStyle.login} data-test-id="handlelogin" onClick={this.handleLogin}>{configJSON.login}</Typography>
            </AlreadyAccount>
          </Box>
        </MainContainerBox>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainerBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  position: "relative",
  alignItems: 'center',
  background: `url(${background})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: "100vh",
  "@media only screen and (max-width: 474px)": {
    backgroundSize: 'cover',
    padding: "24px",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    background: `url(${background_img_for_mobile})`,
    height:"unset"
  },
});
const MainHeading = styled(Typography)({
  color: "#1E293B",
  fontSize: "30px",
  fontFamily:"Inter",
  fontWeight: 700,
  lineHeight: "40px",
  textAlign: "left" as "left",
  "@media only screen and (max-width: 474px)": {
    fontSize: "24px",
    lineHeight: "32px",
    marginTop:"129px"
  },
})

const RoleBox = styled(Box)({
  marginTop:"48px",
  "@media only screen and (max-width: 474px)": {
   marginTop:"96px"
  },
})

const ButtonContinue = styled(Button)({
  background: "#395169",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "none" as "none",
    height: "56px",
    fontFamily:"Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "100%",
    marginTop: "48px",
    "@media only screen and (max-width: 474px)": {
      marginTop:"160px"
     },
})

const SubHeading = styled(Typography)({
  color: "#334155",
  fontSize: "16px",
  fontWeight: 400,
  fontFamily:"Inter",
  lineHeight: "24px",
  textAlign: "left" as "left",
  "@media only screen and (max-width: 474px)": {
    fontSize: "14px",
    lineHeight: "22px",
    marginTop:"8px"
  },
})
const AlreadyAccount = styled(Box)({
  display: "flex",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "22px",
  fontFamily:"Inter",
  textAlign: "left" as "left",
  marginTop: "32px",
  color: '#334155',
  "@media only screen and (max-width: 474px)": {
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    marginBottom:"52px"
  },
})
const BackButton = styled(Box)({
  display: "flex",
  gap: "6px",
  cursor:"pointer",
  position: "absolute" as "absolute",
  top: 60,
  left: 120,
  "@media only screen and (max-width: 622px)": {
    left: 24
  },
})
const BackTypography = styled(Typography)({
  color: "#1E293B",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  fontFamily:"Inter",
  display: "contents",
  "@media only screen and (max-width: 622px)": {
    display:"none"
  },
})
const webStyle = {
  rolesDiv: {
    marginTop: "48px"
  },
  continue: {
    background: "#395169",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "none" as "none",
    height: "56px",
    fontFamily:"Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "100%",
    marginTop: "48px"
  },
  login: {
    fontWeight: 700,
    color: "#395169",
    marginLeft: "8px",
    fontFamily:"Inter",
    cursor: "pointer"
  },
};
// Customizable Area End
