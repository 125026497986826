import React from "react";

// Customizable Area Start
import ForgotPasswordController, { Props } from "./ForgotPasswordController";

import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { Box, Dialog, DialogContent, IconButton, Typography } from "@material-ui/core";
import { backgroundMobile, backgroundWeb, backwardIcon } from "../../customform/src/assets";
import { ErrorMessage, InputField, InputsLabel, PasswordInputWrapper } from "../../email-account-login/src/EmailAccountLoginBlock.web";
import { closeIcon, emailIcon } from "./assets";
export const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isModalOpen, emailErrorMsg } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainContainer>
            <BackIconBox data-test-id="btnBackward"  onClick={() => this.goToLogin()}>
                <img style={webStyle.backIcon} src={backwardIcon} alt={configJSON.backIconAlt} />
                <BackTxtTypography style={webStyle.backIconTxt}>{configJSON.backTxt}</BackTxtTypography>
            </BackIconBox>
            <BoxSection>
                <MainHeading>
                    {configJSON.forgotTxt} {configJSON.passTxt}{configJSON.wordTxt}{configJSON.questionMark}
                </MainHeading>
                <SubHeading>
                    {configJSON.subHeadingText}
                </SubHeading>
                <PasswordInputWrapper>
                    <InputsLabel>{configJSON.emailTxt}</InputsLabel>
                    <InputField 
                        placeholder={configJSON.enterEmailTxt}
                        data-test-id="txtInputEmail"
                        variant='outlined' 
                        fullWidth 
                        value={this.state.email}
                        onChange={(event) => { this.setEmail(event.target.value) } } 
                        error={!!emailErrorMsg}
                        required
                      />
                      {emailErrorMsg && <ErrorMessage>
                              {emailErrorMsg}
                      </ErrorMessage>}
                </PasswordInputWrapper>
                <SendButtonWrapper>
                    <button data-test-id='btnSendResetLink' onClick={this.handleSendResetLink} style={webStyle.sendLinkBtn} >{configJSON.sendTxt} {configJSON.passTxt}{configJSON.wordTxt} {configJSON.resetLinkTxt}</button>
                </SendButtonWrapper>
                <BackToLoginBox>
                    <BackTypography>{configJSON.backTxt} {configJSON.toTxt}</BackTypography>
                    <LoginTypography data-test-id='btnNavigateLogin' onClick={() => this.goToLogin()}>{configJSON.loginTxt}</LoginTypography>
                </BackToLoginBox>
            </BoxSection>
        </MainContainer>

        <Box>
            <BootstrapDialog
                onClose={this.handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={isModalOpen}
            >
                <IconBtn
                    aria-label="close"
                    data-test-id='btnCloseModal'
                    onClick={this.handleCloseModal}
                >
                   <img src={closeIcon} alt="close_icon" />
                </IconBtn>
                
                <DialogContent dividers>
                    <img style={webStyle.emailIcon} src={emailIcon} alt="email_icon" />
                    <ModalHeading>{configJSON.checkYourEmailTxt}</ModalHeading>
                    <ModalSubHeading>
                        {configJSON.capPassTxt}{configJSON.wordTxt} {configJSON.resetLinkSentTo} <ModalMailText>{this.state.email}</ModalMailText>{configJSON.dotTxt} {configJSON.instructionForReset}
                    </ModalSubHeading>
                    <ResendEmailBtn data-test-id='btnResendPassword' onClick={() => this.handeSendResetLinkAgain()}>{configJSON.resendTxt} {configJSON.firstInputAutoCompleteType}</ResendEmailBtn>
                    <BackToLoginBox style={{marginTop: '32px'}} >
                        <BackTypography>{configJSON.backTxt} {configJSON.toTxt}</BackTypography>
                        <LoginTypography data-test-id='btnNavigateLogin' onClick={() => this.goToLogin()}>{configJSON.loginTxt}</LoginTypography>
                    </BackToLoginBox>
                </DialogContent>
            </BootstrapDialog>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const webStyle = {
    backIcon: {
        width: '24px',
        height: '24px',
        marginRight: '6px',
    },
    backIconTxt: {
        color: '#1E293B',
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: 'Inter',
    },
    sendLinkBtn: {
        width: '100%',
        height: '100%',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF',
        border: 'none',
        backgroundColor: '#395169',
        borderRadius: '8px',
        cursor: 'pointer',
    },
    emailIcon: {
        display: 'block',
        margin: 'auto',
    },
    resendEmailBtn: {
        
    },
};

export const MainContainer = styled(Box)({
    background: `url(${backgroundWeb})`,
    minHeight: '100vh',
    paddingBottom: '136px',
    paddingTop: '70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    "@media only screen and (max-width: 414px)": {
      background: `url(${backgroundMobile})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
});

export const BackIconBox = styled(Box)({
    cursor: 'pointer',
    position: 'absolute' as 'absolute',
    left: 120,
    top: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "@media only screen and (max-width: 768px)": {
        left: 60,
        top: 48,
    },
    "@media only screen and (max-width: 628px)": {
        left: 30,
        top: 28,
    },
    "@media only screen and (max-width: 414px)": {
        left: 24,
        top: 18,
    },
});

export const BackTxtTypography = styled(Typography)({
    "@media only screen and (max-width: 414px)": {
        display: 'none',
    },
});

const BoxSection = styled(Box)({
    width: '384px',
    "@media only screen and (max-width: 414px)": {
        width: '327px',
    },
});

export const MainHeading = styled(Typography)({
    width: '384px',
    lineHeight: '40px',
    marginTop: '48px',
    fontSize: '30px',
    fontFamily: 'Inter',
    'font-weight': 700,
    textAlign: 'left',
    color: '#1E293B',
    "@media only screen and (max-width: 414px)": {
        width: '327px',
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '-0.005em',
    },
});

export const SubHeading = styled(Typography)({
    width: '384px',
    fontFamily: 'Inter',
    marginBottom: '32px',
    marginTop: '8px',
    'font-weight': '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#334155',
    "@media only screen and (max-width: 414px)": {
        width: '327px',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#475569',
        marginBottom: '0px',
    },
});

const SendButtonWrapper = styled(Box)({
    width: '384px',
    height: '56px',
    marginTop: '48px',
    "@media only screen and (max-width: 414px)": {
        width: '327px',
        marginTop: '32px',
    },
});

export const BackToLoginBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '32px',
    "@media only screen and (max-width: 414px)": {
        marginTop: '150px',
    },
});

export const BackTypography = styled(Box)({
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#334155',
    marginRight: '8px',
    "@media only screen and (max-width: 414px)": {
        fontSize: '14px',
    }
});

export const LoginTypography = styled(Box)({
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    color: '#395169',
    cursor: 'pointer',
    "@media only screen and (max-width: 414px)": {
        fontSize: '14px',
    }
});

const BootstrapDialog = styled(Dialog) ({
    "& .MuiDialog-paper": {
        borderRadius: "16px",
        boxSizing: 'border-box',
    },
    '& .MuiDialogContent-root': {
        padding: '40px 40px 40px 40px',
        "@media only screen and (max-width: 414px)": {
            padding: '30px 15px 30px 15px',
        },
    },
});

const IconBtn = styled(IconButton)({
    position: 'absolute',
    right: 8,
    top: 8,
});

const ModalHeading = styled(Typography)({
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    textAlign: 'center' as 'center',
    color: '#1E293B',
    margin: '24px 0 16px 0',
    "@media only screen and (max-width: 628px)": {
        margin: '16px 0 8px 0',
        fontSize: '20px',
    },
});

const ModalSubHeading = styled(Box)({
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28.8px',
    textAlign: 'center' as 'center',
    color: '#475569',
    marginBottom: '34px',
    "@media only screen and (max-width: 628px)": {
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '24px',
    },
});

const ModalMailText = styled(Typography)({
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '28.8px',
    textAlign: 'center' as 'center',
    color: '#334155',
    display: 'inline-block',
    "@media only screen and (max-width: 628px)": {
        fontSize: '14px',
        lineHeight: '22px',
    },
});

const ResendEmailBtn = styled(Typography)({
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    color: '#395169',
    textAlign: 'center' as 'center',
    cursor: 'pointer',
    "@media only screen and (max-width: 414px)": {
        fontSize: '14px',
        lineHeight: '22px',
    },
})

// Customizable Area End