// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");
import storage from "../../../framework/src/StorageProvider";

import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
import moment from "moment";
export interface Props {
  navigation: any;
  id: string;
}

export interface JobListDataType {
  attributes: {
    addresses: [];
    address: string;
    created_at: string;
    company_name: string;
    company_headquarter: string;
    company_photo: string;
    company_photo_url: string;
    company_page_id: number;
    country: string;
    employment_type: null;
    employment_type_id: number;
    followers_count: number;
    industry_id: number;
    job_description: string;
    job_title: string;
    job_video: string;
    job_video_url: string;
    location: string;
    job_function: string;
    other_skills: Array<string>;
    profile_id: number;
    industry_name: string;
    question_answer_id: Array<string>;
    question_answers: Array<object>;
    salary: string;
    preffered_location: Array<string>;
    seniority_level: string;
    skill_name: Array<string>;
    sub_emplotyment_type: null;
    total_inteview_rounds: number;
    image_url: string;
    remote_job: boolean;
    skill_id: Array<number>;
  };
  id: string;
  type: string;
}

export interface JobListIOSDataType {
  item: {
    attributes: {
      address: string;
      addresses: [];
      company_headquarter: string;
      company_name: string;
      company_page_id: number;
      company_photo: string;
      company_photo_url: string;
      country: string;
      created_at: string;
      employment_type: null;
      employment_type_id: number;
      industry_id: number;
      industry_name: string;
      job_description: string;
      job_function: string;
      job_title: string;
      job_video: string;
      job_video_url: string;
      location: string;
      other_skills: Array<string>;
      preffered_location: Array<string>;
      profile_id: number;
      question_answer_id: Array<string>;
      question_answers: Array<object>;
      remote_job: boolean;
      salary: string;
      seniority_level: string;
      skill_id: Array<number>;
      skill_name: Array<string>;
      sub_emplotyment_type: null;
      total_inteview_rounds: number;
      image_url: string;
      followers_count: number;
    };
    id: string;
    type: string;
  };
}


export interface AttributesTeacher {
  activated: boolean;
  email: string | null;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  password: string | null;
  member_id: string | null;
  organization: string | null;
  street_address: string;
  city: string;
  state: string;
  zip_code: number;
  country: string;
  no_of_employees: number | null;
  program_type: string | null;
  preferred_travel_distance: number;
  program_capacity: number | null;
  about_us: string | null;
  allow_sms_notification: boolean;
  sms_recommendation: boolean;
  role: Role;
  profile_image: string;
  upload_resumes: string;
  letters_of_interests: string;
  credentials: string;
  transcripts: string;
  note_pages: string;
  letter_of_reference_emails: string;
  country_code: string | null;
  phone_number: string | null;
  preferred_modality_ids: number[];
  preferred_modality_name: string[];
  preferred_age_group_ids: number[];
  preferred_age_group_name: string[];
  preferred_working_days_ids: number[];
  preferred_working_days_name: string[];
  preferred_nature_of_jobs_ids: number[];
  preferred_nature_of_jobs_name: string[];
  preferred_time_shifts_ids: number[];
  preferred_time_shifts_name: string[];
  document_sharings_ids: number[];
  document_sharings_name: string[];
}

interface Role {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

export interface Account {
  id: string;
  type: string;
  attributes: AttributesTeacher;
}
export interface Pagination {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number ;
}

export interface CardsDetails {
  id: string;
  type: string;
  attributes: JobAttributes;
}

interface JobAttributes {
  id: number;
  job_title: string;
  job_description: string;
  organization: string;
  education_level: string;
  experience_required: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  about_organization: string;
  location: string;
  role_name: string;
  posted_by: string;
  job_image: string;
  account_image: AccountImage;
  created_at: string;
  key_responsibilities: string;
  qualifications: string;
  benefits: string;
  posted_at: string;
  admin_email: string;
  post_created_at: string;
  job_applied_at: string | null;
}

interface AccountImage {
  id: number;
  url: string;
  type: string;
  filename: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  first: boolean;
  searchText: string;
  jobArray: JobListDataType[];
  recent: Array<string>;
  lastSearch: string;
  loading: boolean;
  followIndex: number;
  token: string;
  bachelorCheck: boolean;
  associateCheck: boolean;
  highSchoolCheck: boolean;
  certificateCheck: boolean;
  selectedState: string;
  value: number;
  isToken: string;
  showFilterBox: boolean;
  selectedDateFrom: string;
  selectedDateTo: string;
  currentPage: number;
  cardsPerPage: number;
  enteredText: string;
  selectedItems: string[];
  options: string[];

  searchedResult: CardsDetails[];
  searchedResultTeacher:any[]
  cardsDetails: CardsDetails[];
  isViewAll: boolean;
  noLoginRecentJobs: CardsDetails[];
  noLoginInterestedJobs: CardsDetails[];
  logInMatchingProfileJobs: CardsDetails[];
  loginRecentJobs: CardsDetails[];
  loginInterestedJobs: CardsDetails[];

  searchedItemText: string;
  isSearchEmpty: boolean;
  isFilterOptionEmpty: boolean;
  datePickerIsOpen: boolean;
  ageError: boolean;
  datePickerToIsOpen: boolean;
  isSearchedClicked: boolean;
  anchorEl: null | HTMLElement;
  selectedSortItem: string;
  isSortDropOption: boolean;
  nameOfPageWhichShowingFull: string | null;
  searchedResultCopy: CardsDetails[],
  searchedResultTeacherCopy :any[]
  recentJobsCopy : CardsDetails[],
  matchingProfileJobsCopy : CardsDetails[],
  interestedJobsCopy : CardsDetails[],
  current_page:number,
  next_page:number,
  prev_page:number| null,
  total_count: number,
  total_pages : number,
  recentJobsPagination : Pagination | null,
  interestedJobsPagination : Pagination | null,
  matchingJobsPagination : Pagination,
  pageSize : number,
  inviteFriend:boolean;
  searchedResultFromApi : CardsDetails[],
  showAll: boolean,
  role : number,
  substituteTeacherDetails : any[],
  substitueTeacherCopy : any[],
  isDrawerOpen:boolean,
  fromViewTeacherDetail:string,
  isPaginate:boolean;
  substitueTeacherPagination :Pagination,
  totalData : number,
  jobs:any,
  isFullView:boolean,
  isSearch:boolean,
  filteredItem:string,
  isCustom:boolean;
  loader:boolean;
}

interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class JobListingController extends BlockComponent<Props, S, SS> {
  searchJobApiCallId: string = "";
  teacherSearchId:string="";
  searchCompanyApiCallId: string = "";
  searchPeopleApiCallId: string = "";
  followCompanyApiCallId: string = "";
  //willFocusSubscription: object;
  addConnectionApiCallId: string = "";
  lastVisitedJob: string = "";
  teacherListId:string="";
  debounceTimer:any=0;
  lastVisitedCompany: string = "";
  lastVisitedPeople: string = "";

  noLoginRecentJobsId: string = "";
  noLoginInterestedJobsId: string = "";
  logInMatchingProfileJobsId: string = "";
  loginRecentJobsId: string = "";
  loginInterestedJobsId: string = "";
  debounceHandler:any

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.debounceHandler = this.debounce(this.handleSearchButtonOnClick , 0)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      loading: false,
      txtSavedValue: "A",
      enableField: false,
      first: true,
      searchText: "",
      jobArray: [],
      recent: [],
      lastSearch: "",
      followIndex: -1,
      token: "",
      bachelorCheck: false,
      associateCheck: false,
      highSchoolCheck: false,
      certificateCheck: false,
      selectedState: "",
      value: 0,
      isToken: '',
      showFilterBox: false,
      selectedDateFrom: "",
      selectedDateTo: "",
      currentPage: 1,
      cardsPerPage: 2,
      enteredText: "",
      selectedItems: [],
      options: [
        "Assistant Teacher",
        "Assistant Professor",
        "Assistant Lecturer ",
        "Lab Assistant",
      ],

      isViewAll: false,
      noLoginRecentJobs: [],
      noLoginInterestedJobs: [],
      logInMatchingProfileJobs: [],
      loginRecentJobs: [],
      loginInterestedJobs: [],
      cardsDetails: [],
      searchedResult: [],
      searchedResultTeacher :[],
      searchedResultTeacherCopy:[],
      searchedItemText: "",
      isSearchEmpty: false,
      isFilterOptionEmpty: false,
      datePickerIsOpen: false,
      ageError: false,
      datePickerToIsOpen: false,
      isSearchedClicked: false,
      anchorEl: null,
      selectedSortItem: "Relevance",
      isSortDropOption: false,
      nameOfPageWhichShowingFull: "",
      searchedResultCopy: [],
      interestedJobsCopy:[],
      matchingProfileJobsCopy:[],
      recentJobsCopy:[],
      current_page:1,
      next_page:2,
      prev_page:null,
      total_count:1,
      recentJobsPagination : null,
      interestedJobsPagination : null,
      matchingJobsPagination : {
        current_page: 0,
        next_page: null,
        prev_page: null,
        total_pages: 0,
        total_count: 0
      },
      total_pages : 1,
      pageSize : 10,
      inviteFriend:false,
      searchedResultFromApi:[],
      showAll:false,
      role:0,
      substituteTeacherDetails:[],
      substitueTeacherCopy:[],
      isDrawerOpen:false,
      fromViewTeacherDetail:'',
      isPaginate:false,
      substitueTeacherPagination:{
        current_page:0,
        next_page:0,
        prev_page:0,
        total_count:0,
        total_pages:0
      },
      totalData:0,
      jobs:[],
      isFullView : false,
      isSearch:false,
      filteredItem :'',
      isCustom :false,
      loader:false,
};
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  compiledJobs = () => {    
    return this.state.searchedResultFromApi
  };

  handleToggle = ()=>{
    this.setState({
      showAll : !this.state.showAll ?true :false
    })
  }

  handleViewALL = () => {
    this.setState({ isViewAll: true });
  };

  getJobSearchSuccessCallBack = (
    responseJson: CardsDetails[],
  ) => {
        this.setState({ searchedResultFromApi: responseJson || [], loading: false });

    }
  getJobSearchFailedCallBack = (message: string, apiRequestCallId: string) => {
    if (message.includes("Record not found")) {
      if (apiRequestCallId === this.searchJobApiCallId) {
        this.lastVisitedJob = this.state.searchText?.trim();
        this.setState({ jobArray: [], loading: false });
      }
    } else if (
      apiRequestCallId === this.addConnectionApiCallId &&
      message.includes("Connection created successfully")
    ) {
      alert("Request sent successfully");
    }
  };

  getJobSearchErrorCallBack = (
    errorReponse: object,
    apiRequestCallId: string
  ) => {
    if (
      apiRequestCallId === this.searchJobApiCallId ||
      apiRequestCallId === this.searchCompanyApiCallId ||
      apiRequestCallId === this.searchPeopleApiCallId
    ) {
      this.parseApiErrorResponse(errorReponse);
      this.parseApiCatchErrorResponse(errorReponse);
    }
  };

  shortString = (value: string) => {
    if (value?.length <= 100) {
      return value;
    }
    return value?.slice(0, 100) + "...";
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token }, () => {});
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson?.meta?.message) {
        this.getJobSearchFailedCallBack(
          responseJson.meta.message,
          apiRequestCallId
        );
      }

      if (errorReponse) {
        this.getJobSearchErrorCallBack(errorReponse, apiRequestCallId);
      }
      
      this.receiveJobListing(apiRequestCallId, responseJson);    
    }
  }

  async receiveJobListing(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.noLoginRecentJobsId:
        if (responseJson.jobs.data.length === 0) {
          this.setState({
            noLoginRecentJobs: [],
            matchingProfileJobsCopy: [],
            isPaginate: false
          });
        } else {
          this.setState({
            noLoginRecentJobs: responseJson?.jobs?.data,
            total_pages: responseJson?.meta?.pagination?.total_pages || this.state?.total_pages,
            recentJobsPagination: responseJson.meta.pagination,
            total_count: responseJson.meta.pagination.total_count || this.state.total_count,
          });
        }
        this.setState({loader:false})
        break;
  
      case this.noLoginInterestedJobsId:
        if (responseJson.jobs.data.length === 0) {
          this.setState({
            noLoginInterestedJobs: [],
            matchingProfileJobsCopy: [],
            isPaginate: false
          });
        } else {
          this.setState({
            total_pages: responseJson.meta.pagination.total_pages || this.state.total_pages,
            noLoginInterestedJobs: responseJson.jobs.data,
            total_count: responseJson.meta.pagination.total_count || this.state.total_count
          });
        }
        this.setState({loader:false})
        break;
  
      case this.logInMatchingProfileJobsId:
        if (responseJson.jobs.data.length === 0) {
          this.setState({
            logInMatchingProfileJobs: [],
            matchingProfileJobsCopy: [],
            isPaginate: false
          });
        } else {
          this.setState({
            matchingJobsPagination: responseJson.meta.pagination,
            matchingProfileJobsCopy: responseJson.jobs.data,
            total_pages: responseJson.meta.pagination.total_pages || this.state.total_pages,
            current_page: responseJson?.meta?.pagination.current_page,
            total_count: responseJson.meta.pagination.total_count || this.state.total_count,
            logInMatchingProfileJobs: responseJson.jobs.data,
          });
        }
        this.setState({loader:false})
        break;
  
      case this.loginRecentJobsId:
        if (responseJson.jobs.data.length === 0) {
          this.setState({
            recentJobsCopy: [],
            isPaginate: false,
            loginRecentJobs: [],
          });
        } else {
          this.setState({
            total_pages: responseJson.meta.pagination.total_pages || this.state.total_pages,
            recentJobsCopy: responseJson.jobs.data,
            current_page: responseJson?.meta?.pagination.current_page,
            total_count: responseJson.meta.pagination.total_count || this.state.total_count,
            loginRecentJobs: responseJson.jobs.data,
            recentJobsPagination: responseJson.meta.pagination,
          });
        }
        this.setState({loader:false})
        break;
  
      case this.loginInterestedJobsId:
        if (responseJson.jobs.data.length === 0) {
          this.setState({
            isPaginate: false,
            loginInterestedJobs: [],
            interestedJobsCopy: [],
          });
        } else {
          const isFromAppliedJob = await storage.get("afterAppliedJob");
          this.setState({
            loginInterestedJobs: responseJson.jobs.data,
            total_count: responseJson.meta.pagination.total_count || this.state.total_count,
            current_page: responseJson?.meta?.pagination.current_page,
            interestedJobsCopy: responseJson.jobs.data,
            interestedJobsPagination: responseJson.meta.pagination,
            total_pages: responseJson.meta.pagination.total_pages || this.state.total_pages,
          });
          if (isFromAppliedJob === 'true') {
            this.handleViewInterestedJobs('youmayintrestedin');
            await storage.remove('afterAppliedJob');
          }
        }
        this.setState({loader:false})
        break;
  
      case this.teacherListId:
        if (responseJson.error) {
          this.setState({
            substituteTeacherDetails: [],
            isPaginate: false,
            substitueTeacherCopy: [],
          });
        } else {
          this.setState({loader:true,
            substitueTeacherPagination: responseJson?.meta?.pagination,
            substituteTeacherDetails: responseJson?.substitute_teachers?.data,
            total_count: responseJson?.meta?.pagination.total_count,
            substitueTeacherCopy: responseJson?.substitute_teachers?.data,
            totalData: responseJson?.meta?.pagination.total_count,
            total_pages: responseJson?.meta?.pagination.total_pages,
            current_page: responseJson?.meta?.pagination.current_page,
          });
        }
        this.setState({loader:false})
        break;
  
      case this.searchJobApiCallId:
        if (responseJson?.message) {
          this.setState({
            isPaginate: false,
            searchedResultFromApi: [],
            searchedResult: [],
            searchedResultCopy: [],
            isSearchedClicked: true,
            isCustom: false
          });
          this.handleSearch([]);
        } else {
          this.handleSearch(responseJson.data);
          this.setState({ isSearchedClicked: true });
        }
        this.setState({loader:false})
        break;
  
      default:
        break;
    }
  }
  


  handleViewForMatchingProfileJobs = (page: string,matchingJobsPagination:Pagination|null ) => {
    const isMatchingProfile = page === "matchingProfile";
    this.setState({
      fromViewTeacherDetail: '',
      nameOfPageWhichShowingFull: isMatchingProfile ? "Matching your profile" : this.state.nameOfPageWhichShowingFull,
      total_pages: isMatchingProfile ? (matchingJobsPagination?.total_pages|| 1) : this.state.total_pages,
      total_count: isMatchingProfile ? (matchingJobsPagination?.total_count || 1) : this.state.total_count,
      isPaginate:true,
      showFilterBox:true,
      isFullView:true,
      current_page:1
    });
    if (isMatchingProfile) {
      window.scrollTo(0, 0);
      this.setState({
        isPaginate:true
      })
    }
  };

  handleViewForTopCandidates = (page: string) => {
    this.setState({
      fromViewTeacherDetail:'Teacher Listings'
    })
    if (page === "topcandidates") {
      this.setState({
        nameOfPageWhichShowingFull: "Top Candidates",
        isPaginate:true,
        showFilterBox:true,
        isFullView:true,
        filteredItem:"Top Candidates",
        current_page:1
      })
      window.scrollTo(0, 0)
      
    }
  }


  handleViewForRecentlyAddedJobs = (page: string) => {
    this.setState({
      fromViewTeacherDetail:''
    })
    if (page === "recentlyadded") {
      this.setState({
        nameOfPageWhichShowingFull: "Recently added",
        total_pages  : this.state.recentJobsPagination?.total_pages || 1,
        total_count : this.state.recentJobsPagination?.total_count || 1,
        isPaginate:true,
        isFullView:true,
        current_page:1  
      })
    this.getLoginRecentAddedJobs(0)
      window.scrollTo(0, 0)
    }

  }

  handleViewInterestedJobs = (page: string) => {
    this.setState({
      fromViewTeacherDetail:''
    })
    if (page === "youmayintrestedin") {
      this.setState({
        nameOfPageWhichShowingFull: "You may be interested in",
        total_pages  : this.state.interestedJobsPagination?.total_pages || 1,
        total_count : this.state.interestedJobsPagination?.total_count || 1,
        isPaginate:true,
        isFullView:true,
        current_page:1
      })
    this.getLoginInterestedJobs(0)
      window.scrollTo(0, 0)
    }
   
  }


  navigateToBack = () => {
    this.setState({
      nameOfPageWhichShowingFull: "",
      searchedItemText: "",
      enteredText:'',
      isFullView:false,
      searchedResult: [],
      selectedSortItem : "Relavance",
      total_count : 0 , 
      total_pages : 0,
      isSearch:false,
      fromViewTeacherDetail:'',
      isPaginate:false,
      matchingJobsPagination:{
        current_page: 1,
        next_page: 0,
        prev_page: null,
        total_pages: 0,
        total_count: 0
      },
      recentJobsPagination:{
        current_page: 1,
        next_page: 0,
        prev_page: null,
        total_pages: 0,
        total_count: 0
      },
      interestedJobsPagination:{
        current_page: 1,
        next_page: 0,
        prev_page: null,
        total_pages: 0,
        total_count: 0
      },
      highSchoolCheck:false,
      bachelorCheck:false,
      associateCheck:false,
      certificateCheck:false,
      selectedState:"",
      selectedDateFrom:'',
      selectedDateTo:"",
      searchText:"",
      isSearchedClicked:false,
      value:0,
      filteredItem:'',
      
    })
    if (this.state.isToken) {
        this.setState({
          showFilterBox:true
        })
    }
  }

  getToken = () => {
    const getAuthToken: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(getAuthToken);
  };

  async componentDidMount() {
    const token = await storage.get("loginToken");
    const role = await storage.get("role");
    this.setState({ isToken: token  , role : role});
    this.getToken();
    this.setState({loader:true})
    this.getSubstituteTeacherList('1')
    this.getNotLoginRecentlyAddedJobs(this.state.current_page);
    this.getNotLoginInterestedJobs(this.state.currentPage);
    this.getLoginMatchingProfileJobs(this.state.currentPage ,10);
    this.getLoginRecentAddedJobs(this.state.current_page);
    this.getLoginInterestedJobs(this.state.current_page);
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); 
    storage.remove('navigateTo')
    if (token) {
      this.setState({
        showFilterBox:true
      })
    }
  }

  async getRecents() {
    const recentData = await getStorageData("recent");
    this.setState({ recent: recentData });
  }
  handler = (inputValue: string) => {
    if (inputValue.trim()) {
      this.setState({
        lastSearch: inputValue.trim(),
        searchText: inputValue,
        loading: true,
      });
      
    }
  };
  handleResize = () => {
    if (window.innerWidth > 571 && this.state.isDrawerOpen) {
      this.toggleDrawer(false);
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null, isSortDropOption: false });
  }

  handleSortDropDownClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event?.currentTarget, isSortDropOption: true });
  }

   
  parseDateTime = (dateStr: string, timeStr: string): Date => {
    
    const [day, month, year] = dateStr?.split('/').map(Number);

    let [hours, minutes] = [0, 0];
    let isPM = false;

    const timeParts = timeStr.split(':');
    if (timeParts.length === 2) {
      hours = parseInt(timeParts[0].trim(), 10);
      minutes = parseInt(timeParts[1].trim(), 10);
  
      if (timeStr.toUpperCase().includes('PM')) {
        isPM = true;
      }
    }
    if (hours === 12) {
      hours = 0; 
    }
    if (isPM) {
      hours += 12; 
    }
    return new Date(year, month - 1, day, hours, minutes);
  };

  sortData = (data: CardsDetails[], order:string)=> {
    return data.sort((a, b) => {
      const dateA = this.parseDateTime(a.attributes.posted_at, a.attributes.post_created_at);
      const dateB = this.parseDateTime(b.attributes.posted_at, b.attributes.post_created_at);

      const comparison = dateA.getTime() - dateB.getTime();
      return order === 'asc' ? -comparison : comparison;
    });
  };
  

  sortTeacherData=(data:any, order:any) =>{ 
    return data.sort((a:any, b:any) => {
        const dateA = moment(a.attributes.created_at, 'DD/MM/YYYY');
        const dateB = moment(b.attributes.created_at, 'DD/MM/YYYY');

        if (order === 'asc') {
            return dateB.diff(dateA);
        } else if (order === 'desc') {
            return dateA.diff(dateB);
        }
    });
}

  handleSort = (event: React.MouseEvent<HTMLElement>, order: string) => {
    const listForSortData = {
      copyOfSearchResult: Array.isArray(this.state.searchedResult) ? [...this.state?.searchedResult]:[],
      copyOfMatchingProfile: [...this.state.logInMatchingProfileJobs],
      copyOfRecentJobs: [...this.state.loginRecentJobs],
      copyOfInterstedJobs: [...this.state.loginInterestedJobs],
      copyOfTeacherList:[...this.state.substituteTeacherDetails],
      copyOfSearchedTeacherList:[...this.state.searchedResultTeacher]
    };
  
    const sortBySearch = this.sortData(listForSortData.copyOfSearchResult, order);
    const sortMatchingProfile = this.sortData(listForSortData.copyOfMatchingProfile, order);
    const sortByRecent = this.sortData(listForSortData.copyOfRecentJobs, order);
    const sortByInterested = this.sortData(listForSortData.copyOfInterstedJobs, order);
    const sortBySearchTeacher = this.sortTeacherData(listForSortData.copyOfSearchedTeacherList , order)
    const sortByTeacherList = this.sortTeacherData(listForSortData.copyOfTeacherList , order)
  
    this.setState({
      searchedResult: sortBySearch,
      selectedSortItem: event?.currentTarget?.textContent || "",
      loginRecentJobs: sortByRecent,
      loginInterestedJobs: sortByInterested,
      logInMatchingProfileJobs: sortMatchingProfile,
      anchorEl: null,
      substituteTeacherDetails : sortByTeacherList,
      searchedResultTeacher:sortBySearchTeacher,
      isSortDropOption: false,
      isPaginate:true
    });
  };
  
  handleListingRelavance = (event: React.MouseEvent<HTMLElement>) => {
    const paginateData = this.paginateResults(this.state.substitueTeacherCopy ,  this.state.current_page);
    const paginateDataSearch = this.paginateResults(this.state.searchedResultTeacherCopy ,  this.state.current_page);
    this.setState({
      searchedResult: this.state.searchedResult,
      selectedSortItem: event?.currentTarget?.textContent || "",
      loginRecentJobs: this.state.recentJobsCopy,
      loginInterestedJobs: this.state.interestedJobsCopy,
      logInMatchingProfileJobs: this.state.matchingProfileJobsCopy,
      substituteTeacherDetails : paginateData,
      searchedResultTeacher:paginateDataSearch,
      anchorEl: null,
      isSortDropOption: false,
      isPaginate:true
    });
  };
  
  handleListingMostRecentData = (event: React.MouseEvent<HTMLElement>) => {
    this.handleSort(event, 'asc');
  };
  
  handleListingLeastRecentData = (event: React.MouseEvent<HTMLElement>) => {
    this.handleSort(event, 'desc');
  };

  handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.setState({ current_page: newPage });
  
    const page = this.state.nameOfPageWhichShowingFull || this.state.filteredItem;
  
    if (!this.state.isCustom || page) {
      this.handlePageActions(page, newPage);
    } else {
      this.handleRoleBasedPagination(newPage);
    }
  
    window.scrollTo(0, 0);
  };
  
  handlePageActions = (page: string, newPage: number) => {
    if (page === 'Recently added') {
      this.handleRecentlyAddedJobs(newPage);
    } else if (page === 'Matching your profile') {
      this.getLoginMatchingProfileJobs(newPage, 10);
    } else if (page === 'You may be interested in') {
      this.handleInterestedJobs(newPage);
    } else if (this.shouldFetchSubstituteTeacherList(page)) {
      this.getSubstituteTeacherList(newPage?.toString());
    }
  };
  
  handleRecentlyAddedJobs = (newPage: number) => {
    if (this.state.isToken === '') {
      this.getNotLoginRecentlyAddedJobs(newPage);
    } else {
      this.getLoginRecentAddedJobs(newPage);
    }
  };
  
  handleInterestedJobs = (newPage: number) => {
    if (this.state.isToken === '') {
      this.getNotLoginInterestedJobs(newPage);
    } else {
      this.getLoginInterestedJobs(newPage);
    }
  };
  
  handleRoleBasedPagination = (newPage: number) => {
    if (this.state.role == 2) {
      this.paginateResults(this.state.searchedResultCopy, newPage);
    } else if (this.state.role  == 1) {
      this.paginateResults(this.state.searchedResultTeacherCopy, newPage);
    }
  };
  
  shouldFetchSubstituteTeacherList = (page: string) => {
    return (this.state.role == 1 && this.state.nameOfPageWhichShowingFull === '') || page === 'Top Candidates';
  };  

  addDataToRecent() {
    let recent = this.state.recent;
    this.setState({ recent }, () => {
      setStorageData("recent", JSON.stringify(this.state.recent));
    });
  }

  // fetch jobs apis - not logged in
  getNotLoginRecentlyAddedJobs = (page:number) => {
    let queryParams = this.sendQuerys()
    const getNotLoggedInRecentJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.noLoginRecentJobsId = getNotLoggedInRecentJobsMsg.messageId;

    getNotLoggedInRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/recently_added_jobs?page=${page}&${queryParams}}`
    );

    getNotLoggedInRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET` 
    );

    runEngine.sendMessage(
      getNotLoggedInRecentJobsMsg.id,
      getNotLoggedInRecentJobsMsg
    );
    
  };
  

  getNotLoginInterestedJobs = (page:number) => {
    const queryParams = this.sendQuerys()
    const getNotLoggedInInterestJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.noLoginInterestedJobsId = getNotLoggedInInterestJobsMsg.messageId;

    getNotLoggedInInterestJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/jobs_you_may_be_interested_in??page=${page}&${queryParams}}}`
    );

    getNotLoggedInInterestJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(
      getNotLoggedInInterestJobsMsg.id,
      getNotLoggedInInterestJobsMsg
    );
  };

  formatDate = (date: string, format: string) => {
    return moment(date, 'MM/DD/YY').format(format);
  };
  
  getEducationLevels = () => {
    const levels = [];
    if (this.state.bachelorCheck) levels.push("Bachelor's degree");
    if (this.state.associateCheck) levels.push("Associate's degree");
    if (this.state.highSchoolCheck) levels.push("High school diploma or equivalent");
    if (this.state.certificateCheck) levels.push("Certification in substitute teaching");
    return levels.length ? `education_level=${levels.join(',')}` : '';
  };
  
  generateQueryParams = (fields: any) => {
    const queryParams: any = [];
  
    if (fields.enteredText) {
      queryParams.push(`job_title=${fields.enteredText}`);
    }
  
    if (fields.selectedState) {
      queryParams.push(`state=${fields.selectedState}`);
    }
  
    const educationLevels = this.getEducationLevels();
    if (educationLevels) {
      queryParams.push(educationLevels);
    }
  
    if (this.state.role == 2) {
      if (fields.selectedDateFrom) {
        queryParams.push(`start_date=${this.formatDate(fields.selectedDateFrom, 'D MMMM YYYY')}`);
      }
    
      if (fields.selectedDateTo) {
        queryParams.push(`end_date=${this.formatDate(fields.selectedDateTo, 'D MMMM YYYY')}`);
      }
    }
    else{
      if (fields.selectedDateFrom) {
        queryParams.push(`from_date=${this.formatDate(fields.selectedDateFrom, 'DD-MM-YYYY')}`);
      }
    
      if (fields.selectedDateTo) {
        queryParams.push(`to_date=${this.formatDate(fields.selectedDateTo, 'DD-MM-YYYY')}`);
      }
    }
  
    if (fields.value) {
      queryParams.push(`experience_required=${fields.value} year`);
    }
  
    return queryParams.join('&');
  };
  
  sendQuerys = () => {
    const queryParams = this.generateQueryParams(this.state);
    if (queryParams.length > 0) {
      this.setState({ current_page: 1 });
    }
    return queryParams;
  };
  
  sendTeacherQuery = () => {
    const queryParams: any = [];
  
    const [firstName, lastName] = this.state.enteredText.split(' ');
  
    if (firstName) {
      queryParams.push(`first_name=${firstName}`);
    }
    if (lastName) {
      queryParams.push(`last_name=${lastName}`);
    }
  
    const teacherQueryParams = this.generateQueryParams(this.state);
    if (teacherQueryParams) {
      queryParams.push(teacherQueryParams);
    }
  
    return queryParams.join('&');
  };
  

  getLoginRecentAddedJobs = async (page:number) => {
    let queryParams = this.sendQuerys()
    const token = await storage.get("loginToken");

    const header = { token };

    const getLoginRecentJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginRecentJobsId = getLoginRecentJobsMsg.messageId;

    getLoginRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/jobs_recently_added?page=${page}&${queryParams}`
    );

    getLoginRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getLoginRecentJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getLoginRecentJobsMsg.id, getLoginRecentJobsMsg);
  };

  getLoginInterestedJobs = async (page:number) => {
    const token = await storage.get("loginToken");
    let queryParams = this.sendQuerys()

    const header = { token };

    const getLoginInterestedJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginInterestedJobsId = getLoginInterestedJobsMsg.messageId;

    getLoginInterestedJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/jobs_you_may_be_interested_in?page=${page}&${queryParams}`
    );

    getLoginInterestedJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getLoginInterestedJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(
      getLoginInterestedJobsMsg.id,
      getLoginInterestedJobsMsg
    );
  };

  getLoginMatchingProfileJobs = async (page:number,perpage?:number|string) => {
    const token = await storage.get("loginToken");
    const header = { token };
    let queryParams = this.sendQuerys()
    if ((this.state.role == 2 || !this.state.filteredItem) && queryParams.length != 0 ) { 
      this.setState({
        isSearch:queryParams.length > 0,
        isFullView :queryParams.length > 0
      })
    }
    const getLoginMatchingJobsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.logInMatchingProfileJobsId = getLoginMatchingJobsMsg.messageId;

    getLoginMatchingJobsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/job_creations/show_jobs_matching_your_profile?page=${page}&${queryParams}`
    );

    getLoginMatchingJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getLoginMatchingJobsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getLoginMatchingJobsMsg.id, getLoginMatchingJobsMsg);
  };

  goToJobDetailPage = (
    jobId: string,
    companyName: string,
    companyLocation: string,
    companyLogo: string
  ) => {
    const jobMessage = new Message(getName(MessageEnum.NavigationMessage));
    jobMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "JobDetailsPage"
    );

    jobMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      companyName,
      companyLocation,
      companyLogo,
      jobId,
    });
    jobMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );

    this.send(jobMessage);
  };

  searchJob = async (inputValue: string) => {
    this.setState({ loading: true });
    let queryParams = this.sendQuerys()
    if ((this.state.role == 2 || !this.state.filteredItem) && queryParams.length != 0 ) { 
      this.setState({
        isSearch:queryParams.length > 0,
        isFullView :queryParams.length > 0
      })
    }

    if (!queryParams) {
      this.resetData()
    }
    const token = await storage.get("loginToken");
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_joblisting/job_creations/job_filters?${queryParams}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUrl = (imageUrl: string) => {
    if (imageUrl.includes("https://") || imageUrl.includes("http://")) {
      return imageUrl;
    } else {
      return config.baseURL + imageUrl;
    }
  };

  handleBachelor = () => {
    this.setState({ bachelorCheck: !this.state.bachelorCheck });
    this.debounceHandler()
  };
  handleAssociat = () => {
    this.setState({ associateCheck: !this.state.associateCheck });
    this.debounceHandler()
  };
  handlehighScool = () => {
    this.setState({ highSchoolCheck: !this.state.highSchoolCheck });
    this.debounceHandler()
  };
  handlecertificate = () => {
    this.setState({ certificateCheck: !this.state.certificateCheck });
    this.debounceHandler()
  };
  handleStateSelect = (selectedState: string) => {
    if (this.state.selectedState) {
      this.setState({ selectedState  :"" });  
      this.debounceHandler()
    }
    else{
      this.setState({ selectedState });
      this.debounceHandler()
    }
    this.debounceHandler()
  };
  debounce = (func: Function, delay: number) => {
    return (...args: any[]) => {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  
  handleChange = (event: ChangeEvent<{}>, value: number | number[]) => {
    this.setState({ value: value as number });
    this.debounceHandler()
  };

  resetData =()=>{
    if (this.state.role == 2 || !this.state.isToken) {
      this.navigateToBack()
      this.getLoginInterestedJobs(1)
      this.getLoginMatchingProfileJobs(1,10)
      this.getLoginRecentAddedJobs(1)
    }
    if (this.state.role == 1) {
      this.navigateToBack()
      this.getSubstituteTeacherList('1')
      this.setState({
        isPaginate:false,
        isSearch:false,
        isFullView:false,
        filteredItem:'',
        nameOfPageWhichShowingFull:'',
        selectedState:''
      })
    }
  }

  handleTextFieldChange = (event: { target: { value: string } }) => {
    const { value } = event.target;
    this.setState({ enteredText: value ,searchedItemText: value ? value : ""});
    if (!value) {
      if ((this.state.filteredItem || this.state.nameOfPageWhichShowingFull)) { 
        this.handleSearchButtonOnClick()
        this.setState({
          isSearch:true,
          isFullView :true
        })
        
      }else{
        this.setState({
          isSearch:false,
          isFullView :false,
          isPaginate:false
        })
      }
    }
  };

  handleDateFrom = (event: { target: { value: any } }) => {
    const { value } = event.target;
    this.setState({ selectedDateFrom: value });
    this.debounceHandler()
  };
  handleDateTo = (event: { target: { value: any } }) => {
    const { value } = event.target;
    this.setState({ selectedDateTo: value });
    this.debounceHandler()
  };

  paginateResults = (data:any[], newPage:number) => {
    this.setState({
      current_page : newPage,
    })
    const {pageSize} = this.state;
    const startIndex = (newPage - 1) * pageSize;
    const paginatedResults = data?.slice(startIndex, startIndex + pageSize);
    if (this.state.role == 1) {
      this.setState({ searchedResultTeacher: paginatedResults ,isPaginate:true });
    }
    else{
      this.setState({ searchedResult: paginatedResults ,isPaginate:true });
    }
    if (data?.length < 10) {
      this.setState({
        isPaginate:false
      })
    }
    return paginatedResults
  };

  handleSearchButtonOnClick = () => {
    const { enteredText, isToken, role, nameOfPageWhichShowingFull, filteredItem, matchingJobsPagination } = this.state;
    const page = nameOfPageWhichShowingFull || filteredItem;
  
    if (role == 2 && isToken) {
      switch (page) {
        case 'Matching your profile':
          this.setState({ filteredItem: 'Matching your profile' });
          this.getLoginMatchingProfileJobs(0, matchingJobsPagination?.total_count);
          this.handleViewForMatchingProfileJobs('matchingProfile', matchingJobsPagination);
          return;
        case 'Recently added':
          this.setState({ filteredItem: 'Recently added' });
          this.getLoginRecentAddedJobs(0);
          this.handleViewForRecentlyAddedJobs('recentlyadded');
          return;
        case 'You may be interested in':
          this.setState({ filteredItem: 'You may be interested in' });
          this.getLoginInterestedJobs(0);
          this.handleViewInterestedJobs('youmayintrestedin');
          return;
      }
    } 
    else if (role == 2 && !isToken) {
      if (page === 'Recently added') {
        this.setState({ filteredItem: 'Recently added' });
        this.getLoginRecentAddedJobs(0);
      } else if (page === 'You may be interested in') {
        this.setState({ filteredItem: 'You may be interested in' });
        this.getLoginInterestedJobs(0);
      }
    }
  
    if (nameOfPageWhichShowingFull) {
      this.setState({ filteredItem: nameOfPageWhichShowingFull });
    }
  
    this.setState({ 
      nameOfPageWhichShowingFull: role == 1 ? 'Top Candidates' : '',
      isPaginate: true 
    });
  
    if (!page || page === 'Top Candidates') {
      role == 1 ? this.getSubstituteTeacherList('1') : this.searchJob(enteredText);
    }
  };
  
  
  handleSearch = (responseJson : CardsDetails[]) => {
    const { enteredText, pageSize } = this.state;
    this.setState({ isSearchedClicked: true, currentPage: 1 });    
    const filteredCards: CardsDetails[] = responseJson
    if (enteredText.trim() === "") {
      this.setState({ isSearchEmpty: false, isSearchedClicked: false, searchedResult: this.state.cardsDetails ,isPaginate:false });
    } else if (filteredCards?.length === 0) {
      this.setState({ isSearchedClicked:true,isSearchEmpty: true, isPaginate:false, searchedResult: filteredCards });
    } else {
      this.setState({isSearchedClicked:true , searchedResult: filteredCards, isSearchEmpty: false, searchedResultCopy: filteredCards, total_count: filteredCards?.length ,total_pages: Math.ceil(filteredCards?.length / pageSize) });
      if (this.state.nameOfPageWhichShowingFull || this.state.filteredItem) {
        this.setState({
          isCustom:false
        })
      }
      else{
        this.setState({
          isCustom:true
        })
      }
    }
    this.paginateResults(filteredCards , this.state.current_page);
  };

  
  handleSearchTeacher = (responseJson: any[]) => {
    const { enteredText, pageSize, currentPage } = this.state;
  
  const [firstName , lastName] = enteredText.trim().split(" ")

  this.setState({ isSearchedClicked: true, currentPage: 1 });
  const filteredCards: any[] = responseJson
    if (enteredText.trim() === "") {
      this.setState({ isSearchEmpty: false, isSearchedClicked: false, searchedResultTeacher: this.state.cardsDetails });
    } else if (filteredCards.length === 0) {
      this.setState({ isSearchEmpty: true, searchedResultTeacher: [] , isPaginate:false });
    } else {
      this.setState({ 
        searchedResultTeacher: filteredCards,
        isSearchEmpty: false,
        searchedResultTeacherCopy: filteredCards,
        isPaginate:false
      });
    }
  
    if (enteredText === "") {
      this.setState({
        isFilterOptionEmpty: false,
        searchedResultTeacher: [],
        searchedResultTeacherCopy: []
      });
    }
  };
  

  clearSearchText = () => {
    this.setState({ enteredText: '',isSearchedClicked:false });
    const {filteredItem , role} =  this.state
      if ((filteredItem || this.state.nameOfPageWhichShowingFull)) { 
        this.handleSearchButtonOnClick()
        this.setState({
          isSearch:true,
          isFullView :true
        })
        
      }else{
        this.setState({
          isSearch:false,
          isFullView :false,
          isPaginate:false,
        })
        this.resetData()
      }
  }

  handleChipDelete = (item: string) => {
    if (item === "Bachelor's degree") {
      this.setState({ bachelorCheck: false });
      this.debounceHandler()   
    } else if (item === "Associate's degree") {
      this.setState({ associateCheck: false });
      this.debounceHandler()  
    } else if (item === "High school diploma or equivalent") {
      this.setState({ highSchoolCheck: false });
     this.debounceHandler()   
    } else if (item === "Certification in substitute teaching") {
      this.setState({ certificateCheck: false });
     this.debounceHandler()   
    } else if (item.startsWith("Exp -")) {
      this.setState({ value: 0 });
     this.debounceHandler()   
    } else if (item.startsWith("From -")) {
      this.setState({ selectedDateFrom: "" });
     this.debounceHandler()   
    } else if (item.startsWith("To -")) {
      this.setState({ selectedDateTo: "" });
     this.debounceHandler()   
    } else if (this.state.selectedState) {
      this.setState({ selectedState: "" });
     this.debounceHandler()   
    }
  };


  clearAll = () => {
    this.setState({
      bachelorCheck: false,
      associateCheck: false,
      highSchoolCheck: false,
      certificateCheck: false,
      value: 0,
      selectedState: "",
      selectedDateFrom :"",
      selectedDateTo : ""
    });
    this.debounceHandler()
  };

  viewDetailPageTeacher = (index: number , email:string , name:string) => {
    if(this.state.isToken){
      storage.set("profileId" , index)
      storage.set("email" , email)
      storage.set("fname" , name)
      this.setState({
        isPaginate:false
      })
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'TeacherListingDetails'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
  };

  viewDetailPage = (index: number) => {
    if(this.state.isToken){
      storage.set('jobId',index)
      this.setState({
        isPaginate:false
      })
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'JobListingDetails'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
  };

  handleChanges = (selectedDate: any) => {
    this.setState({
      selectedDateFrom: moment(selectedDate).format("MM/DD/YY"),
      datePickerIsOpen: false,
      ageError: false,
    });
    this.debounceHandler()
  };
  openDatePicker() {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  convertFormat = () => {
    return (
      this.state.selectedDateFrom &&
      moment.parseZone(this.state.selectedDateFrom).format("DD MMM YYYY")
    );
  };
  handleChangesTo = (selectedDate: any) => {
    this.setState({
      selectedDateTo: moment(selectedDate).format("MM/DD/YY"),
      datePickerToIsOpen: false,
      ageError: false,
    });
    this.debounceHandler()
  };
  openDatePickerTo() {
    this.setState({
      datePickerToIsOpen: !this.state.datePickerToIsOpen,
    });
  }

  convertFormatTo = () => {
    return (
      this.state.selectedDateTo &&
      moment.parseZone(this.state.selectedDateTo).format("DD MMM YYYY")
    );
  };

  getSubstituteTeacherList = async (page:string) => {
    const token = await storage.get("loginToken");

    let queries = this.sendTeacherQuery()
    if (this.state.role == 1) {
      this.setState({
        isFullView:queries.length > 0,
        isSearch:queries.length > 0,
      })
    }
    
    if (this.state.filteredItem == 'Top Candidates') {
      this.setState({
        isFullView :true,
        isSearch :true
      })
    }
    const header = { token };

    const getSubstituteTeacherList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.teacherListId = getSubstituteTeacherList.messageId;

    getSubstituteTeacherList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/substitute_teacher_list?page=${page}&${queries}`
    );

    getSubstituteTeacherList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getSubstituteTeacherList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(
      getSubstituteTeacherList.id,
      getSubstituteTeacherList
    );
  };

  toggleDrawer = (isOpen: boolean) => {
    this.setState({ isDrawerOpen: isOpen });
  };
}

// Customizable Area End
