import { Box, Radio, FormControlLabel, RadioGroup, Typography, styled } from '@material-ui/core';
import React from 'react';

interface Props {
    title: string;
    options: string[];
    value: string;
    margin?: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioList: React.FC<Props> = ({ title, options, value, margin, handleChange }) => {

    const MainBox = styled(Box)({
        display: 'flex',
        marginTop: margin,
        "@media only screen and (max-width: 798px)": {
            display: 'block',
            marginTop: '12px',
        },
    });

    return (
        <MainBox>
            <LeftBox>
                <MainTitle>{title}</MainTitle>
            </LeftBox>
            <RightBox>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                >
                    {options.map((option, index) => (
                        <FormControlLabels key={option} value={option} control={<Radio style={{color: '#395169', paddingTop: '0', marginRight: "16px", 
                        paddingBottom: '0', 
                        paddingRight: '0',}} />} label={option} />
                    ))}
                </RadioGroup>
            </RightBox>
        </MainBox>
    );
};

const LeftBox = styled(Box)({
    width: '306px',
});

const MainTitle = styled(Typography)({
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    color: '#334155',
    "@media only screen and (max-width: 798px)": {
        marginBottom: '12px',
    },
    "@media only screen and (max-width: 414px)": {
        fontSize: '14px',
    },
});

const RightBox = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
});

const FormControlLabels = styled(FormControlLabel)({
    width: 'fit-content',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    color: '#1E293B',
    marginBottom: '24px',
    "@media only screen and (max-width: 798px)": {
        marginBottom: '16px',
    },
    "@media only screen and (max-width: 414px)": {
        color: '#0F172A',
    },
});

const webStyle = {
    checkedPrivacyAndTerms: {
        color: '#395169',
        marginRight: '16px',
        padding: '0px',
    },
};

export default RadioList;
