Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.loginApiContentType = "application/json";
exports.sendInvitesAPIEndPoint = "bx_block_invitefriends2/invitations";
exports.loginAPiMethod = "POST";

exports.errorMsgInvalidEmail = "*Invalid email, please enter valid email.";
exports.errorMsgPleaseEnterEmail = "*Please enter email address.";
exports.errorMsgPleaseEnterMessage = "*Please enter message";

// Customizable Area End