Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.loginAPIEndPoint = "bx_block_login/logins";
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.pccLogoAlt = "pcc_logo";
exports.welcomeBackTxt = "Welcome back,";
exports.enterEmailAndPasswordTxt = "Enter your email and";
exports.toContinueTxt = "to continue...";
exports.emailPlaceholderTxt = "Enter email";
exports.enterTxt = "Enter";
exports.passTxt = "pass";
exports.wordTxt = "word";
exports.emailTxt = "Email";
exports.rememberMeTxt = "Remember me";
exports.forgetPasswordTxt = "Forgot";
exports.questionMarkTxt = "?";
exports.loginText = "Log in";
exports.dontHaveAccText = "Don’t have an account?";
exports.signupText = "Sign up";
exports.dotTxt = "."
exports.commaTxt = ',';
exports.errorMsgPleaseEnterEmail = "*Please enter email address.";
exports.errorMsgInvalidEmail = "*Invalid email, please enter valid email.";
exports.invalidTxt = 'Invalid';
exports.starTxt = '*';
exports.errorMsgPleaseEnterPassword = `*Please enter account`;
exports.errorMsgInvalidPassword = 'please enter valid';
exports.checkBoxAlertMsg = "You have to agree with Privacy Policy and Terms of Use to Sign up.";
exports.somethingWentWrong = "Oops! Something went wrong on our end. Please try again later."
exports.incorrectText = "Incorrect"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End