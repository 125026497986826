// Customizable Area Start
import React from "react";
import VerificationController, { Props } from "./VerificationController";

import { Box, Button, Typography, Snackbar } from "@material-ui/core";
import OtpInput from "react-otp-input";
import { styled } from "@material-ui/core/styles";
export const configJSON = require("./config");
import {
  wholeBackgroundImage,
  wholeBackgroundImageforMobile,
  back,
  check,
} from "./assets";
// Customizable Area End

export default class OtpConfirmation extends VerificationController {
    constructor(props: Props) {
        super(props);
    // Customizable Area Start
    // Customizable Area End
    }

  // Customizable Area Start
  // Customizable Area End

    render() {
    // Customizable Area Start
    // Customizable Area End
        return (
      // Customizable Area Start
      <MainContainerBox>
        <InnerBox>
          <BackButton data-test-id="backBtn" onClick={this.backToVerification}>
            <div style={{ width: "24px", height: "24px" }}>
              <img src={back} />
            </div>
            <BackTypography>{configJSON.back}</BackTypography>
          </BackButton>
          <MainHeadingTypography>Confirm it’s you</MainHeadingTypography>
          <SubHeadingTypo>
            We just sent a 6-digit code to {this.state.emailPhone}, Please enter
            it below.
          </SubHeadingTypo>
          <BoxOtp>
            <OtpInput
              value={this.state.otpNumber}
              inputType="tel"
              onChange={this.handleOtp}
              containerStyle={{ outline: "none" }}
              data-test-id="otpinput"
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: "100%",
                height: "64px",
                margin: "10px",
                borderRadius: "8px",
                border: "1px solid #94A3B8",
                background: "none",
                outline: "none",
              }}
            />
          </BoxOtp>

          {this.state.isTimerRunning ? (
            <Typography style={webStyle.optText}>
              {this.state.time} sec
            </Typography>
          ) : (
            <Typography
              style={webStyle.optText}
              data-test-id="resend"
              onClick={this.handleResendOtpClick}
            >
              Resend code
            </Typography>
          )}

          <ButtonVerified
            fullWidth
            data-test-id="verify"
            onClick={this.handleVerify}
          >
            Verify code
          </ButtonVerified>
        </InnerBox>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.isOpen}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
          data-test-id="snackbar"
        >
          <div style={webStyle.popup as React.CSSProperties}>
            <img src={check} style={{ marginRight: "8px" }} />
            Your email is verified successfully.
          </div>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isError}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
        >
          <div style={webStyle.poperror as React.CSSProperties}>
            Please enter valid Otp...
          </div>
        </Snackbar>
      </MainContainerBox>
      // Customizable Area End
        );
    }
}
// Customizable Area Start

export const MainContainerBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  background: `url(${wholeBackgroundImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  position: "relative",
  "@media only screen and (max-width: 450px)": {
    background: `url(${wholeBackgroundImageforMobile})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
});

const MainHeadingTypography = styled(Typography)({
  marginTop: "56px",
  fontSize: "30px",
  textAlign: "left" as "left",
  color: "#1E293B",
  fontWeight: 700,
  lineHeight: "40px",
  fontFamily: "Inter",
  "@media only screen and (max-width: 502px)": {
    lineHeight: "32px",
    fontSize: "24px",
    marginTop: "129px",
  },
});

const BoxOtp = styled(Box)({
  marginTop: "48px",
  "@media only screen and (max-width: 502px)": {
    marginTop: "96px",
  },
});

const ButtonVerified = styled(Button)({
  color: "#FFFFFF",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  fontFamily: "Inter",
  textTransform: "none" as "none",
  background: "#395169",
  height: "56px",
  borderRadius: "8px",
  marginTop: "42px",
  widht: "100%",
  "@media only screen and (max-width: 502px)": {
    marginBottom: "48px",
    marginTop: "145px",
  },
});

const SubHeadingTypo = styled(Typography)({
  color: "#334155",
  fontWeight: 400,
  marginBottom: "48px",
  lineHeight: "24px",
  fontFamily: "Inter",
  textAlign: "left" as "left",
  fontSize: "16px",

  "@media only screen and (max-width: 502px)": {
    fontSize: "14px",
    lineHeight: "22px",
    marginTop: "8px",
  },
});
const InnerBox = styled(Box)({
  width: "384px",
  "@media only screen and (max-width: 439px)": {
    width: "327px",
  },
  "@media only screen and (max-width: 341px)": {
    width: "278",
  },
});
const BackButton = styled(Box)({
  display: "flex",
  cursor: "pointer",
  gap: "6px",
  position: "absolute" as "absolute",
  top: 60,
  left: 120,
  "@media only screen and (max-width: 622px)": {
    left: 24,
  },
});
const BackTypography = styled(Typography)({
  color: "#1E293B",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  fontFamily: "Inter",
  display: "contents",
  "@media only screen and (max-width: 622px)": {
    display: "none",
  },
});
const webStyle = {
  createBtn: {
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Inter",
    textTransform: "none" as "none",
    background: "#395169",
    height: "56px",
    borderRadius: "8px",
    marginTop: "42px",
    widht: "100%",
  },
  optText: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "center" as "center",
    marginTop: "74px",
    cursor: "pointer",
  },
  popup: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: "#1E293B",
    borderRadius: "8px",
    height: "42px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Inter",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  poperror: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    backgroundColor: "#FEE2E2",
    color: "DC2626",
    borderRadius: "4px",
    borderLeft: "4px solid #DC2626",
    height: "42px",
    fontFamily: "Inter",
    paddingLeft: "10px",
    fontWeight: 400,
    paddingRight: "10px",
    lineHeight: "24px",
    marginTop:"40px"
  },
};

// Customizable Area End
