export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const pccLogo = require("../assets/pcc_logo.png");
export const wholeBackgroundImage = require("../assets/whole_background_img.png");
export const wholeBackgroundImageforMobile = require("../assets/background_img_for_mobile.png");
export const checkboxConditionFalse = require("../assets/checkbox_condition_false.png");
export const checkboxConditionTrue = require("../assets/checkbox_condition_true.png");
export const email = require("../assets/image_ (5).png");
export const phone = require("../assets/image_ (6).png");
export const back = require("../assets/arrow_back_ios_24px.png");
export const forward = require("../assets/image_close_24px.png");
export const check =require("../assets/image__.png")
export const valid =require("../assets/image_checkmark-circle-outline 1.png")
export const warn =require("../assets/warning_amber_24px.png")
export const checkboxChecked = require("../assets/checkbox_checked.png");
export const checkboxunChecked = require("../assets/checkbox_unchecked.png");
export const visibleOff = require("../assets/VisibleOff.png");