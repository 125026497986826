import React from "react";
// Customizable Area Start
import {
  ScrollView,
  Image,
  Text,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  ImageSourcePropType
} from "react-native";
import { Box, TextField, Typography, styled, Select, MenuItem, Button, createTheme, ThemeProvider, Checkbox, Dialog, Avatar, Divider, Popover, InputAdornment, Snackbar } from "@material-ui/core"

import scale, { verticalScale } from "../../../components/src/Scale";
import PostCreationController, { Props, configJSON ,JobWorkingDay } from "./PostCreationController.web";
import Scale from "../../../components/src/Scale";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web"
import { ArrowBackIos, ArrowForwardIos, Close, Edit, KeyboardArrowDown } from "@material-ui/icons";
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { admin, checked, education, experience, location, logo, school, unchecked } from './assets'
import TimeInput from "../../../components/src/TimeInput.web";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TimeDisplay from '../../../components/src/TimeDisplay.web'
import { tickicon } from "../../settings2/src/assets";
import CircularProgress from "@material-ui/core/CircularProgress";
const customToolbar = [
  ['bold', 'italic'],
  [{ 'list': 'bullet' }, { 'list': 'ordered' },],
];

const modules = {
  toolbar: {
    container: customToolbar,
  },
};
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }
  //Customizable Area Start
  stepFirst=()=>{
    return(
      <>
      {this.state.currentStep == 1 && <>
              {!this.state.editAvailbility &&
              <>
                <Box style={webStyle.jobprefeBox}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '16px', borderBottom: '1px solid #F1F5F9' }}>
                    <Typography style={webStyle.optionLabelText}>Position Working Days</Typography>
                    <Box style={{ display: 'flex', gap: '13px', cursor:'pointer' }} data-test-id="editAvailability" onClick={this.editAvailability}>
                      <Edit />
                      <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>Edit</Typography>
                    </Box>
                  </Box>

                  <Box style={{ display: 'flex', gap: '13px', justifyContent: 'center', padding: '24px' }}>
                    {this.state.daysOfWeek.map(({ label, day }) => (
                      <Avatar
                        key={day}
                        style={{
                          ...webStyle.avtarColor,
                          background: this.state.selectedWorkingDays.includes(day) ? '#D1FAE5' : '#F1F5F9',
                          color: this.state.selectedWorkingDays.includes(day) ? '#059669' : '#94A3B8',
                        }}
                      >
                        {label}
                      </Avatar>
                    ))}
                  </Box>
                </Box>
                <ErrorBox style={{marginTop:'0'}}>{this.state.workingdayError}</ErrorBox>
                </>
              }
              {this.state.editAvailbility &&
                <>
                  <Box style={webStyle.jobprefeBox}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '16px', borderBottom: '1px solid #F1F5F9' }}>
                      <Typography style={webStyle.optionLabelText}>Position Working Days</Typography>
                    </Box>
                    {this.state.workingDaysArray.map((providerType, index) => {
                      const isChecked = this.state.selectedWorkingDays.includes(providerType)
                      return (
                        <Box key={index} style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F1F5F9', padding: '10px 16px', alignItems: 'center' }}>
                          <Typography style={webStyle.preferTime}>{providerType}</Typography>
                          <ThemeProvider theme={theme}>
                            <Checkbox
                              checked={isChecked}
                              style={{ borderRadius: "10px" }}
                              data-test-id="workingDaysClick"
                              onChange={() => this.workingDaysClick(providerType, index)}
                            />
                          </ThemeProvider>
                        </Box>
                      )
                    })}
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button style={webStyle.saveBtn} data-test-id="saveAvailbility" onClick={this.saveAvailbility}>Save</Button>
                  </Box>
                </>
              }
              {this.state.selectedWorkingDays.length > 0 &&
                <Typography style={webStyle.preferTime}>Position Time</Typography>
              }
              <Box>
                {this.state.selectedWorkingDays.map((day) => (
                  <Box key={day} style={{ ...webStyle.jobprefeBox, display: 'flex', flexDirection: 'column', padding: '10px 16px' }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography style={webStyle.prefOption}>{day}</Typography>
                      <Box style={{ display: 'flex', gap: '80px', alignItems: 'center' }}>
                        <Typography style={{ ...webStyle.preferTime, marginTop: 'unset' }}>Full day</Typography>
                        <div style={style2.container as React.CSSProperties}>
                          <input
                            type="checkbox"
                            data-test-id="dayToggle"
                            style={style2.checkbox as React.CSSProperties}
                            id={`checkbox${day}`}
                            checked={this.state.workingDays[day]}
                            onChange={() => this.dayToggle(day)}
                          />
                          <label
                            htmlFor={`checkbox${day}`}
                            style={{
                              ...style2.switch,
                              ...(this.state.workingDays[day] && style2.switchChecked),
                            }}
                          >
                            <span
                              style={{
                                ...style2.slider,
                                ...(this.state.workingDays[day] && style2.sliderChecked),
                              } as React.CSSProperties}
                            ></span>
                          </label>
                        </div>
                      </Box>
                    </Box>

                    <>
                      {!this.state.workingDays[day] && (
                        <>
                    
                          <TimeDisplay
                  label="Start time"
                  time={this.state.workTimes[day]?.start}
                  onClick={() => this.clickTime(day)}
                  testId="clickTime"
                />
                <TimeDisplay
                  label="End time"
                  time={this.state.workTimes[day]?.end}
                  onClick={() => this.clickEndTime(day)}
                  testId="clickEndTime"
                />

                          <TimeDialog open={this.state.startClicked && this.state.activeDay === day} data-test-id="closeTimeDialog" onClose={this.closeTimeDialog}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '24px', borderBottom: '1px solid #E2E8F0' }}>
                              <Typography style={webStyle.popHeading}>
                                Add Start Time
                              </Typography>
                              <Close onClick={this.closeTimeDialog} />
                            </Box>
                            <Box style={{ padding: '20px' }}>
                              <TimeInput data-test-id="handleTimeChange"
                                value={this.state.workTimes[day]?.start || { hour: 9, minute: 0, period: 'AM' }}
                                onChange={(value) => this.handleTimeChange(day, 'start', value)}
                              />
                              <Button data-test-id="saveStartTime" onClick={() => this.saveStartTime(day, this.state.workTimes[day]?.start || { hour: 9, minute: 0, period: 'AM' })}
                                fullWidth style={{ ...webStyle.saveBtn, marginTop: '37px', }}>Save</Button>
                            </Box>
                          </TimeDialog>
                          <TimeDialog open={this.state.endClicked && this.state.activeDay === day} onClose={this.closeTimeDialog}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '24px', borderBottom: '1px solid #E2E8F0' }}>
                              <Typography style={webStyle.popHeading}>
                                Add End Time
                              </Typography>
                              <Close onClick={this.closeTimeDialog} />
                            </Box>
                            <Box style={{ padding: '20px' }}>
                              <TimeInput data-test-id="handleTimeChange"
                                value={this.state.workTimes[day]?.end || { hour: 5, minute: 0, period: 'PM' }}
                                onChange={(value) => this.handleTimeChange(day, 'end', value)}
                              />
                              <Button data-test-id="saveEndTime"
                                onClick={() => this.saveEndTime(day, this.state.workTimes[day]?.end || { hour: 5, minute: 0, period: 'PM' }, this.state.workTimes[day]?.start || { hour: 9, minute: 0, period: 'AM' })}
                                fullWidth style={{ ...webStyle.saveBtn, marginTop: '37px', }}>Save</Button>
                            </Box>
                          </TimeDialog>
                        </>
                      )}
                    </>
                  </Box>
                ))}
              </Box>
              <Typography style={webStyle.textheading}>Start Date</Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Select option"
                value={this.state.textFieldValueDocStart}
                data-test-id="handleStartCalender"
                onClick={this.handleStartCalender}
                style={{ cursor: 'pointer' }}
                InputProps={{
                  style: { pointerEvents: 'none' },
                  endAdornment: (
                    <InputAdornment position="end" style={{ pointerEvents: 'auto', cursor: 'pointer' }} onClick={this.handleStartCalender}>
                      <KeyboardArrowDown />
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorBox style={{marginTop:'0'}}>{this.state.startTimeError}</ErrorBox>
              <CustomPopover
                id="to-popover"
                open={Boolean(this.state.anchorElDocStart)}
                anchorEl={this.state.anchorElDocStart}
                data-test-id="handleCloseDocanchorElDocStart"
                onClose={this.handleCloseDocanchorElDocStart}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Calendar
                  value={this.state.calendarValueDocStart}
                  onClickDay={this.handleDateChangesdoc('calendarValueDocStart', 'textFieldValueDocStart', 'anchorElDocStart')}
                  data-test-id="handleDateChangesdoc"
                  view="month"
                  maxDetail="month"
                  tileContent={({ date }) => {
                    const isSelectedDate =
                      date.getDate() === this.state.calendarValueDocStart?.getDate() &&
                      date.getMonth() === this.state.calendarValueDocStart?.getMonth() &&
                      date.getFullYear() === this.state.calendarValueDocStart?.getFullYear();
                    const style = {
                      borderRadius: '10px',
                      backgroundColor: isSelectedDate ? '#395169' : undefined,
                      color: isSelectedDate ? 'white' : undefined,
                    };
                    return <div style={style} />;
                  }}
                />
              </CustomPopover>
              <Typography style={webStyle.textheading}>End Date</Typography>
              <TextField variant="outlined" fullWidth placeholder="Select option"
                value={this.state.textFieldValueDocEnd}
                data-test-id="handleendCalender"
                onClick={this.handleendCalender}
                style={{cursor:'pointer'}}
                InputProps={{
                  style: { pointerEvents: 'none' },
                  endAdornment: (
                    <InputAdornment position="end" style={{ pointerEvents: 'auto', cursor: 'pointer' }} onClick={this.handleendCalender}>
                      <KeyboardArrowDown />
                    </InputAdornment>
                  ),
                }} />
                <ErrorBox style={{marginTop:'0'}}>{this.state.endTimeError}</ErrorBox>
              <CustomPopover
                id="to-popover"
                open={Boolean(this.state.anchorElDocEnd)}
                anchorEl={this.state.anchorElDocEnd}
                onClose={this.handleCloseDocanchorElDocStart}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Calendar
                  value={this.state.calendarValueDocStart}
                  onClickDay={this.handleDateChangesdoc('calendarValueDocEnd', 'textFieldValueDocEnd', 'anchorElDocEnd')}
                  data-test-id="handleDateChangesdocTo"
                  view="month"
                  maxDetail="month"
                  tileContent={({ date }) => {
                    const isSelectedDate =
                      date.getDate() === this.state.calendarValueDocEnd?.getDate() &&
                      date.getMonth() === this.state.calendarValueDocEnd?.getMonth() &&
                      date.getFullYear() === this.state.calendarValueDocEnd?.getFullYear();
                    const style = {
                      borderRadius: '10px',
                      backgroundColor: isSelectedDate ? '#395169' : undefined,
                      color: isSelectedDate ? 'white' : undefined,
                    };
                    return <div style={style} />;
                  }}
                />
              </CustomPopover>
              <Box style={webStyle.buttons}>
                <Button onClick={this.navigateToHome} style={webStyle.cancleBtn}>Cancel</Button>
                <Button data-test-id="stepOneContinueid" onClick={this.stepOneContinue} style={webStyle.continueBtn}>Continue</Button>
              </Box>
            </>}
      </>
    )
  }
  stepSecond=()=>{
    return(
      <>
      {this.state.currentStep == 2 && <>
              <Typography style={webStyle.textheading}>Job title</Typography>
              <InputField onChange={(event) => { this.onValueChange('jobTitle', event.target.value) }}
                value={this.state.jobTitle} data-test-id="valueChnageone"
                variant="outlined" fullWidth placeholder="Enter job title" />
              <ErrorBox>{this.state.jobTitleError}</ErrorBox>
              <Typography style={webStyle.textheading}>Organization</Typography>
              <InputField variant="outlined" fullWidth placeholder="Enter Organization"
                data-test-id="valueChnageone" onChange={(event) => { this.onValueChange('organisation', event.target.value) }}
                value={this.state.organisation} />
              <ErrorBox>{this.state.organisationError}</ErrorBox>
              <Typography style={webStyle.textheading}>Education level</Typography>
              <Select variant="outlined" fullWidth IconComponent={KeyboardArrowDown} style={{ marginBottom: 32, color: this.state.education == 'Select option' ? '#A5A5A5' : '#1E293B' }}
                value={this.state.education}
                data-test-id="valueChnageone"
                onChange={(event) => { this.onValueChange('education', event.target.value) }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}>
                <MenuItem key='Select option' value='Select option' style={{ display: 'none' }}>Select option</MenuItem>
                {this.state.educationList.map((item) => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              <ErrorBox>{this.state.educationError}</ErrorBox>
              <Typography style={webStyle.textheading}>Experience required<span style={{ fontWeight: 400 }}> (in years)</span></Typography>
              <Select variant="outlined" fullWidth IconComponent={KeyboardArrowDown} style={{ marginBottom: 32, color: this.state.experience == 'Select option' ? '#A5A5A5' : '#1E293B' }}
                onChange={(event) => { this.onValueChange('experience', event.target.value) }} value={this.state.experience}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }} data-test-id="valueChnageone">
                <MenuItem key='Select option' value='Select option' style={{ display: 'none' }}>Select option</MenuItem>
                {this.state.experienceList.map((item) => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              <ErrorBox>{this.state.experienceError}</ErrorBox>
              <Typography style={webStyle.textheading}>Job description</Typography>
              <QuillWrapper>
                <ReactQuill theme="snow" style={{ height: 400 }} placeholder="Enter description"
                  data-test-id='reactQuill'
                  onChange={(html: string) => {
                    this.setState({ jobdescription: html })
                  }}
                  modules={modules}
                />
              </QuillWrapper>
              <ErrorBox style={{ marginTop: '44px' }}>{this.state.jobDesciptionError}</ErrorBox>
              <Box style={webStyle.buttons}>
                <Button onClick={this.backStepOne} style={webStyle.cancleBtn}>Cancel</Button>
                <Button data-test-id="stepTwoContinue" onClick={this.stepTwoContinue} style={webStyle.continueBtn}>Continue</Button>
              </Box>
            </>}</>
    )
  }
  stepThird=()=>{
    return(
      <>
      {this.state.currentStep == 3 && <>
              <Box style={webStyle.checkTypo}>
                <ThemeProvider theme={theme}>
                  <Checkbox
                    checked={this.state.savedAddress}
                    style={{ borderRadius: "10px" }}
                    data-test-id="savedAddressClick"
                    onChange={this.savedAddressClick}
                  />
                </ThemeProvider>
                <Typography style={webStyle.saveAdd}>Use my saved address</Typography>
              </Box>

              <Typography style={webStyle.textheading}>Street address</Typography>
              <InputField variant="outlined" fullWidth placeholder="Enter street address" data-test-id="valueChnageoneTwostreet"
                onChange={(event) => { this.onValueChange('street', event.target.value) }} value={this.state.street}
              />
              <ErrorBox>{this.state.streetError}</ErrorBox>
              <Typography style={webStyle.textheading}>City</Typography>
              <InputField variant="outlined" fullWidth placeholder="Enter city" data-test-id="valueChnageoneTwo"
                onChange={(event) => { this.onValueChange('city', event.target.value) }} value={this.state.city}
              />
              <ErrorBox>{this.state.cityError}</ErrorBox>
              <Typography style={webStyle.textheading}>State</Typography>
              <InputField variant="outlined" fullWidth placeholder="Enter state" data-test-id="valueChnageoneTwosatate"
                onChange={(event) => { this.onValueChange('state', event.target.value) }} value={this.state.state}
              />
              <ErrorBox>{this.state.stateError}</ErrorBox>
              <Typography style={webStyle.textheading}>Zip code</Typography>
              <InputField variant="outlined" fullWidth placeholder="Enter zip code" data-test-id="valueChnageoneTwocode"
                onChange={(event) => { this.onValueChange('zipcode', event.target.value) }} value={this.state.zipcode}
              />
              <ErrorBox>{this.state.zipcodeError}</ErrorBox>
              <Typography style={webStyle.textheading}>Country</Typography>
              <Select variant="outlined" fullWidth IconComponent={KeyboardArrowDown} style={{ marginBottom: 32, color: this.state.country == 'Select option' ? '#A5A5A5' : '#1E293B' }}
                onChange={(event) => { this.onValueChange('country', event.target.value) }} value={this.state.country} data-test-id="valueChnageoneTwocountry"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}>
                <MenuItem key='Select option' value='Select option' style={{ display: 'none' }}>Select option</MenuItem>
                {this.state.countryList.map((item) => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              <ErrorBox>{this.state.countryError}</ErrorBox>
              <Typography style={webStyle.textheading}>About organization</Typography>
              <InputField fullWidth variant="outlined" multiline minRows={10} placeholder="Write something..."
                onChange={(event) => { this.onValueChange('aboutOrg', event.target.value) }} value={this.state.aboutOrg} data-test-id="valueChnageoneAbout"
              />
              <ErrorBox style={{ marginTop: '3px' }}>{this.state.aboutOrgError}</ErrorBox>
              <Box style={webStyle.buttons}>
                <Button data-test-id="handleCancleTwo" onClick={this.handleCancleTwo} style={webStyle.cancleBtn}>Cancel</Button>
                <Box>
                  <Button data-test-id="handlePreview" onClick={this.handlePreview} style={webStyle.preview}>Preview</Button>
                  <Button data-test-id="publishBtnid" onClick={this.publishBtn} style={webStyle.continueBtn}>Publish</Button>
                </Box>
              </Box>
            

              <CustomDialog open={this.state.openPreview} onClose={this.closePreview} data-test-id="closePreview">
                <Box style={{ height: '708px' }}>
                  <Box style={webStyle.header}>
                    <Typography style={webStyle.headerHeading}>Job post preview</Typography>
                    <Close onClick={this.closePreview} />
                  </Box>
                  <Box style={webStyle.mainBox}>

                    <JobDetailsTopBox>
                      <Box style={webStyle.jobTitleMain} >
                        <Box>
                          <MainTitle>{this.state.jobTitle}</MainTitle>
                          <MainCompanyName>{this.state.organisation}</MainCompanyName>
                        </Box>
                        <DisplayForDesktop>
                          <ImageAvatar alt="company_logo" src={school} />
                        </DisplayForDesktop>

                      </Box>
                      <JobTitleTopMiddleSection>
                        <JobTitleTopMiddleSmallBox>
                          <Box style={webStyle.jobTitleMiddleInnerBox} >
                            <img src={experience} alt="" />
                            <JobTitleTopCategoryTypo>EXPERIENCE</JobTitleTopCategoryTypo>
                          </Box>
                          <JobTitleTopCategoryDetail>{this.state.experience}</JobTitleTopCategoryDetail>
                        </JobTitleTopMiddleSmallBox>
                        <JobTitleTopMiddleSmallBox>
                          <Box style={webStyle.jobTitleMiddleInnerBox} >
                            <img src={education} alt="" />
                            <JobTitleTopCategoryTypo>EDUCATION LEVEL</JobTitleTopCategoryTypo>
                          </Box>
                          <JobTitleTopCategoryDetail>{this.state.education}</JobTitleTopCategoryDetail>
                        </JobTitleTopMiddleSmallBox>
                        <JobTitleTopMiddleSmallBox>
                          <Box style={webStyle.jobTitleMiddleInnerBox} >
                            <img src={location} alt="" />
                            <JobTitleTopCategoryTypo>LOCATION</JobTitleTopCategoryTypo>
                          </Box>
                          <JobTitleTopCategoryDetail>{this.state.city}, {this.state.state}, {this.state.country}</JobTitleTopCategoryDetail>
                        </JobTitleTopMiddleSmallBox>
                      </JobTitleTopMiddleSection>
                      <JobDetailsBottomDivider />
                      <Box style={webStyle.postedAtBox} >
                        Posted:
                        <Typography style={webStyle.postedAtText} >Just now</Typography>
                      </Box>

                    </JobDetailsTopBox>

                    <PostedByBox>
                      <PostedByTextTypography>Posted by</PostedByTextTypography>
                      <PostedByDetailsBox>
                        <PostedByDetailsLeftBox>
                          <ImageAvatar src={admin} alt="" />
                          <PostedByDetailsMiddleBox>
                            <PostedByNameTypography>{this.state.firstname} {this.state.lastname}</PostedByNameTypography>
                            <PostedByNameDetailsBox>
                              <AdminTypeTypography>School Admin</AdminTypeTypography>
                              <SchoolNameDot>.</SchoolNameDot>
                              <SchoolNameTypography>{this.state.organisation}</SchoolNameTypography>
                            </PostedByNameDetailsBox>
                          </PostedByDetailsMiddleBox>
                        </PostedByDetailsLeftBox>

                      </PostedByDetailsBox>
                      <DividerForMobile />
                    </PostedByBox>

                    <PostedByBox>
                      <JobDescTitle>{configJSON.positionTitle}</JobDescTitle>
                      {this.state.job_working_Days.map((item, index) => (
                        <Box key={index} style={{ marginTop: "10px" }}>
                          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                            <Typography style={{ ...webStyle.preTime }}>{this.state.days[item.working_day_id]}</Typography>
                            <Typography style={{ color: "#395169", fontFamily: "Inter", fontSize: "14px", fontWeight: 600, lineHeight: "24px" }}>{renderText(item.full_day )}</Typography>
                          </Box>
                          <>{renderStartEndTime(item)}</>
                          <Box></Box>
                        </Box>
                      ))}
                    </PostedByBox>
                    <PostedByBox>
                      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                        <Typography style={{ ...webStyle.preTime }}>Start Date</Typography>
                        <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}>
                          {this.state.textFieldValueDocStart}
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                        <Typography style={{ ...webStyle.preTime }}>End Date</Typography>
                        <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}>
                          {this.state.textFieldValueDocEnd}
                        </Typography>
                      </Box>
                    </PostedByBox>
                    <JobDescriptionMainBox>
                      <JobDescTitle>Job Description</JobDescTitle>
                      <JobDescJobTitleBox>
                        Job Title:
                        <JobDescJobTitle>{this.state.jobTitle}</JobDescJobTitle>
                      </JobDescJobTitleBox>
                      <AboutOrg>
                        <div dangerouslySetInnerHTML={{ __html: this.state.jobdescription }} />
                      </AboutOrg>
                      <DividerForMobile />
                    </JobDescriptionMainBox>

                    <AboutOrganizationMainBox>
                      <AboutOrganizationTitle>About Organization</AboutOrganizationTitle>
                      <Box style={webStyle.aboutOrganizationTop} >
                        <ImageAvatar src={school} alt="" />
                        <AboutOrgDetailBox>
                          <AboutOrgName>{this.state.organisation}</AboutOrgName>
                          <AboutOrgLocation>{this.state.city}, {this.state.state}, {this.state.country}</AboutOrgLocation>
                        </AboutOrgDetailBox>
                      </Box>
                      <AboutOrg>{this.state.aboutOrg}</AboutOrg>
                    </AboutOrganizationMainBox>

                  </Box>
                </Box>
              </CustomDialog>
              <CustomDialog open={this.state.creditError}>
                <Box style={webStyle.deleteBox}>
                  <Close style={webStyle.deleteClose} data-test-id="closeDialog" onClick={this.closePopup} />
                  <Typography style={webStyle.headerHeading}>Insufficient credits in your account. Please add credits to create a new post. </Typography>
                </Box>
                <Box style={webStyle.deleteConfirm}>
                  <Button style={{ ...webStyle.cancelBtn, width: '150px' }} data-test-id="closeLogout" onClick={this.closePopup}>
                    Cancel
                  </Button>
                  <Button style={{ ...webStyle.submitBtn, width: '150px' }} data-test-id="navigateAddCredit" onClick={this.navigateAddCredit}>
                    Add Credit
                  </Button>
                </Box>
              </CustomDialog>
            </>}</>
    )
  }
  //Customizable Area End

  render() {
  //Customizable Area Start
  //Customizable Area End
    return (
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView>
          {/* Customizable Area Start */}
          <CustomNavbar>
            <Navbar navigation={this.props.navigation} id={""} NaviagationPage={""} />
          </CustomNavbar>
          <CustomProfileHead>
          <ArrowBackIos data-test-id='btnBack' onClick={this.navigateToHome} style={webStyle.backIcon} />
          <NavbarTitle>
            <img src={logo} />
          </NavbarTitle>
          </CustomProfileHead>
          <MainBox>
            <Navigation>
              <Typography data-test-id="navigateToHome" onClick={this.navigateToHome} style={webStyle.home}>Home</Typography>
              <ArrowForwardIos style={webStyle.forwardArrow} />
              <Typography style={webStyle.joblisting}>New job post</Typography>
            </Navigation>
            <ProfileHeading>
              <PostHeading>New job post</PostHeading>
              <Typography style={webStyle.steps}>Step {this.state.currentStep} / 3</Typography>
            </ProfileHeading>
            {!this.state.loader && 
             <>
            {this.stepFirst()}

            {this.stepSecond()}

            {this.stepThird()}
            </>}
            {this.state.loader && 
            <div style={{display:'flex', justifyContent:'space-between',position:'relative', marginTop:'200px',marginBottom:'200px'}}>
            <CircularProgress style={{position:'absolute',left:'50%'}}/></div>}
            <CustomSnackbar
              open={this.state.openSuccess}
              autoHideDuration={3000} 
              data-test-id='closeSnackbar'
              onClose={()=>{this.setState({openSuccess:false})}}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div style={webStyle.popup as React.CSSProperties}>
              <img style={{ marginRight: '10px' }} src={tickicon} alt="" />
              New Job Post created successfully
            </div>
          </CustomSnackbar>
          </MainBox>
          <Footer navigation={this.props.navigation} id={""} NaviagationPage={""} />
          {/* Customizable Area End */}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

// Customizable Area Start

const renderStartEndTime = (item:JobWorkingDay) =>{
  return (
    <>
      {!item.full_day &&
        <>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
            <Typography style={{ ...webStyle.prefOption }}>
              Start Time:
            </Typography>
            <Typography variant="body2" style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}>
              {item.start_time}
            </Typography>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
            <Typography style={{ ...webStyle.prefOption }}>
              End Time:
            </Typography>
            <Typography variant="body2" style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}>
              {item.end_time}
            </Typography>
          </Box>
        </>
      }
    </>
  )
}

const renderText = (item:boolean)=>{
    return item ? "Full Day" : ""
}

const theme = createTheme({
  props: {
    MuiCheckbox: {
      icon: <img src={unchecked} />,
      checkedIcon: <img src={checked} />,
    },
  },
})
const CustomSnackbar = styled(Snackbar)({
  boxShadow: "0px 6px 15px -3px #00000026",
  borderRadius: 8,
  "&.MuiSnackbar-anchorOriginTopCenter": {
    top: 158,
    width: '285px'
  }
})
const CustomPopover = styled(Popover)({
  "& .MuiPaper-rounded": {
    borderRadius: '16px',
    padding: 10,
  },
  width: '100%',
  "& .react-calendar": {
    border: 'unset',
  },
  "& .react-calendar__tile--active:enabled": {
    background: '#395169',
    borderRadius: '10px'
  },
  "& .react-calendar__tile:enabled:hover": {
    borderRadius: '10px'
  },
  "& .react-calendar__year-view .react-calendar__tile": {
    padding: '1rem 0.5rem',
  },
  "& .react-calendar button": {
    border: '1px solid #E2E8F0',
    borderRadius: '10px',
  },
  "& .react-calendar__navigation button": {
    border: 'none'
  }

})
const TimeDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    width: 375,
    height: '256px'
  }
})
const CustomDialog = styled(Dialog)({
  borderRadius: '16px',
  '& .MuiDialog-paperWidthSm': {
    minWidth: '700px',
    borderRadius: '16px',
    "@media only screen and (max-width: 816px)": {
      minWidth: '500px'
    },
    "@media only screen and (max-width: 553px)": {
      minWidth: '300px'
    },
  }
})
const PostHeading = styled(Typography)({
  color: "#1E293B",
  fontFamily: "Inter",
  fontSize: "30px",
  fontWeight: 700,
  lineHeight: "40px",
  "@media only screen and (max-width: 414px)": {
    fontSize: '16px'
  },
})
const ErrorBox = styled(Box)({
  color: '#DC2626',
  height: '18px',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: "18px",
  marginTop: "-33px",
  marginBottom: "10px",
  "@media only screen and (max-width: 414px)": {
    marginTop: "-33px"
  },
})
const CustomNavbar = styled(Box)({
  "@media only screen and (max-width: 657px)": {
    display: 'none'
  },
})
const NavbarTitle = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  color: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
const CustomProfileHead = styled(Box)({
  display: 'none',
  "@media only screen and (max-width: 657px)": {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#395169',
    padding: 26,
    position: 'relative',
    paadingBottom: 37
  }
})
const QuillWrapper = styled(Box)({
  position: 'relative',
  display: 'flex',
  borderTop: "1px solid #ccc",
  borderRadius: 8,
  flexDirection: 'column-reverse',
  "& .ql-toolbar": {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    transform: 'translateY(100%)',
    borderTop: 'none',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  "& .ql-container": {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  "& .ql-editor::before": {
    color: '#A5A5A5',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal'
  }
})
const MainBox = styled(Box)({
  background: "#F8F8F8",
  position: "relative",
  padding: "140px 426px 120px 426px",
  "@media only screen and (max-width: 1330px)": {
    padding: 100,
  },
  "@media only screen and (max-width: 688px)": {
    paddingLeft: 29,
    paddingRight: 29
  },
  "@media only screen and (max-width: 571px)": {
    padding: '29px'
  },
  "@media only screen and (max-width: 404px)": {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: '29px',
    paddingTop: '29px'
  },
  "@media only screen and (max-width: 375px)": {
    paddingLeft: 4,
    paddingRight: 4,
    paddingBottom: '29px',
    paddingTop: '29px'
  }
})
const Navigation = styled(Box)({
  display: "flex",
  gap: "10px",
  position: "absolute" as "absolute",
  top: 32,
  left: 114,
  "@media only screen and (max-width: 657px)": {
    left: 38
  },
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const ProfileHeading = styled(Box)({
  marginBottom: "32px",
  display: "flex",
  justifyContent: 'space-between',

})
const webStyle = {
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4
  },
  steps: {
    color: '#475569',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  textheading: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    marginBottom: 6,
    marginTop: '27px'
  },
  buttons: {
    display: 'flex',
    justifyContent: "space-between",
    marginTop: '130px'
  },
  cancleBtn: {
    color: "#395169",
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'none' as 'none'
  },
  continueBtn: {
    borderRadius: '8px',
    background: '#395169',
    padding: '16px',
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'none' as 'none'
  },
  preview: {
    borderRadius: '8px',
    background: '#fff',
    border: '1px solid #395169',
    padding: '16px',
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '16px',
    marginRight: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'none' as 'none'
  },
  checkTypo: {
    display: 'flex',
    gap: 2,
    marginBottom: 32,
    marginLeft: '-7px'
  },
  saveAdd: {
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: '6px'
  },
  jobTitleMain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  jobTitleMiddleInnerBox: {
    display: 'flex',
    alignItems: 'center',
  },
  postedAtBox: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  postedAtText: {
    display: 'inline-block',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
    color: '#4788b7',
    marginLeft: '4px',
  },
  aboutOrganizationTop: {
    display: 'flex',
  },
  headerHeading: {
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 16px',
    background: '#fff'
  },
  mainBox: {
    padding: '24px',
    background: '#F8F8F8',
  },
  schoolname: {
    color: '#475569',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  buttonbox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 40px'
  },
  cancelBtn: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: '24px',
    width: '100%',
    background: '#fff',
    textTransform: 'none' as 'none',
    height: '56px',
    cursor: 'pointer',
  },
  submitBtn: {
    color: '#fff',
    background: '#395169',
    width: '100%',
    fontFamily: 'Inter',
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: '24px',
    cursor: 'pointer',
    textTransform: 'none' as 'none',
    height: '56px'
  },
  deleteBox: {
    padding: '60px 40px 32px 40px',
    position: 'relative' as 'relative'
  },
  deleteClose: {
    position: 'absolute' as 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer'
  },
  deleteConfirm: {
    borderTop: '1px solid #E2E8F0',
    padding: '20px 40px',
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end'
  },
  jobprefeBox: {
    borderRadius: '8px',
    border: '1px solid #E2E8F0',
    marginTop: '16px'
  },
  preferTitle: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    borderBottom: '#F1F5F9',
    padding: '15px 16px'
  },
  prefOption: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  preTime:{
    color: '#1E293B',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Inter',
  },
  travel: {
    color: '#475569',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '20px',
    marginBottom: '10px'
  },
  avtarColor: {
    width: '32px', height: '32px', fontWeight: 700, fontFamily: 'Inter', fontSize: '14px'
  },
  preferTime: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '16px',
  },
  optionLabelText: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  saveBtn: {
    color: '#FFFFFF',
    background: '#395169',
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: 16,
    textTransform: "none" as "none",
    padding: "10px 16px",
    borderRadius: 8,
    cursor: 'pointer'
  },
  editText: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginLeft: 5,
    cursor: 'pointer'
  },
  popHeading: {
    color: '#1E293B',
    fontFamily: 'Inter' as 'Inter',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
  },
  backIcon: {
    color: '#fff'
  },
  popup: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    width: '332px',
    padding: '10px 10px 10px 10px',
    gap: '1px',
    borderFadius: '8px',
    boxShadow: '0px 6px 15px -3px #00000026',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#0F172A',
  },
}
const styles = StyleSheet.create({
  safeAreaView: {
  },
  inputTextField: {
    height: verticalScale(80),
    borderColor: "grey",
    borderWidth: 1,
    color: "black",
    fontSize: scale(16.7),
    borderBottomWidth: 0.3,
    margin: scale(3),
    padding: 10,
    marginTop: verticalScale(5)
  },

  inputTextField1: {
    height: verticalScale(50),
    borderColor: "grey",
    borderWidth: 1,
    color: "black",
    fontSize: scale(16.7),
    borderBottomWidth: 0.3,
    paddingLeft: 10,
    paddingRight: 10,
    margin: scale(3)
  },

  buttonStyle: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 22,
    backgroundColor: "#366ef9",
    height: scale(50),
    marginTop: verticalScale(20)
  },
  imageStyle: {
    width: scale(100),
    height: scale(100),
    borderRadius: scale(50),
    borderWidth: 1,
    borderColor: "black",
    marginTop: verticalScale(5),
    marginBottom: verticalScale(25),
    opacity: 0.6
  },
  buttonText: {
    color: "white"
  },
  editIcon: {
    position: "absolute",
    height: scale(20),
    width: scale(20),
    resizeMode: "contain",
    top: verticalScale(90),
    left: Scale(90)
  },
});
const style2 = {
  container: {
    width: '51px',
    height: '31px',
    position: 'relative',
  },
  checkbox: {
    opacity: 0,
    width: 0,
    height: 0,
    position: 'absolute',
    cursor: 'pointer'
  },
  checkRecom: {
    opacity: 0,
    width: 0,
    height: 0,
    position: 'absolute',
  },
  switch: {
    width: '100%',
    height: '100%',
    display: 'block',
    backgroundColor: '#E2E8F0',
    borderRadius: '16px',
    cursor: 'pointer',
    transition: 'all 0.2s ease-out',
  },
  slider: {
    width: '27px',
    height: '27px',
    position: 'absolute',
    left: 'calc(50% - 27px/2 - 10px)',
    top: 'calc(50% - 27px/2)',
    borderRadius: '50%',
    background: '#FFFFFF',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)',
    transition: 'all 0.2s ease-out',
    cursor: 'pointer',
  },
  switchChecked: {
    backgroundColor: '#395169',
  },
  sliderChecked: {
    left: 'calc(50% - 27px/2 + 10px)',
  },
}
const JobDetailsTopBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  margin: 'auto',
  marginTop: '32px',
  padding: '32px 32px 32px 32px',
  borderRadius: '16px',
  "@media only screen and (max-width: 816px)": {
    padding: '24px 24px 24px 24px',
  },
  "@media only screen and (max-width: 620px)": {
    padding: '16px 16px 16px 16px',
  },
  "@media only screen and (max-width: 414px)": {
    'box-shadow': 'none',
    borderRadius: '0',
    paddingTop: '0px',
  },
});

const MainTitle = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 600,
  color: '#0F172A',
  "@media only screen and (max-width: 816px)": {
    fontSize: '20px',
    lineHeight: '30px',
  },
  "@media only screen and (max-width: 620px)": {
    fontSize: '18px',
    lineHeight: '28px',
  },
});

const MainCompanyName = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  marginTop: "8px",
  color: '#475569',
  "@media only screen and (max-width: 816px)": {
    fontSize: '14px',
    lineHeight: '22px',
  },
  "@media only screen and (max-width: 620px)": {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: "4",
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const JobTitleTopMiddleSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '32px',
  "@media only screen and (max-width: 816px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 620px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    position: 'relative',
    marginTop: '16px',
  },
});

const JobTitleTopMiddleSmallBox = styled(Box)({
  marginRight: '48px',

  "@media only screen and (max-width: 816px)": {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  "@media only screen and (max-width: 620px)": {
    marginBottom: '8px',
    width: '100%',
  },
  "@media only screen and (max-width: 414px)": {
    margin: 'auto',
    marginBottom: '8px',
  },
});

const JobTitleTopCategoryTypo = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '18px',
  color: '#64748B',
  marginLeft: '8px',
  "@media only screen and (max-width: 816px)": {
    width: '120px',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const JobTitleTopCategoryDetail = styled(Typography)({
  marginTop: '8px',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#334155',
  width: '100%',
  "@media only screen and (max-width: 816px)": {
    margin: '0 0 0 8px',
  },
  "@media only screen and (max-width: 620px)": {
    margin: '0 0 0 8px',
    fontSize: '14px',
  },
});

const JobDetailsBottomDivider = styled(Divider)({
  margin: '32px 0 32px 0',
  "@media only screen and (max-width: 414px)": {
    margin: '16px 0 16px 0',
  },
});

const PostedByBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  margin: 'auto',
  marginTop: '24px',
  padding: '32px 32px 32px 32px',
  borderRadius: '16px',
  "@media only screen and (max-width: 816px)": {
    padding: '24px 24px 24px 24px',
  },
  "@media only screen and (max-width: 620px)": {
    padding: '16px 16px 16px 16px',
  },
  "@media only screen and (max-width: 414px)": {
    'box-shadow': 'none',
    borderRadius: '0',
    marginTop: '0px',
    paddingTop: '0',
    paddingBottom: '0',
  },
});

const PostedByTextTypography = styled(Typography)({
  color: '#1E293B',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 600,
  marginBottom: '24px',
  "@media only screen and (max-width: 816px)": {
    fontSize: '18px',
  },
  "@media only screen and (max-width: 620px)": {
    fontSize: '16px',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const PostedByDetailsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  "@media only screen and (max-width: 816px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 620px)": {
    display: 'block',

  },
});

const PostedByDetailsLeftBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const ImageAvatar = styled(Avatar)({
  width: '56px',
  height: '56px',
});

const PostedByDetailsMiddleBox = styled(Box)({
  marginLeft: '16px',
});

const PostedByNameTypography = styled(Typography)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '26px',
  color: '#1E293B',
  "@media only screen and (max-width: 414px)": {
    fontSize: '14px',
  },
});

const PostedByNameDetailsBox = styled(Box)({
  display: 'flex',
  marginTop: '4px',
  "@media only screen and (max-width: 620px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'flex',
    alignItems: "center",
  },
});

const SchoolNameDot = styled(Box)({
  marginLeft: '12px',
  color: '#64748B',
  fontSize: '14px',
  fontFamily: 'Inter',
  lineHeight: '22px',
  fontWeight: 400,
  "@media only screen and (max-width: 620px)": {
    display: 'none',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'block',
    fontSize: '12px',
    marginLeft: '6px',
  },
})

const AdminTypeTypography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#64748B',
  "@media only screen and (max-width: 414px)": {
    fontSize: '12px',
  },
});

const SchoolNameTypography = styled(Typography)({
  marginLeft: '12px',
  color: '#64748B',
  fontSize: '14px',
  fontFamily: 'Inter',
  lineHeight: '22px',
  fontWeight: 400,
  "@media only screen and (max-width: 620px)": {
    margin: '4px 0 0 0',
  },
  "@media only screen and (max-width: 414px)": {
    fontSize: '12px',
    margin: '0 0 0 6px',
  },
});
const JobDescriptionMainBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  margin: 'auto',
  marginTop: '24px',
  padding: '32px 32px 32px 32px',
  borderRadius: '16px',
  "@media only screen and (max-width: 816px)": {
    padding: '24px 24px 24px 24px',
  },
  "@media only screen and (max-width: 620px)": {
    padding: '16px 16px 16px 16px',
  },
  "@media only screen and (max-width: 414px)": {
    'box-shadow': 'none',
    borderRadius: '0',
    paddingTop: '0',
  },
});

const JobDescTitle = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 600,
  color: '#1E293B',
  "@media only screen and (max-width: 816px)": {
    fontSize: '18px',
    lineHeight: '26px',
  },
  "@media only screen and (max-width: 620px)": {
    fontSize: '16px',
    lineHeight: '26px',
  },
});

const JobDescJobTitleBox = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '25.6px',
  color: '#334155',
  "@media only screen and (max-width: 620px)": {
    fontSize: '14px',
    lineHeight: '22px',
  },
});

const JobDescJobTitle = styled(Typography)({
  display: 'inline-block',
  fontSize: '16px',
  fontFamily: 'Inter',
  lineHeight: '25.6px',
  fontWeight: 400,
  color: '#334155',
  marginLeft: '4px',
  marginTop: '24px',
  "@media only screen and (max-width: 620px)": {
    fontSize: '14px',
    lineHeight: '22px',
    marginTop: '12px',
  },
});

const AboutOrganizationMainBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  margin: 'auto',
  marginTop: '24px',
  padding: '32px 32px 32px 32px',
  borderRadius: '16px',
  "@media only screen and (max-width: 816px)": {
    padding: '24px 24px 24px 24px',
  },
  "@media only screen and (max-width: 620px)": {
    padding: '16px 16px 16px 16px',
  },
  "@media only screen and (max-width: 414px)": {
    'box-shadow': 'none',
    borderRadius: '0',
    marginTop: '0px',
    paddingTop: "0px",
  },
});

const AboutOrganizationTitle = styled(Typography)({
  fontWeight: 600,
  lineHeight: '28px',
  fontFamily: 'Inter',
  color: '#1E293B',
  fontSize: '20px',
  marginBottom: '24px',
  "@media only screen and (max-width: 620px)": {
    lineHeight: '24px',
    marginBottom: '20px',
    fontSize: '18px',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const AboutOrgDetailBox = styled(Box)({
  marginLeft: '16px',
});

const AboutOrgName = styled(Typography)({
  fontSize: '18px',
  fontFamily: 'Inter',
  lineHeight: '26px',
  fontWeight: 500,
  "@media only screen and (max-width: 620px)": {
    fontSize: '16px',
  },
});

const AboutOrgLocation = styled(Typography)({
  marginTop: '4px',
  color: '#64748B',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  fontFamily: 'Inter',
});

const AboutOrg = styled(Typography)({
  fontSize: '16px',
  color: '#334155',
  lineHeight: '25.6px',
  fontFamily: 'Inter',
  fontWeight: 400,
  marginTop: '24px',
  "@media only screen and (max-width: 620px)": {
    fontSize: '14px',
    marginTop: '20px',
  },
});

const DividerForMobile = styled(Divider)({
  display: 'none',
  "@media only screen and (max-width: 414px)": {
    display: 'block',
    margin: '16px 0 16px 0',
  },
});

const DisplayForDesktop = styled(Box)({
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});
export const InputField = styled(TextField)({
  marginBottom: "32px",

  "& .MuiOutlinedInput-input": {
    "&::placeholder": {
      color: '#9A3B8',
      fontWeight: 500
    },
  },
});
// Customizable Area End
