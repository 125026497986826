import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { Typography } from "@material-ui/core";
import { useEffect  , useState} from "react";

export  default function CustomePagination({onchange , total_page, current_page ,total_count }:any){
    const [startResult , setStartResult] = useState<number>(0)
    const [endResult , setEndResult] = useState<number>(0)
    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          justifyContent: 'right',
          width : "100%",
          marginTop: theme.spacing(2),
          alignItems:"center",
          gap:"60px"
        },
        ul: {
          '& .MuiPaginationItem-root': {
            color: 'black',
            '&.Mui-selected': {
              color: '#475569',
              backgroundColor: 'transparent',
              textDecoration: 'none',
              fontWeight : "bolder",
              fontSize: ""
            },
            '&:not(.Mui-selected)': {
                textDecoration: 'underline',
                fontWeight : "lighter"
              }
          },
        },
      }));
      
    const classes = useStyles();
    const countPagination =() =>{
      setStartResult((current_page - 1) * 10 + 1);
      setEndResult(Math.min(current_page * 10, total_count));  
    }
    useEffect(()=>{
      countPagination()
    } , [current_page , total_count])

    return (
        <>
        <div className={classes.root} >
          <Pagination 
            count={total_page}
            onChange={onchange}
            page={current_page}

            classes={{ ul: classes.ul }} 
          />
          <Typography style={{fontSize : "14px" , color:"#64748B"}}>
            {startResult} - {endResult} of {total_count} results
          </Typography>
        </div>
        </>
    )
}


