export const profilePic = require('../assets/dummyProfile.jpeg')
export const addicon = require('../assets/image_add_circle_outline_24px.png')
export const flaficon = require('../assets/Icon.png')
export const universitylogo = require('../assets/ucf_logo.svg.png')
export const calendericon = require('../assets/calendar_month.png')
export const uncheckedR = require('../assets/radiobutton_(1).png')
export const checkedR = require('../assets/radiobutton_.png')
export const upload = require('../assets/view_(1).png')
export const pdfIcon = require('../assets/image_4.png')
export const unchecked = require('../assets/checkbox_(1).png')
export const checked = require('../assets/checkbox_.png')
export const radiounchecked = require('../assets/radiobutton_(1).png')
export const radiochecked = require('../assets/radiobutton_.png')