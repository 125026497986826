// Customizable Area Start

import React from "react";
import {
  TextField,
  Typography,
  Box,
  Button,
  DialogContent,
  styled,
  Snackbar,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiPaper: {
      elevation24: {
        boxShadow: "none",
      },
      rounded: {
        borderRadius: "8px",
      },
    },
  },
});
import "react-toastify/dist/ReactToastify.css";

import InvitefriendsController, { Props } from "./InvitefriendsController";
import { CheckCircle } from "@material-ui/icons";

export default class Invitefriends extends InvitefriendsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      email,
      emailError,
      emailErrorMsg,
      message,
      messageError,
      messageErrorMsg,
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
          <DialogContent>
            <Typography variant="h5" gutterBottom style={webStyles.MainTitle}>
              Invite your friends by email
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              style={webStyles.EmailAddressTitle}
            >
              Email address
            </Typography>
            <EmailInputField
              placeholder="Invite by email address..."
              variant="outlined"
              fullWidth
              margin="none"
              name="email"
              value={email}
              onChange={this.handleEmailChange}
              style={{ marginBottom: "36px" }}
              error={emailError}
              data-test-id="handleEmailChange"
            />
            <ErrorBox>{emailErrorMsg}</ErrorBox>
            <Typography
              variant="h5"
              gutterBottom
              style={webStyles.EmailAddressTitle}
            >
              Your Message
            </Typography>
            <MessageInputField
              placeholder="Put your message here..."
              variant="outlined"
              fullWidth
              margin="none"
              multiline
              rows={4}
              name="message"
              value={message}
              onChange={this.handleMessageChange}
              style={{ marginBottom: "16px" }}
              error={messageError}
              data-test-id="handleMessageChange"
            />
            <ErrorBox style={{marginTop:'-11px'}}>{messageErrorMsg}</ErrorBox>
            <Button
              variant="contained"
              style={webStyles.invtBtn}
              data-test-id="handleSendInvites"
              onClick={this.handleSendInvites}
            >
              Send invites
            </Button>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.isSnackbarOpen}
              autoHideDuration={3000}
              data-test-id="closesnackBar"
              onClose={()=>this.setState({isSnackbarOpen:false})}
            >
              <div style={webStyles.popup as React.CSSProperties}>
                <CheckCircle style={{color:'#34D399'}}/>
                Invite link sent successfully!
              </div>
          </Snackbar>
          </DialogContent>
      </ThemeProvider>
    );
  }
}

const ErrorBox = styled(Box)({
  color: '#DC2626',
    height: '18px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: "18px",
    marginTop:"-32px",
    marginBottom:"10px",
    
  "@media only screen and (max-width: 414px)": {
    marginTop:"-16px"
  },

})

const webStyles = {
  quicklink: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "blue",
    margin: "24px",
  },
  MainTitle: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    marginTop: "14px",
    marginBottom: "24px",
  },
  EmailAddressTitle: {
    color: "#334155",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
  },
  invtBtn:{
    width: "129px",
    height: "56px",
    padding: "8px 8px",
    gap: "8px",
    borderRadius: "8px",
    background: "#395169",
    color: "#fff",
    marginLeft: "auto",
    display: "block" as 'block',
    textTransform: "none" as 'none',
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    marginBottom: "50px",
  },
  popup: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px 10px 10px',
    gap: '8px',
    borderFadius: '8px',
    boxShadow: '0px 6px 15px -3px #00000026',
    fontFamily: 'Inter' as 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#0F172A',
  },
};

const MessageInputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      border: "1px solid #CBD5E1",
    },
    "&:hover fieldset": {
      border: "1px solid #CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #CBD5E1",
    },
  },
  "& .MuiOutlinedInput-multiline": {
    padding: "8.5px 10px",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#94A3B8",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
  },
});

const EmailInputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      border: "1px solid #CBD5E1",
    },
    "& .MuiInputBase-input": {
      padding: "12px 10px",
    },
    "&:hover fieldset": {
      border: "1px solid #CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #CBD5E1",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#94A3B8",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
  },
});
const CopyInputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      border: "1px solid #CBD5E1",
    },
    "& .MuiInputBase-input": {
      padding: "12px 10px",
      color: "#94A3B8",
    },
    "&:hover fieldset": {
      border: "1px solid #CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #CBD5E1",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#94A3B8",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
  },
});

// Customizable Area End
