import React, { Component } from "react";

import {
    Box,
    Button,
    Typography, Menu, MenuItem
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import storage from "../../framework/src/StorageProvider"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu.web'
export interface Props {
    navigation: any;
    id: string;
    NaviagationPage: string;
}

interface NavbarState {
    activeOption: string;
    token: string;
    anchorEl: null | HTMLElement;
}

export default class Navbar extends Component<Props, NavbarState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            activeOption: "Home",
            token: "",
            anchorEl: null
        };
    }
    render() {
        
        return (
           <NavigationMenu navigation={this.props.navigation} id={""} NaviagationPage={this.props.NaviagationPage}/>
        );
    }
}
