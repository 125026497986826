export const edit = require("../assets/edit.png");
export const checked = require("../assets/checkbox_.png");
export const unchecked = require("../assets/checkbox_(1).png");
export const education = require("../assets/image_school.png");
export const location = require("../assets/image_location_24px.png");
export const experience = require("../assets/image_work_outline_24px.png");
export const school = require("../assets/1.png");
export const admin = require("../assets/image_ 1.png");
export const logo = require("../assets/PCC_Tree.png")
export const credit = require("../assets/textlabel_.png")
export const noData = require("../assets/Usability testing-pana (1) 1.png")