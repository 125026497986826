Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.timeout = 2000
exports.bottomLine = "Lorem ipsum dolor sit amet, consectetur adispiscing.\nTurpis mauris euismod posuere scelerisque"
exports.percentageText = "20%"
exports.webLogoAlt = 'web_logo';
exports.splashLoaderAlt = 'splash_loader';
exports.closeIconAlt = 'close_icon';
exports.noInternetIconAlt = 'no_internet_icon';
exports.oopsNotInternet = 'Oops! No internet connection';
exports.pleaseTryAgain = 'Make sure wi-fi or cellular data is turned on and then please try again.';
exports.btnTryAgain = 'Try again';
// Customizable Area End