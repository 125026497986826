// Customizable Area Start
import React from "react";
import JobListingDetailsController,{JobWorkingDay} from "./JobListingDetailsController";
import { Avatar, Box, Dialog, Divider, Snackbar, Typography ,Collapse ,Grid} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { backIcon, applied_checkmark_circle, description, educationLogo, experienceLogo, image, location, locationLogo, people2, rightArrow, savedStarIcon, savedStarMobileIcon, tickIcon, unSavedStarIcon, unSavedStarMobileIcon, profileDummy, pdfIcon , profilePic } from "./assets";
import Footer from "../../../components/src/Footer.web";
import Navbar from "../../../components/src/Navbar.web";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Invitefriends from "../../invitefriends/src/Invitefriends.web"
import { Certifications } from "./SavedJobController";
export const configJSON = require("./config");
// Customizable Area End

class JobListingDetails extends JobListingDetailsController {
// Customizable Area Start

renderStartEndTime = (item: JobWorkingDay) => {
  return (
      <>
          {!item?.full_day && (
              <>
                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                      <Typography style={{ ...webStyleNew.preTime }}>Start Time:</Typography>
                      <Typography variant="body2" style={{ color: "#395169", fontFamily: "Inter", fontSize: "14px", fontWeight: 600, lineHeight: "24px" }}>
                          {item.start_time}
                      </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                      <Typography style={{ ...webStyleNew.preTime }}>End Time:</Typography>
                      <Typography variant="body2" style={{ color: "#395169", fontFamily: "Inter", fontSize: "14px", fontWeight: 600, lineHeight: "24px" }}>
                          {item.end_time}
                      </Typography>
                  </Box>
              </>
          )}
      </>
  );
};

renderTeacherBox = (isTeacherSaved:boolean ,buttonText:string ,hasTraveldistance?:boolean|number) =>{
  const {teacherDetails} = this.state
  return (
    <>
    {renderRole(this.state.role) && <MainBoxTeacher>
            <ProfileHeading>Profile</ProfileHeading>
            <ProfileInfo>
              <Grid container spacing={2} >
                <DeskCustomeGrid item xs={12} sm={2}>
                  <img
                    src={teacherDetails?.profile_image?.url || profilePic}
                    style={webStyleNew.profilepic}
                    data-test-id="handleImageClick1"
                  />
                </DeskCustomeGrid>
                <DeskCustomeGrid item xs={12} sm={12} md={5} style={{marginLeft:"10px"}}>
                  <Box>
                    <Typography style={webStyleNew.name}>{teacherDetails?.first_name} {teacherDetails?.last_name}</Typography>
                    <Typography style={webStyleNew.email}>{teacherDetails?.email}</Typography>
                  </Box>
                  <DeskCustomeGrid container spacing={1} style={{ marginTop: "30px", display: "flex" }}>
                    <DeskCustomeGrid item >
                      <Box  style={{ ...webStyleNew.countingBox, border: "none" }}>
                      <SendMessageButtonBox data-test-id="getChatListTeacher" style={{width:"auto",textWrap:"nowrap" ,textAlign:"left" , marginRight:"15px"}}  onClick={()=>this.getTeacherListTeacher(`${teacherDetails.first_name} ${teacherDetails.last_name}` , teacherDetails.email)}>
                          <button style={webStyle.sendMessageBtn} >{configJSON.sendMessage}</button>
                        </SendMessageButtonBox>
                        <Box
                          data-test-id='handleSaveUnsaveTeacher'
                          style={{ ...webStyle.saveJobBox, textWrap: "nowrap",cursor:"pointer" }}
                          onClick={() => this.handleSaveUnsaveTeacher(!this.isTeacherSaved(teacherDetails.id), teacherDetails.id)}>
                          <img
                            style={webStyle.savedIcon}
                            data-test-id='isTeacherSaved'
                            src={this.getSavedIconSrc(isTeacherSaved)}
                            alt={this.getSavedIconAlt(isTeacherSaved)}
                          />
                          {buttonText}
                        </Box>
                      </Box>
                    </DeskCustomeGrid>
                  </DeskCustomeGrid>
                </DeskCustomeGrid>
                <MobileCustomGrid style={{ width: "100%", display: "flex", padding: "8px" }}>
                  <Box style={{ display: "flex", marginRight: "20px" }}>
                    <img
                        src={teacherDetails?.profile_image?.url || profileDummy}
                      style={webStyleNew.profilepicMob}
                      data-test-id="handleImageClick"
                    />
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <Typography style={webStyleNew.nameMob}>{teacherDetails?.first_name} {teacherDetails?.last_name}</Typography>
                    <Typography style={webStyleNew.emailMob}>{teacherDetails?.email}</Typography>
                  </Box>
                </MobileCustomGrid>
                <MobileCustomGrid item xs={12} sm={12} md={5}>
                  <MobileCustomGrid container className="profileChatBox" spacing={1} style={{ display: "flex", justifyContent: "center" }}>
                    <MobileCustomGrid item style={{ width: "100%" }}>
                      <CustomeBox className="ChatBox" style={{...webStyleNew.countingBox , border:"none" , flexWrap:"wrap"}}>
                        <SendMessageButtonBox data-test-id="getChatListTeacher" onClick={()=>this.getTeacherListTeacher(`${teacherDetails.first_name} ${teacherDetails.last_name}` , teacherDetails.email)}>
                          <button  style={{...webStyle.sendMessageBtn , marginRight:"15px"}} >{configJSON.sendMessage}</button>
                        </SendMessageButtonBox>
                        <CustomBox data-test-id='btnSaveUnsave' style={webStyle.saveJobBox} >
                          <img style={webStyle.savedIcon} src={this.getSavedIconSrc(isTeacherSaved)} alt="" />
                          {buttonText}
                        </CustomBox>
                      </CustomeBox>
                    </MobileCustomGrid>
                  </MobileCustomGrid>
                </MobileCustomGrid>
              </Grid>
            </ProfileInfo>
            <Grid container  spacing={2}>
              <QuickLinks item xs={12} sm={4}>
                <Box style={webStyleNew.profileInfo}>
                  <Typography style={webStyleNew.quicklink}>Quick Links</Typography>
                  <Typography data-test-id="quicklink" style={webStyleNew.links} onClick={this.personalLink}>
                    Personal Information
                  </Typography>
                  <Typography data-test-id="quicklink" style={webStyleNew.links} onClick={this.resumeLink}>
                    Resume
                  </Typography>
                  <Typography data-test-id="quicklink" style={webStyleNew.links} onClick={this.certificateLink}>
                    Certifications
                  </Typography>
                  <Typography data-test-id="quicklink" style={webStyleNew.links} onClick={this.preferLink}>
                    Job Preferences
                  </Typography>
                  <Typography data-test-id="quicklink" style={webStyleNew.links} onClick={this.availabilityLink}>
                    Availability
                  </Typography>
                </Box>
              </QuickLinks>
              <Grid item xs={12} sm={12} md={8}>
                <Box style={webStyleNew.profileInfo}>
                  <div ref={this.personalInfoRef} style={webStyleNew.optionLabel} data-test-id="handleToggle" onClick={this.handlePersonalInfo}>
                    <Typography style={webStyleNew.optionLabelText}>Personal Information</Typography>
                    <ArrowForwardIosIcon
                    data-test-id='ArrowForwardIosIcon'
                      style={{
                        ...webStyleNew.expandArrow,
                        transform: this.state.openPersonalInfo ? 'rotate(90deg)' : 'rotate(0deg)',
                      }}
                    />
                  </div>
                  <Collapse in={this.state.openPersonalInfo} style={{ marginTop: -14, marginBottom: 16 }}>
                    <Box style={{ borderBottom: "1px solid #F1F5F9", paddingBottom: 16 }}>
                      <Box style={webStyleNew.space}>
                        <Typography style={webStyleNew.editType}>
                          Account
                        </Typography>
                      </Box>
                      <>
                        <Box style={webStyleNew.space}>
                          <Typography style={webStyleNew.editHeading}>First Name</Typography>
                          <Typography style={{ ...webStyleNew.editHeading, fontWeight: 700 }}>{teacherDetails?.first_name}</Typography>
                        </Box>
                        <Box style={webStyleNew.space}>
                          <Typography style={webStyleNew.editHeading}>Last Name</Typography>
                          <Typography style={{ ...webStyleNew.editHeading, fontWeight: 700 }}>{teacherDetails?.last_name}</Typography>
                        </Box>
                        <Box style={webStyleNew.space}>
                          <Typography style={webStyleNew.editHeading}>Phone</Typography>
                          <Typography style={{ ...webStyleNew.editHeading, fontWeight: 700 }}>{teacherDetails?.full_phone_number}</Typography>
                        </Box>
                        <Box style={webStyleNew.space}>
                          <Typography style={webStyleNew.editHeading}>Email</Typography>
                          <Typography style={{ ...webStyleNew.editHeading, fontWeight: 700 }}>{teacherDetails?.email}</Typography>
                        </Box>
                      </>
                    </Box>
                    <Box style={{ borderBottom: "1px solid #F1F5F9", paddingBottom: 16 }}>
                      <Box style={webStyleNew.space}>
                        <Typography style={webStyleNew.editType}>
                          Address
                        </Typography>
                      </Box>

                      <>
                        <Box style={webStyleNew.space}>
                          <Typography style={webStyleNew.editHeading}>Street</Typography>
                          <Typography style={{ ...webStyleNew.editHeading, fontWeight: 700 }}>{teacherDetails?.street_address}</Typography>
                        </Box>
                        <Box style={webStyleNew.space}>
                          <Typography style={webStyleNew.editHeading}>City</Typography>
                          <Typography style={{ ...webStyleNew.editHeading, fontWeight: 700 }}>{teacherDetails?.city}</Typography>
                        </Box>
                        <Box style={webStyleNew.space}>
                          <Typography style={webStyleNew.editHeading}>State</Typography>
                          <Typography style={{ ...webStyleNew.editHeading, fontWeight: 700 }}>{teacherDetails?.state}</Typography>
                        </Box>
                        <Box style={webStyleNew.space}>
                          <Typography style={webStyleNew.editHeading}>Country</Typography>
                          <Typography style={{ ...webStyleNew.editHeading, fontWeight: 700 }}>{teacherDetails?.country}</Typography>
                        </Box>
                        <Box style={webStyleNew.space}>
                          <Typography style={webStyleNew.editHeading}>Zip Code</Typography>
                          <Typography style={{ ...webStyleNew.editHeading, fontWeight: 700 }}>{teacherDetails?.zip_code}</Typography>
                        </Box>
                      </>
                    </Box>
                  </Collapse>

                  <div ref={this.resumeRef} style={webStyleNew.optionLabel} data-test-id="handleToggle" onClick={this.handleResumeToggle} >
                    <Typography style={webStyleNew.optionLabelText}>Resume</Typography>
                    <ArrowForwardIosIcon
                    data-test-id='ArrowForwardIosIcon'
                      style={{
                        ...webStyleNew.expandArrow,
                        transform: this.state.resumeOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                      }} />
                  </div>
                  <Collapse in={this.state.resumeOpen} style={{ marginTop: -14, marginBottom: 16 }}>
                    <Box style={{ borderBottom: "1px solid #F1F5F9", paddingBottom: 16 }}>


                      <Box style={{...webStyleNew.space , flexDirection:"column"}}>
                        <Typography style={webStyleNew.editHeading}>Introduce Yourself</Typography>
                        <Typography style={webStyleNew.editHeading} data-test-id="about">{!teacherDetails.about?.description ? 'No introduction found' :  teacherDetails.about?.description}</Typography>
                      </Box>
                      <Box style={{ ...webStyleNew.space, flexDirection: "column" }}>
                        <Typography style={webStyleNew.editHeading}>Education</Typography>
                        <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" , flexDirection:"column" }} data-test-id="educontainer">
                          {this.eduSbmitted()}
                        </Box>
                      </Box>

                      <Box style={{ ...webStyleNew.space, flexDirection: "column" }}>
                        <Typography style={webStyleNew.editHeading}>Work Experience</Typography>
                        <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" ,flexDirection:"column" }}>
                          {this.firstWorkExpBox()}
                        </Box>
                      </Box>

                      <Box style={{ ...webStyleNew.space, flexDirection: "column" }}>
                        <Typography style={webStyleNew.editHeading}>Skills</Typography>

                        {teacherDetails.skills ? teacherDetails?.skills?.map((item: any) => (
                          <Box style={webStyleNew.skillBox}>
                            <Typography style={{ padding: '15px', borderBottom: '1px solid #F1F5F9' }}>{item.name}</Typography>
                          </Box>
                        )) : (
                          <Box style={webStyleNew.skillBox}>
                            <Typography style={{ padding: '15px', borderBottom: '1px solid #F1F5F9' }}>No Data Found</Typography>
                          </Box>
                        )
                        }
                      </Box>
                      <Box style={{ ...webStyleNew.space, flexDirection: "column" }}>
                        <Typography style={webStyleNew.editHeading}>Teaching Interests</Typography>
                        <Box style={webStyleNew.skillBox}>
                          {teacherDetails.teaching_interests ? teacherDetails?.teaching_interests?.map((item: any) => (
                            <Box style={webStyleNew.skillBox}>
                              <Typography style={{ padding: '15px', borderBottom: '1px solid #F1F5F9' }}>{item.name}</Typography>
                            </Box>
                          )) : (
                            <Box style={webStyleNew.skillBox}>
                              <Typography style={{ padding: '15px', borderBottom: '1px solid #F1F5F9' }}>No Data Found</Typography>
                            </Box>
                          )
                          }
                        </Box>
                      </Box>
                    </Box>
                  </Collapse>

                  <div ref={this.certificationRef} style={webStyleNew.optionLabel} data-test-id="handleToggle" onClick={this.handleCertificationToggle} >
                    <Typography style={webStyleNew.optionLabelText}>Certifications</Typography>
                    <ArrowForwardIosIcon
                    data-test-id='ArrowForwardIosIcon'
                      style={{
                        ...webStyleNew.expandArrow,
                        transform: this.state.certificationOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                      }} />
                  </div>
                  <Collapse in={this.state.certificationOpen} style={{ marginTop: -14, marginBottom: 16 }}>
                    <Box style={{ borderBottom: "1px solid #F1F5F9", paddingBottom: 16 }}>
                    {this.renderCertificates()}
                      {this.renderNocertificateFound(teacherDetails.certifications)}
                    </Box>
                  </Collapse>

                  <div ref={this.preferenceRef} style={webStyleNew.optionLabel} data-test-id="handleToggle" onClick={this.handlePreferenceToggle} >
                    <Typography style={webStyleNew.optionLabelText}>Job Preferences</Typography>
                    <ArrowForwardIosIcon
                    data-test-id="ArrowForwardIosIcon"
                      style={{
                        ...webStyleNew.expandArrow,
                        transform: this.state.preferenceOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                      }} />
                  </div>
                  <Collapse in={this.state.preferenceOpen} style={{ marginTop: -14, marginBottom: 16 }}>
                    <Box style={{ borderBottom: "1px solid #F1F5F9", paddingBottom: 16 }}>
                      <Box style={{ position: 'relative' }}>
                        <Box style={webStyleNew.jobprefeBox}>
                          <Typography style={webStyleNew.preferTitle}>Modalities</Typography>
                          {this.renderModalities()}
                        </Box>

                        <Box style={webStyleNew.jobprefeBox}>
                          <Typography style={webStyleNew.preferTitle}>Age Groups</Typography>
                          {teacherDetails?.preferred_age_group_name?.length > 0 ? (
                            <Box>
                              {teacherDetails.preferred_age_group_name.map(
                                (ageGroup: any, index: number) => (
                                  <Typography
                                    style={{
                                      ...webStyleNew.prefOption,
                                      borderBottom: '1px solid #F1F5F9',
                                      padding: '10px 16px',
                                      alignItems: 'center',
                                    }}
                                    key={index}
                                  >
                                    {ageGroup}
                                  </Typography>
                                )
                              )}
                            </Box>
                          ) : (
                            <Typography style={{
                              ...webStyleNew.prefOption,
                              borderBottom: '1px solid #F1F5F9',
                              padding: '10px 16px',
                              alignItems: 'center',
                              fontSize: "12px"
                            }}>No age groups available</Typography>
                          )}
                        </Box>

                        <Box style={webStyleNew.jobprefeBox}>
                          <Typography style={webStyleNew.preferTitle}>Working Days</Typography>
                          {teacherDetails?.preferred_working_days_name?.length > 0 ? (
                            <Box>
                              {teacherDetails.preferred_working_days_name.map(
                                (days: any, index: number) => (
                                  <Typography
                                    style={{
                                      ...webStyleNew.prefOption,
                                      borderBottom: '1px solid #F1F5F9',
                                      padding: '10px 16px',
                                      alignItems: 'center',
                                    }}
                                    key={index}
                                  >
                                    {days}
                                  </Typography>
                                )
                              )}
                            </Box>
                          ) : (
                            <Typography style={{
                              ...webStyleNew.prefOption,
                              borderBottom: '1px solid #F1F5F9',
                              padding: '10px 16px',
                              alignItems: 'center',
                              fontSize: "12px"
                            }}>No working days available</Typography>
                          )}
                        </Box>

                        <Box style={webStyleNew.jobprefeBox}>
                          <Typography style={webStyleNew.preferTitle}>Time Shift</Typography>
                          {teacherDetails?.preferred_time_shifts_name?.length > 0 ? (
                            <Typography
                              style={{
                                ...webStyleNew.prefOption,
                                borderBottom: '1px solid #F1F5F9',
                                padding: '10px 16px',
                                alignItems: 'center',
                              }}
                            >
                              {teacherDetails.preferred_time_shifts_name[0]}
                            </Typography>
                          ) : (
                            <Typography style={{
                              ...webStyleNew.prefOption,
                              borderBottom: '1px solid #F1F5F9',
                              padding: '10px 16px',
                              alignItems: 'center',
                              fontSize: "12px"
                            }}>No time shift available</Typography>
                          )}
                        </Box>

                        <Box style={webStyleNew.jobprefeBox}>
                          <Typography style={webStyleNew.preferTitle}>Nature of Job</Typography>
                          {teacherDetails?.preferred_nature_of_jobs_name?.length > 0 ? (
                            <Typography
                              style={{
                                ...webStyleNew.prefOption,
                                borderBottom: '1px solid #F1F5F9',
                                padding: '10px 16px',
                                alignItems: 'center',
                              }}
                            >
                              {teacherDetails.preferred_nature_of_jobs_name[0]}
                            </Typography>
                          ) : (
                            <Typography style={{
                              ...webStyleNew.prefOption,
                              borderBottom: '1px solid #F1F5F9',
                              padding: '10px 16px',
                              alignItems: 'center',
                              fontSize: "12px"
                            }}>No nature of job available</Typography>
                          )}
                        </Box>

                        <Box style={webStyleNew.jobprefeBox}>
                          <Typography style={webStyleNew.preferTitle}>Travel Distance</Typography>
                          {hasTraveldistance
                            ? this.renderTravelDistance(teacherDetails?.preferred_travel_distance)
                            : this.renderNoModalityMessage('No travel distance available')}
                        </Box>
                      </Box>

                    </Box>
                  </Collapse>
                  <div ref={this.availabilityRef} style={webStyleNew.optionLabel} data-test-id="handleToggle" onClick={this.handleAvailabilityToggle} >
                    <Typography style={webStyleNew.optionLabelText}>Availability</Typography>
                    <ArrowForwardIosIcon
                      style={{
                        ...webStyleNew.expandArrow,
                        transform: this.state.availabilityOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                      }} />
                  </div>
                  
                  <Collapse in={this.state.availabilityOpen} style={{ marginTop: -14, marginBottom: 16 }}>
                    <Typography style={{...webStyleNew.prefOption}}>
                    Preferred Time
                    </Typography>
                    {this.AccountUI()}
                  </Collapse>
                </Box> </Grid>
              </Grid>
          </MainBoxTeacher>}
    
    </>
  )
}

eduSbmitted = () => {
  const {teacherDetails } = this.state
  return (
    <>
      {teacherDetails?.qualifications?.map((education: any, index: any) => (
        <Box key={index} style={{ width: "100%" }} data-test-id="eduSbmitted">
          <Box
            style={{
              width: "100%",
              height: 221,
              border: '0.5px solid #E2E8F0',
              borderRadius: 15.2,
              marginTop: 30,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <Box>
              <Typography style={webStyleNew.schoolname}>{education.university}</Typography>
              <Typography style={webStyleNew.course}>{education.course} in {education.specialization}</Typography>
              <Typography style={webStyleNew.duration}>
                {education.from_date} - {education.to_date}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
}

formatTime = (time: string) => {
  if (!time) return ''; 
  const [hours, minutes] = time.split(':');
  const period = parseInt(hours) >= 12 ? 'PM' : 'AM'; 
  const formattedHours = parseInt(hours) % 12 || 12; 
  return `${formattedHours}:${minutes} ${period}`; 
};


AccountUI = () => {

  return (
    <Box >
      {this.state.availabilty?.length > 0 ? (
        <Box >
          {this.state.availabilty.map((day: any) => (
            <>
              <Box style={{ ...webStyleNew.skillBox }}>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                  <Typography style={{ ...webStyleNew.prefOption, padding: "10px 16px" }}>
                    {day?.day_name}
                  </Typography>
                  <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px', padding: "10px 16px" }}>
                    {day?.full_day ? 'Full Day' : ''}
                  </Typography>
                </Box>

                {!day?.full_day &&
                  <>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                      <Typography style={{ ...webStyleNew.prefOption, padding: "10px 16px" }}>
                        Start Time:
                      </Typography>
                      <Typography variant="body2" style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px', padding: "10px 16px" }}>
                        {this.formatTime(day.start_time)}
                      </Typography>
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                      <Typography style={{ ...webStyleNew.prefOption, padding: "10px 16px" }}>
                        End Time:
                      </Typography>
                      <Typography variant="body2" style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px', padding: "10px 16px" }}>
                        {this.formatTime(day.end_time)}
                      </Typography>
                    </Box>
                  </>
                }
              </Box>
            </>
          ))}
        </Box>
      ) : (
        <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '12px', fontWeight: 600, lineHeight: '24px' }}>No working days specified.</Typography>
      )}
    </Box>
  );
};


firstWorkExpBox = () => {
  const {teacherDetails } = this.state

  return (
    <>
      {teacherDetails?.work_experiences && teacherDetails.work_experiences.length > 0 ? (
        teacherDetails.work_experiences.map((work: any, index: number) => (
          <Box key={index} style={webStyleNew.showBoxData}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={webStyleNew.showBoxTtile}>{work.position}</Typography>
            </Box>
            <Typography style={webStyleNew.organisationTxt}>{work.organization}</Typography>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography style={webStyleNew.organisationTxt}>{work.start_date} - {work.end_date}</Typography>
              <Typography style={webStyleNew.expTxt}>{work.experience} year</Typography>
            </Box>
            <Typography style={webStyleNew.repotitle}>Job responsibilities</Typography>
            <Typography style={webStyleNew.jobRespon}>{work.job_responsibilities}</Typography>
          </Box>
        ))
      ) : (
        <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '12px', fontWeight: 600, lineHeight: '24px' }}>No work experience found</Typography>
      )}
    </>
  )
}

isTeacherSaved = (id:number) => {
  return this.state.savedTeachers.includes(id);
};

isJobSaved = (id:number) => {
  return this.state.savedJobs.includes(id);
};


renderModalityItems(modalities: string[]): JSX.Element {
  return (
    <Box>
      {modalities.map((providerType: string, index: number) => (
        <Typography
          style={{
            ...webStyleNew.prefOption,
            borderBottom: '1px solid #F1F5F9',
            padding: '10px 16px',
            alignItems: 'center',
          }}
          key={index}
        >
          {providerType}
        </Typography>
      ))}
    </Box>
  );
}

  renderModalities = () => {
    const modalities = this.state.teacherDetails?.preferred_modality_name;
    const hasModalities = modalities && modalities.length > 0;
    return (
      hasModalities
        ? this.renderModalityItems(modalities)
        : this.renderNoModalityMessage('No travel Modalities available')
    )
  }

renderTravelDistance(travel_distance: number): JSX.Element {
  return (
    <Box>
        <Typography
          style={{
            ...webStyleNew.prefOption,
            borderBottom: '1px solid #F1F5F9',
            padding: '10px 16px',
            alignItems: 'center',
          }}
        >
          {travel_distance}
        </Typography>
    </Box>
  );
}

renderNoModalityMessage(message:string): JSX.Element {
  return (
    <Typography
      style={{
        ...webStyleNew.prefOption,
        borderBottom: '1px solid #F1F5F9',
        padding: '10px 16px',
        alignItems: 'center',
        fontSize: "12px"
      }}
    >
      {message}
    </Typography>
  );
}

getButtonText() {
  const teacherId = this.state.teacherDetails?.id;
  if (!teacherId) {
    return configJSON.saveTeacher;
  }
  return this.isTeacherSaved(teacherId) ? configJSON.savedTeacher : configJSON.saveTeacher;
}

getJobButtonText() {
  const jobId = this.state.cardId;
  if (!jobId) {
    return configJSON.saveJobBox;
  }
  return this.isJobSaved(jobId) ? configJSON.jobPostSaved : configJSON.saveJobPost;
}

getIsTeacherSaved(teacherId: number): boolean {
  return this.isTeacherSaved(teacherId);
}

getIsJobSaved(cardId: number): boolean {
  return this.isJobSaved(cardId);
}

getSavedIconSrc(isSaved: boolean): string {
  return isSaved ? savedStarIcon : unSavedStarIcon;
}

getSavedIconAlt(isSaved: boolean): string {
  return isSaved ? 'Saved teacher icon' : 'Unsaved teacher icon';
}

checkCertificate = (certifications:Certifications)=>{
  const isCertificationsEmpty:boolean = Object.values(certifications).every(
    (attribute) => Array.isArray(attribute) && attribute.length === 0
  );
  return isCertificationsEmpty
}
  renderNocertificateFound(certifications: Certifications) {
    return (
      
        this.checkCertificate(certifications) && (
          <Typography
            style={{
              ...webStyleNew.prefOption,
              borderBottom: '1px solid #F1F5F9',
              padding: '10px 16px',
              alignItems: 'center',
              fontSize: "12px"
            }}
          >No Certificates available</Typography>
        )
      
    )
  }
renderCertificates(){
  return (
    !this.checkCertificate(this.state.teacherDetails?.certifications) && (
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {this.state.teacherDetails?.certifications?.certificates?.map((certificate: any, index: number) => (
          <>
            <Box key={`certificate-${certificate.id}`} style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
              <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
                {certificate?.files[0]?.type === 'application' ? (
                  <>
                  <img src={pdfIcon} style={{ width: '40px', height: '40px' }} alt="PDF Icon" />
                  </>
                  
                ) : (
                  <img src={certificate?.files[0]?.url} alt="Uploaded file" style={{ width: '40px', height: '40px' }} />
                )}
                <Box>
                  <Typography style={webStyleNew.filename}>{certificate?.name}</Typography>
                  <Typography style={webStyleNew.fileSize}>{certificate?.files[0]?.filename}</Typography>
                </Box>
              </Box>
              <Box data-test-id={`anchorElCertificate-${index}`} onClick={()=>this.handleViewClick(certificate?.files[0]?.url as string)} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' , color:"#395169" }}>
              <VisibilityIcon />
              </Box>
            </Box>
          </>
        ))}
  
        {this.state.teacherDetails?.certifications?.upload_resumes?.map((resume: any, index: number) => (
          <>
            <Box key={`upload_resume-${index}`} style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
              <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
                <img src={resume?.url} alt="Uploaded Resume" style={{ width: '40px', height: '40px' }} />
                <Box>
                  <Typography style={webStyleNew.filename}>{resume?.filename}</Typography>
                </Box>
              </Box>
              <Box data-test-id={`anchorElCertificate-${index}`} onClick={()=>this.handleViewClick(resume?.url as string)} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' , color:"#395169" }}>
              <VisibilityIcon />
              </Box>
            </Box>
          </>
        ))}
  
        {this.state.teacherDetails?.certifications?.letters_of_interests?.map((letter: any, index: number) => (
          <>
            <Box key={`letters_of_interests-${index}`} style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
              <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
                <img src={letter?.url} alt="Uploaded Letter" style={{ width: '40px', height: '40px' }} />
                <Box>
                  <Typography style={webStyleNew.filename}>{letter?.filename}</Typography>
                </Box>
              </Box>
              <Box data-test-id={`anchorElCertificate-${index}`} onClick={()=>this.handleViewClick(letter?.url as string)} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' , color:"#395169" }}>
              <VisibilityIcon />
              </Box>
            </Box>
          </>
        ))}
  
        {this.state.teacherDetails?.certifications?.credentials?.map((credential: any, index: number) => (
          <>
  
            <Box key={`credentials-${index}`} style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
              <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
                <img src={credential?.url} alt="Uploaded Credential" style={{ width: '40px', height: '40px' }} />
                <Box>
                  <Typography style={webStyleNew.filename}>{credential?.filename}</Typography>
                </Box>
              </Box>
              <Box data-test-id={`anchorElCertificate-${index}`} onClick={()=>this.handleViewClick(credential?.url as string)} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' , color:"#395169" }}>
              <VisibilityIcon />
              </Box>
            </Box>
          </>
        ))}
  
        {this.state.teacherDetails?.certifications?.transcripts.map((transcript: any, index: number) => (
          <>
            <Box key={`transcripts-${index}`} style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
              <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
                <img src={transcript?.url} alt="Uploaded Transcript" style={{ width: '40px', height: '40px' }} />
                <Box>
                  <Typography style={webStyleNew.filename}>{transcript?.filename}</Typography>
                </Box>
              </Box>
              <Box data-test-id={`anchorElCertificate-${index}`} onClick={()=>this.handleViewClick(transcript?.url as string)} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' , color:"#395169" }}>
              <VisibilityIcon />
              </Box>
            </Box>
          </>
        ))}
  
        {this.state.teacherDetails?.certifications?.note_pages.map((note: any, index: number) => (
          <>
            <Box key={`note_pages-${index}`} style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
              <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
                <img src={note?.url} alt="Uploaded Note" style={{ width: '40px', height: '40px' }} />
                <Box>
                  <Typography style={webStyleNew.filename}>{note?.filename}</Typography>
                </Box>
              </Box>
              <Box data-test-id={`anchorElCertificate-${index}`} onClick={()=>this.handleViewClick(note?.url as string)} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' , color:"#395169" }}>
              <VisibilityIcon />
              </Box>
            </Box>
  
          </>
        ))}
  
        {this.state.teacherDetails?.certifications?.letter_of_reference_emails?.map((reference: any, index: number) => (
          <>
  
            <Box key={`letter_of_reference_emails-${index}`} style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
              <Box style={{ display: 'flex', gap: '10px', padding: '16px' }}>
                <img src={reference?.url} alt="Uploaded Reference" style={{ width: '40px', height: '40px' }} />
                <Box>
                  <Typography style={webStyleNew.filename}>{reference?.filename}</Typography>
                </Box>
              </Box>
              <Box data-test-id={`anchorElCertificate-${index}`} onClick={()=>this.handleViewClick(reference?.url as string)} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' , color:"#395169" }}>
              <VisibilityIcon />
              </Box>
            </Box>
           
          </>
        ))}
      </Box>)
  )
}
  


  render() {
    const buttonText = this.getButtonText()
    const teacherId = this.state.teacherDetails?.id;
    const isTeacherSaved = this.getIsTeacherSaved(teacherId);
    const isJobSaved = this.getIsJobSaved(this.state.cardId);
    const distance= this.state.teacherDetails.preferred_travel_distance
    const hasTraveldistance =  distance &&  distance != 0
    const {details}=this.state
    const jobDetails = {
      job_title: 'Assistant Teacher',
      job_summary: "We are seeking a dedicated and enthusiastic Assistant Teacher to join our team. The Assistant Teacher will work closely with the lead teacher to support the educational and developmental needs of students. Responsibilities include assisting in planning and implementing lesson plans, providing individualized attention to students, assisting with classroom management, and fostering a positive and inclusive learning environment. The ideal candidate will have a passion for working with children, excellent communication skills, and the ability to collaborate effectively with colleagues.",
      key_responsibilities: [
        'Assist in planning and implementing engaging lesson plans that align with curriculum objectives.',
        'Provide individualized support to students to enhance their learning experience',
        'Assist with classroom management, including maintaining a safe and orderly environment.',
        'Collaborate with lead teacher and other staff members to ensure effective delivery of instruction.',
        'Assist in planning and implementing engaging lesson plans that align with curriculum objectives.',
        'Provide individualized support to students to enhance their learning experience',
        'Assist with classroom management, including maintaining a safe and orderly environment.',
        'Provide individualized support to students to enhance their learning experience',
      ],
      qualifications: [
        "Bachelor's degree in Education or related field.",
        "Previous experience working with children in an educational setting preferred.",
        "Strong interpersonal skills and the ability to work effectively as part of a team.",
        "Excellent communication skills, both verbal and written.",
        "Patient, compassionate, and supportive attitude towards students.",
        "Ability to adapt to the needs of diverse learners.",
        "Knowledge of educational principles and best practices.",
      ],
      benefits: [
        "Competitive salary",
        "Health insurance",
        "Professional development opportunities",
        "Paid time off",
        "Positive and supportive work environment",
      ],
      company_photo_url: image,
      company_name: 'Riverdale Elementary',
      company_about: 'Riverdale Elementary School is a vibrant and inclusive learning community dedicated to providing high-quality education to students from diverse backgrounds. Our school is committed to fostering a nurturing and supportive environment where students thrive academically, socially, and emotionally. With a team of dedicated educators, innovative programs, and a focus on student success, Riverdale Elementary School is proud to be a cornerstone of education excellence in our area.',
      required_experience: '0-1 Year',
      education_level: 'Graduation',
      location: 'Riverdale, New York, United States',
      posted_at: '1 hours ago',
      posted_by_name: 'Sandra Thompson',
      posted_by_image_url: people2,
      posted_by_admin_name: 'School Admin',
      posted_by_school_name: 'Riverdale Elementary School',
      isSaved: false,
      isApplied: false,
    }
    const jobsData = [
      {
        description: "We are seeking a dedicated and enthusiastic Assistant Teacher to join our team. The As...", place: "Riverdale, New York, United States",
        postedAt: "1 hour ago",title: "Assistant Teacher",graduation: "Graduation", year: "1-2 year",  postedBy: "Sandra Thompson . School admin",schoolname: "Riverdale Elementary", 
      },
      {
         postedAt: "2 hour ago", year: "1-2 year",  schoolname: "Lakeside High School",graduation: "Graduation", 
         title: "Special Education Assistant", postedBy: "David Rodrigues . School staff",description: "We are seeking a dedicated and enthusiastic Assistant Teacher to join our team. The As...", place: "lakeside City, California, United States"
      },
      {
        postedAt: "1 hour ago",title: "Assistant Teacher",graduation: "Graduation",description: "We are seeking a dedicated and enthusiastic Assistant Teacher to join our team. The As...",  year: "1-2 year",  
         place: "Riverdale, New York, United States", postedBy: "Sandra Thompson . School admin",schoolname: "Riverdale Elementary", 
      },
    ]

    const { teacherDetails } = this.state
    const renderJobsData = () => {
      return (
        <>
          {this.state.cardsList?.map((item) => {
            return (
              <JobDataMainBox>
                <FlexSpaceBetweenBox>
                  <CardTitleTypo>{item.attributes.job_title}</CardTitleTypo>
                  <DesktopBox style={webStyle.postedAtBox}>
                      {configJSON.postedTxt}{configJSON.colonTxt} 
                      <Typography style={webStyle.postedAtText} >{item.attributes.post_created_at}</Typography>
                  </DesktopBox>
                </FlexSpaceBetweenBox>
                <Box style={webStyle.jobShortDetailBox}>
                  <JobShortDetail style={{marginRight: '8px'}} >{item.attributes.experience_required}</JobShortDetail>
                  <JobShortDetail>{item.attributes.education_level}</JobShortDetail>
                </Box>
                <Box style={webStyle.aboutOrganizationTop}>
                    <ImageAvatar src={jobDetails.company_photo_url} alt="" />
                    <JobAboutSchoolBox>
                      <JobAboutSchoolName>{item.attributes.organization}</JobAboutSchoolName>
                      <PostedByNameDetailsBox>
                        <JobAboutSchoolAdminAndType style={{marginRight:'8px'}} >{jobDetails.posted_by_school_name}</JobAboutSchoolAdminAndType>
                        <JobAboutSchoolAdminAndType><span style={{marginRight: '8px'}} >{configJSON.middleDotTxt}</span> {jobDetails.posted_by_admin_name}</JobAboutSchoolAdminAndType>
                      </PostedByNameDetailsBox>
                    </JobAboutSchoolBox>
                </Box>
                <NoteBox>
                  <img src={description} alt="" />
                  <JobNote>
                    <div dangerouslySetInnerHTML={{ __html: this.shortString(item.attributes.job_description) }} />
                    </JobNote>
                </NoteBox>
                <JobDetailsDivider/>
                <FlexSpaceBetweenBox>
                  <JobDetailsBottomLeftBox>
                    <img src={location} alt="" />
                    <JobLocationTypo>{item.attributes.city}, {item.attributes.state}, {item.attributes.country}</JobLocationTypo>
                  </JobDetailsBottomLeftBox>
                  <ViewDetailsButtonBox>
                    <button style={webStyle.viewDetailsBtn} >{configJSON.viewDetails}</button>
                  </ViewDetailsButtonBox>
                </FlexSpaceBetweenBox>
              </JobDataMainBox>
            )
          })}</>
      )
    }
    const title =this.state.role == 1 ? "Teacher Details" : "Job Details"
    return (
      <>
        <MainBox>
          <NavbarBox>
            <Navbar id='' navigation={this.props.navigation} NaviagationPage={"Home"} />
          </NavbarBox>
          <NavbarMobile>
            <img data-test-id='btnBack' onClick={this.handleBack} style={webStyle.backIcon} src={backIcon} alt="" />
            <NavbarTitle>{title}</NavbarTitle>
          </NavbarMobile>
          <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.isSnackbarOpen}
              autoHideDuration={3000}
              onClose={this.closeSnackbar}
            >
              <div style={webStyle.popup as React.CSSProperties}>
                <img style={{marginRight: '10px'}} src={tickIcon} alt="" />
                {this.state.snackbarMsg}
              </div>
          </Snackbar>
          <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.alreadyApplied}
              autoHideDuration={3000}
              onClose={this.closeSnackbar}
            >
              <div style={webStyle.popup as React.CSSProperties}>
                <img style={{marginRight: '10px'}} src={tickIcon} alt="" />
                You have already applied for this job
              </div>
          </Snackbar>
          <BreadcrumbBox>
            <BreadcrumbTitle data-test-id='btnNavigate' onClick={() => this.handleNavigation('JobListing',false)}>{configJSON.homeTxt}</BreadcrumbTitle>
            <img style={webStyle.rightIcon} src={rightArrow} alt="" />
            <BreadcrumbTitle data-test-id='btnNavigate' onClick={() => this.handleNavigation('JobListing',false)}>{configJSON.searchResultsTxt}</BreadcrumbTitle>
            <img style={webStyle.rightIcon} src={rightArrow} alt="" />
            <BreadcrumbTitleLast>{title}</BreadcrumbTitleLast>
          </BreadcrumbBox>
          {this.state.role == "2" && (this.state.isAppliedBtnClicked
            ?<>
              <AppliedCheckMarkBox>
                <img style={webStyle.appliedCheckMarkImg} src={applied_checkmark_circle} alt="" />
              </AppliedCheckMarkBox>
              <SuccssfullyAppliedTypo>{configJSON.successfullyAppliedTxt}</SuccssfullyAppliedTypo>
              <ThanksForApplyingTypo>{configJSON.thanksForApplied}</ThanksForApplyingTypo>
              <BrowsMoreJobBtnBox>
                  <button data-test-id='btnBrowsMoreJob' style={webStyle.applyForJobBtn} onClick={this.browseMoreJob} >{configJSON.browseMoreJob}</button>
              </BrowsMoreJobBtnBox>

              <YouMayInterestedMainBox>
                <YouMayInterestedTypo>{configJSON.YouMayInterestedTxt}</YouMayInterestedTypo>
                <Box style={webStyle.viewAllBox} onClick={()=>this.handleNavigation('JobListing',true)} >
                  <ViewAllTypo>{configJSON.viewAll}</ViewAllTypo>
                  <RightArrowBox>
                    <img style={webStyle.viewAllRightIcon} src={rightArrow} alt="" />
                  </RightArrowBox>
                </Box>
              </YouMayInterestedMainBox>
              <Box>{renderJobsData()}</Box>
            </>
            : <>
                <JobDetailsTopBox>
                  <Box style={webStyle.jobTitleMain} >
                    <Box>
                      <MainTitle>{details.job_title}</MainTitle>
                      <MainCompanyName>{details.organization}</MainCompanyName>
                    </Box>
                    <DisplayForDesktop>
                      <ImageAvatar alt="company_logo" src={jobDetails.company_photo_url} />
                    </DisplayForDesktop>
                    <DisplayForMobile data-test-id='btnSaveUnsave' onClick={() => this.handleSavedUnsaved(!this.isJobSaved(this.state.cardId), this.state.cardId)} >
                      <img style={webStyle.savedIconForMobile} src={this.state.isApplied ? savedStarMobileIcon : unSavedStarMobileIcon} alt="" /> 
                    </DisplayForMobile>
                  </Box>
                  <JobTitleTopMiddleSection>
                    <JobTitleTopMiddleSmallBox>
                      <Box style={webStyle.jobTitleMiddleInnerBox} >
                        <img src={experienceLogo} alt="" />
                        <JobTitleTopCategoryTypo>{configJSON.experienceTxt}</JobTitleTopCategoryTypo>
                      </Box>
                      <JobTitleTopCategoryDetail>{details.experience_required}</JobTitleTopCategoryDetail>
                    </JobTitleTopMiddleSmallBox>
                    <JobTitleTopMiddleSmallBox>
                      <Box style={webStyle.jobTitleMiddleInnerBox} >
                        <img src={educationLogo} alt="" />
                        <JobTitleTopCategoryTypo>{configJSON.educationLevelTxt}</JobTitleTopCategoryTypo>
                      </Box>
                      <JobTitleTopCategoryDetail>{details.education_level}</JobTitleTopCategoryDetail>
                    </JobTitleTopMiddleSmallBox>
                    <JobTitleTopMiddleSmallBox>
                      <Box style={webStyle.jobTitleMiddleInnerBox} >
                        <img src={locationLogo} alt="" />
                        <JobTitleTopCategoryTypo>{configJSON.locationTxt}</JobTitleTopCategoryTypo>
                      </Box>
                      <JobTitleTopCategoryDetail>{details.city}, {details.state}, {details.country}</JobTitleTopCategoryDetail>
                    </JobTitleTopMiddleSmallBox>
                    <PostedTimeMobile>
                      <Box style={webStyle.postedAtBox} >
                        {configJSON.postedTxt}{configJSON.colonTxt} 
                        <Typography style={webStyle.postedAtText} >{details.created_at}</Typography>
                      </Box>
                    </PostedTimeMobile>
                  </JobTitleTopMiddleSection>
                  <JobDetailsBottomDivider/>
                  <JobTitleTopBottomSection>
                    <Box style={webStyle.postedAtBox} >
                      {configJSON.postedTxt}{configJSON.colonTxt} 
                      <Typography style={webStyle.postedAtText} >{details.created_at}</Typography>
                    </Box>
                    <Box style={{display:"flex", gap:"8px"}}>
                      <Box style={webStyle.saveJobBox} data-test-id="inviteclick" onClick={this.inviteclick}  >
                      <MailOutlineIcon style={{marginRight:"4px" , fontSize:"1.4rem"}} />
                      {configJSON.inviteFriend}
                      </Box>
                      <Box data-test-id='btnSaveUnsave' style={webStyle.saveJobBox} onClick={() => this.handleSavedUnsaved(!this.isJobSaved(this.state.cardId), this.state.cardId)} >
                        <img style={webStyle.savedIcon} src={this.getSavedIconSrc(isJobSaved)}  alt="" />
                        {this.getJobButtonText()}
                      </Box>
                    </Box>
                  </JobTitleTopBottomSection>
                </JobDetailsTopBox>

                <PostedByBox>
                  <PostedByTextTypography>{configJSON.postedByTxt}</PostedByTextTypography>
                  <PostedByDetailsBox>
                    <PostedByDetailsLeftBox>
                      <ImageAvatar src={jobDetails.posted_by_image_url} alt="" />
                      <PostedByDetailsMiddleBox>
                        <PostedByNameTypography>{details.posted_by}</PostedByNameTypography>
                        <PostedByNameDetailsBox>
                          <AdminTypeTypography>{jobDetails.posted_by_admin_name}</AdminTypeTypography>
                          <SchoolNameDot>{configJSON.middleDotTxt}</SchoolNameDot>
                          <SchoolNameTypography>{details.organization}</SchoolNameTypography>
                        </PostedByNameDetailsBox>
                      </PostedByDetailsMiddleBox>
                    </PostedByDetailsLeftBox>
                    <SendMessageButtonBox data-test-id="getChatList" onClick={this.getChatList}>
                      <button style={webStyle.sendMessageBtn} >{configJSON.sendMessage}</button>
                    </SendMessageButtonBox>
                  </PostedByDetailsBox>
                  <DividerForMobile/>
                </PostedByBox>

                <PostedByBox >
            <JobDescTitle>{configJSON.positionTitle}</JobDescTitle>
              {details?.job_working_days?.map((item, index) => (
                  <Box style={{marginTop:"10px"}} key={index}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                      <Typography style={{ ...webStyleNew.prefOption }}>
                        {this.state?.days[item.working_day_id]}
                      </Typography>
                      <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}>
                        {renderText(item?.full_day)}
                      </Typography>
                    </Box>
                    <>
                      {this.renderStartEndTime(item)}
                    </>
                  <Box>
                  </Box>
                  </Box>
              ))}
              </PostedByBox>
              <PostedByBox>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                  <Typography style={{ ...webStyleNew.prefOption }}>
                    Start Date
                  </Typography>
                  <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}>
                  {this.formatDate(details?.start_date)}
                  </Typography>
                </Box>

                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "end" }}>
                  <Typography style={{ ...webStyleNew.prefOption }}>
                    End Date
                  </Typography>
                  <Typography style={{ color: '#395169', fontFamily: 'Inter', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}>
                  {this.formatDate(details?.end_date)}
                  </Typography>
                </Box>
              </PostedByBox>
                <JobDescriptionMainBox>
                  <JobDescTitle>{configJSON.jobDescriptionTxt}</JobDescTitle>
                  <JobDescJobTitleBox>
                    {configJSON.jobTitleTxt}{configJSON.colonTxt}
                    <JobDescJobTitle>{details.job_title}</JobDescJobTitle>
                  </JobDescJobTitleBox>
                   <AboutOrg> <div dangerouslySetInnerHTML={{ __html: details.job_description }} /></AboutOrg>
                  <DividerForMobile/>
                </JobDescriptionMainBox>

                <AboutOrganizationMainBox>
                  <AboutOrganizationTitle>{configJSON.aboutOrganization}</AboutOrganizationTitle>
                  <Box style={webStyle.aboutOrganizationTop} >
                    <ImageAvatar src={jobDetails.company_photo_url} alt="" />
                    <AboutOrgDetailBox>
                      <AboutOrgName>{details.organization}</AboutOrgName>
                      <AboutOrgLocation>{details.city}, {details.state}, {details.country}</AboutOrgLocation>
                    </AboutOrgDetailBox>
                  </Box>
                  <AboutOrg>{details.about_organization}</AboutOrg>
                </AboutOrganizationMainBox>
                {
                  !jobDetails.isApplied && <ApplyForJobButtonBox>

                  <button data-test-id='btnApplyForJob2' onClick={this.handleApplyForJob} style={webStyle.applyForJobBtn} >{configJSON.applyForJob}</button>
                </ApplyForJobButtonBox>
                }
                 <ApplyForJobButtonMobileMainBox>
                    <ApplyForJobButtonMobileBox>
                      <button style={webStyle.sendMsgBtnMobile} >{configJSON.sendMessage}</button>
                    </ApplyForJobButtonMobileBox>
                    {
                      !jobDetails.isApplied && <ApplyForJobButtonMobileBox>
                        <button data-test-id='btnApplyForJob2' onClick={this.handleApplyForJob} style={webStyle.applyForJobMobileBtn} >{configJSON.applyForJob}</button>
                      </ApplyForJobButtonMobileBox>
                    }
                  </ApplyForJobButtonMobileMainBox>
                
            </>
          )}

         {this.renderTeacherBox(isTeacherSaved , buttonText , hasTraveldistance)}
          <Dialog PaperProps={{
            style: {
              width: "808px",
              borderRadius: "8px",
              boxShadow: "none",
              padding: "24px",
              backgroundColor: "rgb(240, 240, 240)",
              position: "relative",
            },
          }} open={this.state.inviteFriend} data-test-id="closeinvite" onClose={() => this.setState({ inviteFriend: false })}>
            <Invitefriends navigation={this.props.navigation} id={""} open={true} data-test-id="closeComponent" onClose={() => { }} />
          </Dialog>
          <FooterBox>
            <Footer navigation={undefined} id={""} NaviagationPage={""}/>
          </FooterBox>
        </MainBox>
      </>
    );
  }
// Customizable Area End
}

export default JobListingDetails;
// Customizable Area Start

const renderText = (item:boolean)=>(item ? "Full Day" : "")
const renderRole = (role:string)=>( role == '1')
const webStyle = {
  backIcon: {
    cursor: 'pointer',
  },
  appliedCheckMarkImg: {
    width: '100%',
    height: '100%',
  },
  popup: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    width: '332px',
    padding: '10px 10px 10px 10px',
    gap: '8px',
    borderFadius: '8px',
    boxShadow: '0px 6px 15px -3px #00000026',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#0F172A',
  },
  rightIcon: {
    margin: '0 15px 0 15px'
  },
  viewAllBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  viewAllRightIcon: {
    height: '10px',
    width: '8spx',
    marginLeft: '7.6px',
  },
  jobTitleMain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  jobTitleMiddleInnerBox: {
    display: 'flex',
    alignItems: 'center',
  },
  postedAtBox: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  postedAtText: {
    display: 'inline-block',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
    color: '#4788B7',
    marginLeft: '4px',
  },
  saveJobBox: {
    display: 'flex',
    alignItems: 'center',
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
  },
  savedIcon: {
    marginRight: '4px',
  },
  savedIconForMobile: {
    cursor: 'pointer',
  },
  sendMessageBtn: {
    height: '44px',
    gap: '8px',
    borderRadius: '8px',
    color: '#395169',
    border: '1px solid #395169',
    background: '#FFFFFF',
    cursor: 'pointer',
    padding: '10px 24px 10px 24px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
  },
  aboutOrganizationTop: {
    display: 'flex',
  },
  applyForJobBtn: {
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#395169',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer'
  },
  applyForJobMobileBtn: {
    border: 'none',
    height: '100%',
    width: '100%',
    padding: '10px 16px 10px 16px',
    borderRadius: '8px',
    backgroundColor: '#395169',
    fontFamily: 'Inter',
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '14px',
    cursor: 'pointer'
  },
  sendMsgBtnMobile: {
    height: '100%',
    width: '100%',
    border: '1px solid #395169',
    padding: '10px 16px 10px 16px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Inter',
    color: '#395169',
    fontSize: '14px',
    cursor: 'pointer',
    fontWeight: 700,
  },
  jobShortDetailBox: {
    display: 'flex',
    alignItems: 'center',
  },
  viewDetailsBtn: {
    height: '32px',
    padding: '6px 16px 6px 16px',
    borderRadius: '8px',
    color: '#395169',
    border: '1px solid #395169',
    background: 'none',
    cursor: 'pointer',
  },
};

const MainBox = styled(Box)({
  backgroundColor: "#F8F8F8",
});

const DesktopBox = styled(Box)({
  "@media only screen and (max-width: 816px)": {
    display: 'none'
  },
});

const BreadcrumbBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '36px 0 0 120px',
  "@media only screen and (max-width: 924px)": {
    margin: '36px 0 0 80px',
  },
  "@media only screen and (max-width: 816px)": {
    margin: '36px 0 0 40px',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const BreadcrumbTitle = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#475569',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationColor: '#475569',
  }
});

const BreadcrumbTitleLast = styled(Box)({
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: 400,
  color: '#475569',
  fontFamily: 'Inter',
});

const JobDetailsTopBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  width: '728px',
  margin: 'auto',
  marginTop: '32px',
  padding: '32px 32px 32px 32px',
  borderRadius: '16px',
  "@media only screen and (max-width: 816px)": {
    width: '528px',
    padding: '24px 24px 24px 24px',
  },
  "@media only screen and (max-width: 620px)": {
    width: '360px',
    padding: '16px 16px 16px 16px',
  },
  "@media only screen and (max-width: 414px)": {
    'box-shadow': 'none',
    borderRadius: '0',
    paddingTop: '0px',
  },
});

const MainTitle = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 600,
  color: '#0F172A',
  "@media only screen and (max-width: 816px)": {
    fontSize: '20px',
    lineHeight: '30px',
  },
  "@media only screen and (max-width: 620px)": {
    fontSize: '18px',
    lineHeight: '28px',
  },
});

const MainCompanyName = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  marginTop: "8px",
  color: '#475569',
  "@media only screen and (max-width: 816px)": {
    fontSize: '14px',
    lineHeight: '22px',
  },
  "@media only screen and (max-width: 620px)": {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: "4",
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const JobTitleTopMiddleSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '32px',
  "@media only screen and (max-width: 816px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 620px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    position: 'relative',
    marginTop: '16px',
  },
});

const JobTitleTopMiddleSmallBox = styled(Box)({
  marginRight: '48px',

  "@media only screen and (max-width: 816px)": {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  "@media only screen and (max-width: 620px)": {
    marginBottom: '8px',
    width: '100%',
  },
  "@media only screen and (max-width: 414px)": {
    margin: 'auto',
    marginBottom: '8px',
  },
});

const PostedTimeMobile = styled(Box)({
  display: 'none',
  "@media only screen and (max-width: 414px)": {
    position: 'absolute',
    bottom: '12px',
    right: '0px',
  },
});

const JobTitleTopCategoryTypo = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '18px',
  color: '#64748B',
  marginLeft: '8px',
  "@media only screen and (max-width: 816px)": {
    width: '120px',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const JobTitleTopCategoryDetail = styled(Typography)({
  marginTop: '8px',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#334155',
  width: '100%',
  "@media only screen and (max-width: 816px)": {
    margin: '0 0 0 8px',
  },
  "@media only screen and (max-width: 620px)": {
    margin: '0 0 0 8px',
    fontSize: '14px',
  },
});

const JobTitleTopBottomSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const JobDetailsBottomDivider = styled(Divider)({
  margin: '32px 0 32px 0',
  "@media only screen and (max-width: 414px)": {
    margin: '16px 0 16px 0',
  },
});

const PostedByBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  width: '728px',
  margin: 'auto',
  marginTop: '24px',
  padding: '32px 32px 32px 32px',
  borderRadius: '16px',
  "@media only screen and (max-width: 816px)": {
    width: '528px',
    padding: '24px 24px 24px 24px',
  },
  "@media only screen and (max-width: 620px)": {
    width: '360px',
    padding: '16px 16px 16px 16px',
  },
  "@media only screen and (max-width: 414px)": {
    'box-shadow': 'none',
    borderRadius: '0',
    marginTop: '0px',
    paddingTop: '0',
    paddingBottom: '0',
  },
});

const PostedByTextTypography = styled(Typography)({
  color: '#1E293B',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 600,
  marginBottom: '24px',
  "@media only screen and (max-width: 816px)": {
    fontSize: '18px',
  },
  "@media only screen and (max-width: 620px)": {
    fontSize: '16px',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const PostedByDetailsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  "@media only screen and (max-width: 816px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 620px)": {
    display: 'block',

  },
});

const PostedByDetailsLeftBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const ImageAvatar = styled(Avatar)({
  width: '56px',
  height: '56px',
});

const PostedByDetailsMiddleBox = styled(Box)({
  marginLeft: '16px',
});

const PostedByNameTypography = styled(Typography)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '26px',
  color: '#1E293B',
  "@media only screen and (max-width: 414px)": {
    fontSize: '14px',
  },
});

const PostedByNameDetailsBox = styled(Box)({
  display: 'flex',
  marginTop: '4px',
  "@media only screen and (max-width: 620px)": {
    display: 'block',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'flex',
    alignItems: "center",
  },
});

const SchoolNameDot = styled(Box)({
  marginLeft: '12px',
  color: '#64748B',
  fontSize: '14px',
  fontFamily: 'Inter',
  lineHeight: '22px',
  fontWeight: 400,
  "@media only screen and (max-width: 620px)": {
    display: 'none',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'block',
    fontSize: '12px',
    marginLeft: '6px',
  },
})

const AdminTypeTypography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#64748B',
  "@media only screen and (max-width: 414px)": {
    fontSize: '12px',
  },
});

const SchoolNameTypography = styled(Typography)({
  marginLeft: '12px',
  color: '#64748B',
  fontSize: '14px',
  fontFamily: 'Inter',
  lineHeight: '22px',
  fontWeight: 400,
  "@media only screen and (max-width: 620px)": {
    margin: '4px 0 0 0',
  },
  "@media only screen and (max-width: 414px)": {
    fontSize: '12px',
    margin: '0 0 0 6px',
  },
});

const SendMessageButtonBox = styled(Box)({
  "@media only screen and (max-width: 816px)": {
    marginTop: '0px',
  },
  "@media only screen and (max-width: 620px)": {
    marginTop: '0px',

  },
  "@media only screen and (max-width: 420px)": {
    marginRight:"26px",
    flexDirection:"column"
  },
});

const CustomBox = styled(Box)({
  "@media only screen and (max-width: 420px)": {
    justifyContent: "left",
    width: "100%",
    marginTop: "15px",
  },
})

const JobDescriptionMainBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  width: '728px',
  margin: 'auto',
  marginTop: '24px',
  padding: '32px 32px 32px 32px',
  borderRadius: '16px',
  "@media only screen and (max-width: 816px)": {
    width: '528px',
    padding: '24px 24px 24px 24px',
  },
  "@media only screen and (max-width: 620px)": {
    width: '360px',
    padding: '16px 16px 16px 16px',
  },
  "@media only screen and (max-width: 414px)": {
    'box-shadow': 'none',
    borderRadius: '0',
    paddingTop: '0',
  },
});

const JobDescTitle = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 600,
  color: '#1E293B',
  "@media only screen and (max-width: 816px)": {
    fontSize: '18px',
    lineHeight: '26px',
  },
  "@media only screen and (max-width: 620px)": {
    fontSize: '16px',
    lineHeight: '26px',
  },
});

const JobDescJobTitleBox = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '25.6px',
  color: '#334155',
  "@media only screen and (max-width: 620px)": {
    fontSize: '14px',
    lineHeight: '22px',
  },
});

const JobDescJobTitle = styled(Typography)({
  display: 'inline-block',
  fontSize: '16px',
  fontFamily: 'Inter',
  lineHeight: '25.6px',
  fontWeight: 400,
  color: '#334155',
  marginLeft: '4px',
  marginTop: '24px',
  "@media only screen and (max-width: 620px)": {
    fontSize: '14px',
    lineHeight: '22px',
    marginTop: '12px',
  },
});


const AboutOrganizationMainBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  width: '728px',
  margin: 'auto',
  marginTop: '24px',
  padding: '32px 32px 32px 32px',
  borderRadius: '16px',
  "@media only screen and (max-width: 816px)": {
    width: '528px',
    padding: '24px 24px 24px 24px',
  },
  "@media only screen and (max-width: 620px)": {
    width: '360px',
    padding: '16px 16px 16px 16px',
  },
  "@media only screen and (max-width: 414px)": {
    'box-shadow': 'none',
    borderRadius: '0',
    marginTop: '0px',
    paddingTop: "0px",
  },
});

const AboutOrganizationTitle = styled(Typography)({
  fontWeight: 600,
  lineHeight: '28px',
  fontFamily: 'Inter',
  color: '#1E293B',
  fontSize: '20px',
  marginBottom: '24px',
  "@media only screen and (max-width: 620px)": {
    lineHeight: '24px',
    marginBottom: '20px',
    fontSize: '18px',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const AboutOrgDetailBox = styled(Box)({
  marginLeft: '16px',
});

const AboutOrgName = styled(Typography)({
  fontSize: '18px',
  fontFamily: 'Inter',
  lineHeight: '26px',
  fontWeight: 500,
  "@media only screen and (max-width: 620px)": {
    fontSize: '16px',
  },
});

const AboutOrgLocation = styled(Typography)({
  marginTop: '4px',
  color: '#64748B',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  fontFamily: 'Inter',
});

const AboutOrg = styled(Typography)({
  fontSize: '16px',
  color: '#334155',
  lineHeight: '25.6px',
  fontFamily: 'Inter',
  fontWeight: 400,
  marginTop: '24px',
  "@media only screen and (max-width: 620px)": {
    fontSize: '14px',
    marginTop: '20px',
  },
});

const ApplyForJobButtonBox = styled(Box)({
  width: '169px',
  height: '56px',
  margin: 'auto',
  marginTop: '80px',
  "@media only screen and (max-width: 620px)": {
    marginTop: '50px',
  },
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const ApplyForJobButtonMobileMainBox = styled(Box)({
  display: 'none',
  "@media only screen and (max-width: 414px)": {
    display: 'flex',
    alignItems: 'center',
    marginBottom: "50px",
  },
});

const ApplyForJobButtonMobileBox = styled(Box)({
  width: '169px',
  height: '56px',
  margin: 'auto',
  marginTop: '80px',
  "@media only screen and (max-width: 620px)": {
    marginTop: '50px',
  },
  "@media only screen and (max-width: 414px)": {
    marginTop: '32px',
  },
});

const AppliedCheckMarkBox = styled(Box)({
  width: '160px',
  height: '160px',
  margin: 'auto',
  marginTop: '96px',
  "@media only screen and (max-width: 414px)": {
    width: '96px',
    height: '96px',
    margin: 'auto',
    marginTop: '96px',
  },
});

const SuccssfullyAppliedTypo = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  color: '#1E293B',
  marginTop: '16px',
  textAlign: 'center',
});

const ThanksForApplyingTypo = styled(Typography)({
  width: '384px',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#475569',
  margin: 'auto',
  marginTop: '12px',
  textAlign: 'center',
  "@media only screen and (max-width: 400px)": {
    width: '305px',
    fontSize: 12
  },
});

const BrowsMoreJobBtnBox = styled(Box)({
  width: 'fit-content',
  height: '56px',
  margin: 'auto',
  marginTop: '32px',
});

const YouMayInterestedMainBox = styled(Box)({
  width: '690px',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '128px',
  "@media only screen and (max-width: 816px)": {
    width: '528px',
  },
  "@media only screen and (max-width: 620px)": {
    width: '360px',
  },
  "@media only screen and (max-width: 414px)": {
    width: '320px',
  },
});

const YouMayInterestedTypo = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '26px',
  color: '#475569',
  "@media only screen and (max-width: 620px)": {
    fontSize: '16px',
  },
});

const ViewAllTypo = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '22px',
  color: '#395169',
});

const RightArrowBox = styled(Box)({

});

const JobDataMainBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  width: '626px',
  margin: 'auto',
  marginTop: '24px',
  padding: '24px 32px 24px 32px',
  borderRadius: '16px',
  "@media only screen and (max-width: 816px)": {
    width: '528px',
    padding: '24px 24px 24px 24px',
  },
  "@media only screen and (max-width: 620px)": {
    width: '360px',
    padding: '16px 16px 16px 16px',
  },
  "@media only screen and (max-width: 414px)": {
    'box-shadow': 'none',
    borderRadius: '0',
    paddingTop: '0px',
    width: "320px",
  },
});

const FlexSpaceBetweenBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const CardTitleTypo = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '26px',
  color: '#0F172A',
});

const JobShortDetail = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#000000',
  padding: '2px 12px 2px 12px',
  backgroundColor: '#F8F8F8',
  marginTop: '12px',
  marginBottom: '20px',
});

const JobAboutSchoolBox = styled(Box)({
  marginLeft: '16px',
});

const JobAboutSchoolName = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '22px',
  color: '#0F172A',
})

const JobAboutSchoolAdminAndType = styled(Typography)({
  marginTop: '4px',
  color: '#64748B',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
});

const NoteBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '24px',
  "@media only screen and (max-width: 816px)": {
    display: 'none'
  },
});

const JobNote = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#475569',
  marginLeft: '12px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const JobDetailsDivider = styled(Divider)({
  margin: '16px 0 16px 0',
});

const DividerForMobile = styled(Divider)({
  display: 'none',
  "@media only screen and (max-width: 414px)": {
    display: 'block',
    margin: '16px 0 16px 0',
  },
});

const JobDetailsBottomLeftBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const JobLocationTypo = styled(Typography)({
  marginLeft: '12px',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#0F172A',
});

const ViewDetailsButtonBox = styled(Box)({
  height: '32px',
  "@media only screen and (max-width: 816px)": {
    display: 'none'
  },
});

const DisplayForDesktop = styled(Box)({
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  },
});

const DisplayForMobile = styled(Box)({
  display: 'none',
  cursor:"pointer",
  "@media only screen and (max-width: 414px)": {
    display: 'block',
  },
});

const FooterBox = styled(Box)({
  marginTop: "120px",
  "@media only screen and (max-width: 620px)": {
    fontSize: '80px',
  },
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  },
});

const NavbarBox = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  },
});

const NavbarMobile = styled(Box)({
  display: 'none',
  "@media only screen and (max-width: 571px)": {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#395169',
    padding: '15px 0 15px 20px',
    position: 'relative',
  },
});


const QuickLinks = styled(Grid)({
  "@media only screen and (max-width: 688px)": {
    display: 'none',
  }
})
const DeskCustomeGrid = styled(Grid)({
  display: "block",
  "@media only screen and (max-width: 687px)": {
    display: 'none',
  }
})
const MobileCustomGrid = styled(Grid)({
  display: "none !important",
  "@media only screen and (max-width: 687px)": {
    display: 'flex !important',
  },
  "@media only screen and (max-width: 420px)": {
    "& .profileChatBox":{
      marginTop:0
    }
  },
  
})

const CustomeBox = styled(Box)({
  "@media only screen and (max-width: 420px)": {
    
  },
 
})


const NavbarTitle = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  color: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
const MainBoxTeacher = styled(Box)({
  background: "#F8F8F8",
  position: "relative",
  padding: "140px 222px 120px 222px",
  "@media only screen and (max-width: 1330px)": {
    padding: 100,
  },
  "@media only screen and (max-width: 688px)": {
    paddingLeft: 29,
    paddingRight: 29
  },
  "@media only screen and (max-width: 571px)": {
    paddingLeft: 29,
    paddingRight: 29,
  },
  "@media only screen and (max-width: 404px)": {
    paddingLeft: 10,
    paddingRight: 10
  },
  "@media only screen and (max-width: 375px)": {
    paddingLeft: 4,
    paddingRight: 4
  }
})

const ProfileHeading = styled(Box)({
  color: "#1E293B",
  fontFamily: "Inter",
  fontSize: "30px",
  fontWeight: 700,
  lineHeight: "40px",
  marginBottom: "32px",
  "@media only screen and (max-width: 414px)": {
    display: 'none',
  }
})

const ProfileInfo = styled(Box)({
  background: "#fff",
  padding: "32px",
  borderRadius: "16px",
  "@media only screen and (max-width: 414px)": {
    background: "#fff",
    marginTop: -99
  },
  "@media only screen and (max-width: 543px)": {
    padding: "30px"
  }
})
const webStyleNew = {
  confirmheading: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '25.8px',
    fontWeight: 700,
    lineHeight: '41.6px',
    marginTop: '22px',
  },
  confirmDelete: {
    color: '#DC2626',
    fontFamily: 'Inter',
    fontSize: '21.6px',
    fontWeight: 700,
    lineHeight: '38.4px',
    textAlign: 'center' as 'center',
    textTransform: 'none' as 'none',
    marginTop: '10px',
    cursor: 'pointer'
  },
  preTime : {
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,color: '#1E293B'},
  popHeading: {
    color: '#1E293B',
    fontFamily: 'Inter' as 'Inter',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
  },
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4
  },
  profileInfo: {
    background: "#fff",
    padding: "32px",
    borderRadius: "16px"
  },
  heading: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    marginBottom: "32px"
  },
  profilepic: {
    borderRadius: "50%",
    width: 120,
    height: 120,
    cursor: 'pointer'
  },
  editText:
    { color: '#395169', fontFamily: 'Inter', fontSize: '16px', fontWeight: 400, lineHeight: '24px' },

  profilepicMob: {
    borderRadius: "50%",
    width: 56,
    height: 56,
    cursor: 'pointer'
  },
  name: {
    color: '#1E293B',
    fontFamily: "Inter",
    fontSize: "24px",
    fontweight: 600,
    lineHeight: "32px",
  },
  email: {
    color: '#475569',
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  nameMob: {
    color: '#1E293B',
    fontFamily: "Inter",
    fontSize: "18px",
    fontweight: 600,
    lineHeight: "32px",
  },
  emailMob: {
    color: '#475569',
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "14px",
  },
  countingBox: {
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    textAlign: "center" as "center",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  profileStatusBox: {
    background: "#EBF3C6",
    borderRadius: "12px",
    padding: "24px",
    borderBottomLeftRadius: "unset",
    borderBottomRightRadius: "unset"
  },
  profileCompletion: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  remaining: {
    background: "linear-gradient(90.62deg, #4788B7 0%, #669CBA 42.9%, #C6DC68 100%)",
    paddingTop: 5,
    paddingLeft: 10,
    paddingBottom: 5,
    paddingRight: 10,
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    display: "flex",
    justifyContent: "space-between"
  },
  addmore: {
    color: '#334155',
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  text: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  quicklink: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    paddingBottom: '16px',
    borderBottom: "1px solid #F1F5F9"
  },
  links: {
    color: '#395169',
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textDecoration: "underline",
    marginTop: "16px",
    cursor: 'pointer'
  },
  optionLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "16px",
    borderBottom: "1px solid #F1F5F9",
    marginBottom: 16,
    cursor: "pointer"
  },
  numbers: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  optionLabelText: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  sliderBox: {
    marginTop: 16,
    display: "flex",
    gap: 26,
    justifyContent: "space-between"
  },
  remainingItemBox: {
    display: "flex",
    gap: 6,
    marginTop: 8
  },
  arrowWhite: {
    color: "#fff",
    height: 12,
    marginTop: 2
  },
  expandArrow: {
    height: 16,
    marginTop: 5
  },
  editType: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  editIcon: {
    color: "#395169"
  },
  space: { display: "flex", justifyContent: "space-between", marginTop: 22 },
  editHeading: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  saveBtn: {
    color: '#FFFFFF',
    background: '#395169',
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: 16,
    textTransform: "none" as "none",
    padding: "10px 16px",
    borderRadius: 8,
    cursor: 'pointer'
  },
  editTextPreferesnce: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginLeft: 5,
    cursor: 'pointer'
  },
  introValueBox: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    height: 160,
    padding: 16,
    border: '0.5px solid #CBD5E1',
    borderRadius: 8,
    marginTop: 16
  },
  addMoreEdu: {
    color: "#395169",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: 'center' as 'center',
    justifyContent: "center",
    cursor: 'pointer',
  },
  monthstyle: {
    border: "1px solid #E2E8F0", textAlign: 'center' as 'center', padding: 10, borderRadius: 8, width: 100, textTransform: 'none' as 'none',
  },
  monthYearHeading: {
    color: '#0F172A',
    fontFamily: "Inter",
    fontSize: "26.6px",
    fontWeight: 700,
    lineHeight: "32.47px",
  },
  yearHeading: {
    display: 'flex', justifyContent: 'space-between', paddingTop: 30, paddingBottom: 30, paddingInline: 23
  },
  errorMsg: {
    color: 'red',
    fontSize: 12,
    fontFamily: 'Inter'
  },
  showBoxData: {
    border: '1px solid #E2E8F0',
    borderRadius: '12px',
    padding: '24px',
    marginTop: '30px',
    width: "100%"
  },
  showBoxTtile: {
    color: '1E293B',
    fontFamily: 'Inter',
    fontSize: '21px',
    fontWeight: 600
  },
  organisationTxt: {
    color: "#334155",
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '27px',
  },
  repotitle: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '27px',
    marginTop: '15px'
  },
  jobRespon: {
    color: '#334155',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '27px',
    marginTop: '12px'
  },
  expTxt: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  addskill: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: '15px'
  },
  addbutton: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    cursor: 'pointer'
  },
  skillname: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  skillNameDelete: {
    padding: '3px 16px',
    borderBottom: '1px solid #F1F5F9'
  },
  skillBox: {
    border: '1px solid #E2E8F0',
    borderRadius: '16px',
    marginTop: '9px',
    width: "100%"
  },
  jobprefeBox: {
    borderRadius: '8px',
    border: '1px solid #E2E8F0',
    marginTop: '16px'
  },
  preferTitle: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    borderBottom: '#F1F5F9',
    padding: '15px 16px'
  },
  prefOption: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
  },
  travel: {
    color: '#475569',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '20px',
    marginBottom: '10px'
  },
  avtarColor: {
    width: '32px', height: '32px', fontWeight: 700, fontFamily: 'Inter', fontSize: '14px'
  },
  preferTime: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '16px',
    marginLeft: '16px'
  },
  schoolname: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
  },
  course: {
    color: '#334155',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: "20px"
  },
  duration: {
    color: '#475569',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: '20px'
  },
  sharewith: {
    border: '1px solid #E2E8F0',
    borderRadius: '8px'
  },
  sharewithHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 16px',
    borderBottom: '1px solid #F1F5F9'
  },
  sharewithTxt: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  sharewithOptions: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    padding: '12px 16px'
  },
  uploadBox: {
    padding: '32px 24px',
    borderRadius: '4px',
    border: '1px solid #CBD5E1',
    borderStyle: 'dashed',
    textAlign: 'center' as 'center',
    marginTop: '12px',
    cursor: 'pointer'
  },
  filename: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  fileSize: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  jpeg: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '5px'
  },
  uploadFileTxt: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  textheading: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    marginBottom: 6,
    marginTop: '23px'
  },
}

// Customizable Area End