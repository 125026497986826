import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled, Collapse, Dialog,
  TextField,
  Snackbar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons";
import { tickicon, checkboxConditionTrue, checkboxConditionFalse } from "./assets";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getTransform=()=>{
    const transform=this.state.isAccountOpen ? 'rotate(-90deg)' : 'rotate(90deg)'
    return transform
  }
  notiTransform=()=>{
    return this.state.isNotificationOpen ? 'rotate(-90deg)' : 'rotate(90deg)'
  }
  passType=()=>{
    return this.state.isOldPassEnabled ? "password" : "text"
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <CustomNavbar>
          <Navbar navigation={this.props.navigation} id={""} NaviagationPage={""} />
        </CustomNavbar>
          <NavbarMobile>
          <ArrowBackIos data-test-id='btnBack' onClick={this.handleBack} style={webStyle.backIcon} />
          <NavbarTitle>Settings</NavbarTitle>
        </NavbarMobile>
        <MainBox>
          <Navigation>
            <Typography data-test-id="navigateToHome" onClick={this.handleBack} style={webStyle.home}>Home</Typography>
            <ArrowForwardIos style={webStyle.forwardArrow} />
            <Typography style={webStyle.joblisting}>Settings</Typography>
          </Navigation>
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <Heading>Settings</Heading>
          <Button data-test-id="viewNotifications" onClick={this.viewNotifications} style={webStyle.viewBtn}>View Notifications</Button>
          </div>
          <CustomSnackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.isEmailSuccess}
            autoHideDuration={5000}
            data-test-id='closeSnackbar'
            onClose={this.closeSnackbar}
          >
            <div style={webStyle.popup as React.CSSProperties}>
              <img style={{ marginRight: '10px' }} src={tickicon} alt="" />
              Email updated successfully!
            </div>
          </CustomSnackbar>
          <AccountSettingBox>
            <Box style={webStyle.optionLabel} data-test-id="handleAccountSettingBox" onClick={this.handleAccountSettingBox}>
              <Box>
                <Typography style={webStyle.optionLabelText}>Account Settings</Typography>
                <Typography style={webStyle.subheading}>Manage account settings like change email, account password etc.</Typography>
              </Box>
              <ArrowForwardIos
                style={{
                  ...webStyle.expandArrow,
                  transform: this.getTransform()
                }} />
            </Box>
            <Collapse in={this.state.isAccountOpen}>
              <Box style={{ ...webStyle.optionLabel, borderBottom: '1px solid #F1F5F9' }} data-test-id="handleEmailDialog" onClick={this.handleEmailDialog}>
                <Typography style={webStyle.subOptions}>Change email</Typography>
                <ArrowForwardIos
                  style={{ height: '16px', marginTop: '5px' }} />
              </Box>
              <Box style={{ ...webStyle.optionLabel, borderBottom: '1px solid #F1F5F9' }} data-test-id="handlePasswordDialog" onClick={this.handlePasswordDialog}>
                <Typography style={webStyle.subOptions}>Change password</Typography>
                <ArrowForwardIos
                  style={{ height: '16px', marginTop: '5px' }} />
              </Box>
              <Box style={{ ...webStyle.optionLabel }} data-test-id="handleDeleteDialog" onClick={this.handleDeleteDialog}>
                <Typography style={{ ...webStyle.subOptions, color: '#DC2626' }}>Delete account</Typography>
              </Box>
            </Collapse>
          </AccountSettingBox>
          <CustomDialog open={this.state.isChangeEmailOpen}>
            <Box style={webStyle.dialogHeader}>
              <Typography style={webStyle.headerHeading}>Change email</Typography>
              <Close data-test-id="closeEmailBox" style={{cursor:'pointer'}} onClick={this.closeEmailBox} />
            </Box>
            <Box style={webStyle.subBox}>
              <Typography style={webStyle.kindlyFill}>Kindly fill out the form below to begin the process of updating your email address.</Typography>
              <Typography style={webStyle.textfield}>Old email</Typography>
              <TextField fullWidth variant="outlined" value={this.state.oldEmail} data-test-id="odlemail" onChange={(event) => this.valueChange("oldEmail", event.target.value)} />
              <ErrorBox>{this.state.oldEmailError}</ErrorBox>
              <Typography style={webStyle.textfield}>New email</Typography>
              <TextField fullWidth variant="outlined" value={this.state.newEmail} data-test-id="odlemail" onChange={(event) => this.valueChange("newEmail", event.target.value)} />
              <ErrorBox>{this.state.newEmailError}</ErrorBox>
              <Typography style={webStyle.textfield}>Confirm new email</Typography>
              <TextField fullWidth variant="outlined" value={this.state.confirmEmail} data-test-id="odlemail" onChange={(event) => this.valueChange("confirmEmail", event.target.value)} />
              <ErrorBox>{this.state.confirmEmailError}</ErrorBox>
            </Box>
            <Box style={webStyle.buttonbox}>
              <Button style={webStyle.cancelBtn} onClick={this.closeEmailBox}>
                Cancel
              </Button>
              <Button style={webStyle.submitBtn} data-test-id="sumbitEmailChange" onClick={this.sumbitEmailChange}>
                Submit
              </Button>
            </Box>
          </CustomDialog>
          <CustomDialog open={this.state.isPasswordChnageOpen}>
            <Box style={webStyle.dialogHeader}>
              <Typography style={webStyle.headerHeading}>Change password</Typography>
              <Close style={{cursor:'pointer'}} onClick={this.closeEmailBox} />
            </Box>
            <Box style={webStyle.subBox}>
              <Typography style={webStyle.kindlyFill}>Kindly fill out the form below to begin the process of updating your account password.</Typography>
              <Typography style={webStyle.textfield}>Old password</Typography>
              <TextField fullWidth variant="outlined"
                type={this.passType()}
                value={this.state.oldPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePasswordChange(event.target.value)}
                placeholder="Enter old password"
                data-test-id="txtInputPassword"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        edge="end"
                      >
                        {!this.state.isOldPassEnabled ? (
                          <Visibility style={webStyle.eyeIcon} />
                        ) : (
                          <VisibilityOff style={webStyle.eyeIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorBox>{this.state.oldPasswordError}</ErrorBox>
              <Typography style={webStyle.textfield}>New password</Typography>
              <TextField fullWidth variant="outlined"
                type={this.state.isNewPassenabled ? "password" : "text"}
                value={this.state.newPassword}
                data-test-id="handleNewPasswordChange"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleNewPasswordChange(event.target.value)}
                placeholder="Enter new password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleNewClickShowPassword}
                        edge="end"
                      >
                        {!this.state.isNewPassenabled ? (
                          <Visibility style={webStyle.eyeIcon} />
                        ) : (
                          <VisibilityOff style={webStyle.eyeIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorBox>{this.state.newPasswordError}</ErrorBox>
              <Typography style={webStyle.textfield}>Confirm new password</Typography>
              <TextField fullWidth variant="outlined"
                type={this.state.isConfirmPassEnabled ? "password" : "text"}
                value={this.state.confirmPassword}
                data-test-id="handleConfirmwPasswordChange"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleConfirmwPasswordChange(event.target.value)}
                placeholder="Enter new password again"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleConfirmClickShowPassword}
                        edge="end"
                      >
                        {!this.state.isConfirmPassEnabled ? (
                          <Visibility style={webStyle.eyeIcon} />
                        ) : (
                          <VisibilityOff style={webStyle.eyeIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorBox>{this.state.confirmPassError}</ErrorBox>
              <PasswordConditionTitleTypograpy>
                Password must include:
              </PasswordConditionTitleTypograpy>
              <PasswordConditionWrapper>
                <img style={webStyle.checkboxIcon} src={this.state.passwordConditions.isCapital ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                <PasswordConditionDescTypograpy>
                  At least one capital letter.
                </PasswordConditionDescTypograpy>
              </PasswordConditionWrapper>

              <PasswordConditionWrapper >
                <img style={webStyle.checkboxIcon} src={this.state.passwordConditions.isLowercase ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                <PasswordConditionDescTypograpy>
                  At least one lowercase letter.
                </PasswordConditionDescTypograpy>
              </PasswordConditionWrapper>

              <PasswordConditionWrapper >
                <img style={webStyle.checkboxIcon} src={this.state.passwordConditions.isNumber ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                <PasswordConditionDescTypograpy>
                  At least one number.
                </PasswordConditionDescTypograpy>
              </PasswordConditionWrapper>

              <PasswordConditionWrapper >
                <img style={webStyle.checkboxIcon} src={this.state.passwordConditions.isLength ? checkboxConditionTrue : checkboxConditionFalse} alt={configJSON.checkboxAlt} />
                <PasswordConditionDescTypograpy>
                  Minimum characters length is 8 characters.
                </PasswordConditionDescTypograpy>
              </PasswordConditionWrapper>
            </Box>
            <Box style={webStyle.buttonbox}>
              <Button style={webStyle.cancelBtn} onClick={this.closeEmailBox}>
                Cancel
              </Button>
              <Button style={webStyle.submitBtn} data-test-id="submitPasswordChange" onClick={this.submitPasswordChange}>
                Submit
              </Button>
            </Box>
          </CustomDialog>
          <CustomDialog open={this.state.isDeleteOpen}>
            <Box style={webStyle.deleteBox}>
              <Close style={webStyle.deleteClose} onClick={this.closeEmailBox} />
              <Typography style={webStyle.headerHeading}>Are you sure you want to delete your account?</Typography>
              <Typography style={webStyle.subTextDelete}>This action can’t be undone and all the data stored in your account won’t be accessible anymore.</Typography>
            </Box>
            <Box style={webStyle.deleteConfirm}>
              <Button style={{ ...webStyle.cancelBtn, color: '#DC2626', width: '199px' }} data-test-id="deleteAccount" onClick={this.deleteAccount}>
                Delete account
              </Button>
              <Button style={{ ...webStyle.submitBtn, width: '199px' }} onClick={this.closeEmailBox}>
                Keep account
              </Button>
            </Box>
          </CustomDialog>

          <AccountSettingBox>
            <Box style={webStyle.optionLabel} data-test-id="handleNotificationBox" onClick={this.handleNotificationBox}>
              <Box>
                <Typography style={webStyle.optionLabelText}>Notification Preferences</Typography>
                <Typography style={webStyle.subheading}>Manage notification preferences like sms notifications, sms recommendations etc.</Typography>
              </Box>
              <ArrowForwardIos
                style={{
                  ...webStyle.expandArrow,
                  transform: this.notiTransform()
                }} />
            </Box>
            <Collapse in={this.state.isNotificationOpen}>
              <Box style={{ ...webStyle.optionLabel, borderBottom: '1px solid #F1F5F9' }}>
                <div>
                  <Typography style={webStyle.subOptions}>SMS Notifications</Typography>
                  <Typography style={webStyle.subheading}>Enable SMS to get notifications for unread messages</Typography>
                </div>
                <div>
                  <div style={styles.container as React.CSSProperties}>
                    <input
                      type="checkbox"
                      style={styles.checkbox as React.CSSProperties}
                      id="checkboxSms"
                      checked={this.state.isSmsNotiChecked}
                      onChange={this.handleSmsToggle}
                    />
                    <label
                      htmlFor="checkboxSms"
                      style={{
                        ...styles.switch,
                        ...(this.state.isSmsNotiChecked && styles.switchChecked),
                      }}
                    >
                      <span
                        style={{
                          ...styles.slider,
                          ...(this.state.isSmsNotiChecked && styles.sliderChecked),
                        } as React.CSSProperties}
                      ></span>
                    </label>
                  </div>
                </div>
              </Box>
              {this.state.roleId==2 && 
              <>
              <Box style={{ ...webStyle.optionLabel, borderBottom: '1px solid #F1F5F9' }}>
                <div>
                  <Typography style={webStyle.subOptions}>SMS Recommendations</Typography>
                  <Typography style={webStyle.subheading}>Enable SMS to get position recommendations based on your job preference</Typography>
                </div>
                <div>
                  <div style={styles.container as React.CSSProperties}>
                    <input type="checkbox"style={styles.checkbox as React.CSSProperties} id="checkboxRecom" checked={this.state.isRecommendationsChecked} onChange={this.handleRecommendationToggle} />
                    <label htmlFor="checkboxRecom" style={{ ...styles.switch, ...(this.state.isRecommendationsChecked && styles.switchChecked)}}>
                      <span style={{...styles.slider, ...(this.state.isRecommendationsChecked && styles.sliderChecked), } as React.CSSProperties}></span>
                    </label>
                  </div>
                </div>
              </Box>
              <Box style={{ ...webStyle.optionLabel }}>
                <div>
                  <Typography style={webStyle.subOptions}>Push Notifications</Typography>
                  <Typography style={webStyle.subheading}>Enable Push Notifications to get position recommendations based on your job preference</Typography>
                </div>
                <div>
                  <div style={styles.container as React.CSSProperties}>
                    <input type="checkbox"
                      onChange={this.handleNotificationToggle}
                      style={styles.checkbox as React.CSSProperties}
                      id="checkboxPush"
                      checked={this.state.isNotificationChecked}
                    />
                    <label
                      style={{
                        ...styles.switch, ...(this.state.isNotificationChecked && styles.switchChecked),
                      }}
                      htmlFor="checkboxPush"
                    >
                      <span
                        style={{ ...styles.slider, ...(this.state.isNotificationChecked && styles.sliderChecked), } as React.CSSProperties} ></span>
                    </label>
                  </div>
                </div>
              </Box>
              </>}
              {this.state.roleId==1 && <>
            
              <Box style={{ ...webStyle.optionLabel }}>
                <div>
                  <Typography style={webStyle.subOptions}>Push Notifications</Typography>
                  <Typography style={webStyle.subheading}>Enable Push Notifications when substitutes apply to my job postings</Typography>
                </div>
                <div>
                  <div style={styles.container as React.CSSProperties}>
                    <input
                      type="checkbox"
                      style={styles.checkbox as React.CSSProperties} id="checkboxPush"
                      checked={this.state.isNotificationChecked} onChange={this.handleNotificationToggle}
                    />
                    <label
                      htmlFor="checkboxPush" style={{
                        ...styles.switch,
                        ...(this.state.isNotificationChecked && styles.switchChecked),
                      }}
                    >
                      <span style={{
                          ...styles.slider,
                          ...(this.state.isNotificationChecked && styles.sliderChecked),
                        } as React.CSSProperties}
                      ></span>
                    </label>
                  </div>
                </div>
              </Box>
              </>}
            </Collapse>
          </AccountSettingBox>

          <AccountSettingBox>
            <Box style={{ paddingLeft: '32px', paddingRight: '32px' }}>
              <Box data-test-id="navigateTermsAndCondition" onClick={this.navigateTermsAndCondition}>
                <Typography style={webStyle.termsText} >Terms and conditions</Typography>
              </Box>
              <Box data-test-id="navigatePrivacyFaq" onClick={()=>this.navigatePrivacyFaq('privacy')}>
                <Typography style={webStyle.termsText}>Privacy policy</Typography>
              </Box>
              <Box data-test-id="navigateFaq" onClick={()=>this.navigatePrivacyFaq('Faq')}>
                <Typography style={{ ...webStyle.termsText, borderBottom: 'unset' }}>Frequently asked questions</Typography>
              </Box>
            </Box>
          </AccountSettingBox>
          <AccountSettingBox data-test-id="handleLogoutClick" onClick={this.handleLogoutClick}>
            <Typography style={{ ...webStyle.optionLabelText, color: '#DC2626', padding: '24px 32px' }}>
              Log out
            </Typography>
          </AccountSettingBox>
          <CustomDialog open={this.state.isLogoutDialogopen}>
            <Box style={webStyle.deleteBox}>
              <Close style={webStyle.deleteClose} onClick={this.closeLogout} />
              <Typography style={webStyle.headerHeading}>Are you sure you want to log out of your account?</Typography>
            </Box>
            <Box style={webStyle.deleteConfirm}>
              <Button style={{ ...webStyle.cancelBtn, width: '150px' }} data-test-id="closeLogout" onClick={this.closeLogout}>
                Cancel
              </Button>
              <Button style={{ ...webStyle.submitBtn, width: '150px' }} data-test-id="handleLogout" onClick={this.handleLogout}>
                Log out
              </Button>
            </Box>
          </CustomDialog>
          <CustomSnackbar
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
            autoHideDuration={2000} 
            open={this.state.isSmsEnable}
            onClose={this.closeSnackbar}
            data-test-id='closeSnackbar'
          >
            <div style={webStyle.popup as React.CSSProperties}>
              <img style={{ marginRight: '10px' }} src={tickicon} alt="" />
              SMS notifications disabled!
            </div>
          </CustomSnackbar>
          <CustomSnackbar
            open={this.state.isSmsDisable}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={this.closeSnackbar}
            autoHideDuration={2000} 
            data-test-id='closeSnackbar'
          >
            <div style={webStyle.popup as React.CSSProperties}>
              <img style={{ marginRight: '10px' }} src={tickicon} alt="" />
              SMS notifications enabled!
            </div>
          </CustomSnackbar>
          <CustomSnackbar
            open={this.state.isRecomdEnable}
            autoHideDuration={2000} 
            data-test-id='closeSnackbar'
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={this.closeSnackbar}
          >
            <div style={webStyle.popup as React.CSSProperties}>
              <img style={{ marginRight: '10px' }} src={tickicon} alt="" />
              SMS Recommendations disabled!
            </div>
          </CustomSnackbar>
          <CustomSnackbar
              data-test-id='closeSnackbar'
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.isRecomdDisable}
            onClose={this.closeSnackbar}
            autoHideDuration={2000} 
          >
            <div style={webStyle.popup as React.CSSProperties}>
              <img style={{ marginRight: '10px' }} src={tickicon} alt="" />
              SMS Recommendations enabled!
            </div>
          </CustomSnackbar>
          <CustomSnackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.isPushEnable}
            autoHideDuration={2000} 
            data-test-id='closeSnackbar'
            onClose={this.closeSnackbar}
          >
            <div style={webStyle.popup as React.CSSProperties}>
              <img style={{ marginRight: '10px' }} src={tickicon} alt="" />
              Notifications disabled!
            </div>
          </CustomSnackbar>
          <CustomSnackbar
              open={this.state.isPushDisable}
              autoHideDuration={2000} 
              data-test-id='closeSnackbar'
              onClose={this.closeSnackbar}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div style={webStyle.popup as React.CSSProperties}>
              <img style={{ marginRight: '10px' }} src={tickicon} alt="" />
              Notifications enabled!
            </div>
          </CustomSnackbar>
        </MainBox>
        <CustomFooter>
        <Footer navigation={this.props.navigation} id={""} NaviagationPage={""} />
        </CustomFooter>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Heading = styled(Typography)({
  color: '#1E293B',
  fontFamily: "Inter",
  fontSize: '30px',
  fontWeight: 700,
  lineHeight: '40px',
  marginBottom: 24,
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})

const NavbarMobile = styled(Box)({
  display: 'none',
  "@media only screen and (max-width: 571px)": {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#395169',
    padding: '15px 0 15px 20px',
    position: 'relative',
  },
});
const CustomNavbar = styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const MainBox = styled(Box)({
  background: "#F8F8F8",
  position: "relative",
  padding: "140px 310px 120px 310px",
  "@media only screen and (max-width: 1330px)": {
    paddingTop: 100,
    paddingLeft: 300,
    paddingRight: 300
  },
  "@media only screen and (max-width: 1235px)": {
    paddingTop: 100,
    paddingLeft: 230,
    paddingRight: 230
  },
  "@media only screen and (max-width: 1100px)": {
    paddingTop: 100,
    paddingLeft: 185,
    paddingRight: 185
  },
  "@media only screen and (max-width: 959px)": {
    paddingTop: 100,
    padding: 100,
  },
  "@media only screen and (max-width: 664px)": {
    padding: 37,
    paddingTop: 100
  },
  "@media only screen and (max-width: 571px)": {
    paddingTop: 20
  }
})
const Navigation = styled(Box)({
  display: "flex",
  gap: "10px",
  position: "absolute" as "absolute",
  top: 32,
  left: 114,
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const NavbarTitle = styled(Box)({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  color: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const AccountSettingBox = styled(Box)(({
  background: '#fff',
  borderRadius: '16px',
  marginBottom: '16px',
  cursor:'pointer'
}))
const CustomDialog = styled(Dialog)({
  borderRadius: '16px',
  '& .MuiDialog-paperWidthSm': {
    minWidth: '600px',
    borderRadius: '16px',
    "@media only screen and (max-width: 816px)": {
      minWidth: '500px'
    },
    "@media only screen and (max-width: 553px)": {
      minWidth: '300px'
    },
  }
})
const ErrorBox = styled(Box)({
  color: '#DC2626',
  height: '18px',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: "18px",
  marginBottom: "2px",
})
const CustomSnackbar = styled(Snackbar)({
  boxShadow: "0px 6px 15px -3px #00000026",
  borderRadius: 8,
  "&.MuiSnackbar-anchorOriginTopCenter": {
    top: 158,
    width: '285px'
  }
})
export const PasswordConditionTitleTypograpy = styled(Typography)({
  fontSize: '14px',
  fontWeight: 700,
  fontfamily: 'Inter',
  color: '#334155',
  margin: 'auto',
  marginTop: '16px',
  marginBottom: '8px',
  '&:first-letter': {
    textTransform: 'uppercase',
  },
  "@media only screen and (max-width: 414px)": {
    width: '327px',
  },
});
export const PasswordConditionWrapper = styled(Typography)({
  width: '384px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '6px',
  "@media only screen and (max-width: 414px)": {
    width: '327px',
  },
});
export const PasswordConditionDescTypograpy = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  color: '#334155',
  "@media only screen and (max-width: 414px)": {
    fontSize: '12px',
  },
});
const CustomFooter=styled(Box)({
  "@media only screen and (max-width: 571px)": {
    display: 'none',
  }
})
const webStyle = {
  joblisting: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  home: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    cursor:'pointer'
  },
  forwardArrow: {
    color: "#395169",
    height: "15px",
    marginTop: 4
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  optionLabelText: {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  expandArrow: {
    height: 21,
    marginTop: 18
  },
  optionLabel: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: '24px 32px',
    // borderBottom: '1px solid #F1F5F9'
  },
  subheading: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  openedBox: {
    borderTop: "1px solid #E2E8F0",
    padding: "24px 32px"
  },
  subOptions: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  dialogHeader: {
    padding: '24px 16px 24px 40px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E2E8F0'
  },
  headerHeading: {
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
  },
  subBox: {
    padding: '32px 40px',
    borderBottom: '1px solid #E2E8F0'
  },
  kindlyFill: {
    color: '#334155',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: '24px'
  },
  textfield: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    marginBottom: '6px'
  },
  buttonbox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 40px'
  },
  cancelBtn: {
    color: '#395169',
    fontFamily: 'Inter',
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: '24px',
    width: '100%',
    background: '#fff',
    textTransform: 'none' as 'none',
    height: '56px',
    cursor: 'pointer',
  },
  submitBtn: {
    color: '#fff',
    background: '#395169',
    width: '100%',
    fontFamily: 'Inter',
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: '24px',
    cursor: 'pointer',
    textTransform: 'none' as 'none',
    height: '56px'
  },
  deleteBox: {
    padding: '60px 40px 32px 40px',
    position: 'relative' as 'relative'
  },
  deleteClose: {
    position: 'absolute' as 'absolute',
    top: 20,
    right: 20,
    cursor:'pointer'
  },
  subTextDelete: {
    color: '#475569',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
    marginTop: '16px'
  },
  deleteConfirm: {
    borderTop: '1px solid #E2E8F0',
    padding: '20px 40px',
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end'
  },
  popup: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    width: '332px',
    padding: '10px 10px 10px 10px',
    gap: '1px',
    borderFadius: '8px',
    boxShadow: '0px 6px 15px -3px #00000026',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#0F172A',
  },
  eyeIcon: {
    height: '24px',
    color: '#94A3B8',
    width: '24px',
  },
  checkboxIcon: {
    width: '16px',
    height: '16px',
    marginRight: '8px',
  },
  termsText: {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    paddingTop: "24px",
    paddingBottom: "24px",
    borderBottom: "1px solid #F1F5F9"
  },
  backIcon: {
    color: '#fff'
  },
  viewBtn:{
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#395169",
    border: "1px solid #395169",
    borderRadius: "8px",
    textTransform: "none" as "none",
    height:'33px',
    cursor:'pointer'
  }
};
const styles = {
  componentTitle: {
    width: '100%',
    position: 'absolute',
    zIndex: 999,
    top: '30px',
    left: 0,
    padding: 0,
    margin: 0,
    fontSize: '1rem',
    fontWeight: 700,
    color: '#888',
    textAlign: 'center',
  },
  container: {
    width: '51px',
    height: '31px',
    position: 'relative',
  },
  checkbox: {
    opacity: 0,
    width: 0,
    height: 0,
    position: 'absolute',
    cursor:'pointer'
  },
  checkRecom: {
    opacity: 0,
    width: 0,
    height: 0,
    position: 'absolute',
  },
  switch: {
    width: '100%',
    height: '100%',
    display: 'block',
    backgroundColor: '#E2E8F0',
    borderRadius: '16px',
    cursor: 'pointer',
    transition: 'all 0.2s ease-out',
  },
  slider: {
    width: '27px',
    height: '27px',
    position: 'absolute',
    left: 'calc(50% - 27px/2 - 10px)',
    top: 'calc(50% - 27px/2)',
    borderRadius: '50%',
    background: '#FFFFFF',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)',
    transition: 'all 0.2s ease-out',
    cursor: 'pointer',
  },
  switchChecked: {
    backgroundColor: '#395169',
  },
  sliderChecked: {
    left: 'calc(50% - 27px/2 + 10px)',
  },

};

// Customizable Area End
