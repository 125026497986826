// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from '../../../framework/src/StorageProvider';
export const configJSON = require("./config");

export interface Props {
    navigation: any;
}
interface Transaction {
    title: string;
    name: string | null;
    amount: string | number | null;
    time: string;
}
interface S {
    transactions: Transaction[];
    currentPage: number;
    totalPages: number;
    totalCount: number;
    paginatedTransactions: Transaction[]
}

interface SS {
    id: any;
}

export default class TransactionController extends BlockComponent<
    Props,
    S,
    SS
> {
    addCreditid: string = "";
    getlistid: string = "";
    itemsPerPage: number = 10;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            transactions: [],
            currentPage: 1,
            totalPages: 1,
            paginatedTransactions: [],  // Transactions for the current page
            totalCount: 0,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            if (apiRequestCallId === this.getlistid) {
                const transactionsData = responseJson.show_transactions.data;
                const totalCount = transactionsData.length;

                const mappedTransactions: Transaction[] = transactionsData.map((item: { attributes: any }) => {
                    const transactionType = item.attributes.transaction_type;

                    if (transactionType === 'credit_deducted') {
                        return {
                            title: 'New Job Post',
                            name: item.attributes.job_creation_title,
                            amount: item.attributes.credit_calculator.credits_deducted + ' Credits',
                            time: item.attributes.credit_calculator.time_ago,
                        };
                    } else if (transactionType === 'credit_added') {
                        return {
                            title: 'New Credits',
                            name: item.attributes.credit_calculator.credits_purchase + ' Credits',
                            amount: item.attributes.amount + ' Dollars',
                            time: item.attributes.credit_calculator.time_ago,
                        };
                    }
                    return null;
                }).filter(Boolean) as Transaction[];

                const totalPages = Math.ceil(mappedTransactions.length / this.itemsPerPage);

                this.setState({
                    transactions: mappedTransactions,
                    totalPages: totalPages,
                    totalCount: totalCount,
                }, () => {
                    this.paginateTransactions();
                });
            }

        }
    }
    paginateTransactions = () => {
        const { currentPage, transactions } = this.state;
        const startIndex = (currentPage - 1) * this.itemsPerPage;
        const paginatedTransactions = transactions.slice(startIndex, startIndex + this.itemsPerPage);

        this.setState({ paginatedTransactions });
    };

    handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        this.setState({ currentPage: newPage }, this.paginateTransactions);
    }
    navigateToHome = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'JobListing'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    }

    async componentDidMount() {
        this.getTransactionList()
    }

    getTransactionList = async () => {
        const header = {
            token: await storage.get("loginToken"),
        };

        const getTeacherForm = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getlistid = getTeacherForm.messageId;

        getTeacherForm.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_joblisting/job_creations/show_transactions`
        );

        getTeacherForm.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        getTeacherForm.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `GET`
        );

        runEngine.sendMessage(getTeacherForm.id, getTeacherForm);
    }
}
// Customizable Area End
