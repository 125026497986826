import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
import { ChangeEvent } from "react";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  Linking,
  Platform
} from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  onClose: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  message: string;
  referralLink: string;
  open: boolean;
  emailError: boolean,
  emailErrorMsg: string,
  messageError: boolean,
  messageErrorMsg: string,
  token: string;
  userId: string;
  formComplete: boolean;
  validations: { validateEmail: boolean; validateMessage: boolean;};
  errors:{
    emailError: string;
    messageError: string;
  },
  isSnackbarOpen:boolean;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class InvitefriendsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      email: '',
      message: '',
      emailError: false,
      messageErrorMsg: "",
      emailErrorMsg: "",
      messageError: false,
      referralLink: 'www.inviteproduct.referrals/132324433453454',
      open: true,
      token: '',
      userId: '',
      formComplete: false,
      validations: 
        { validateEmail: false, 
          validateMessage: false,
        },
        errors:{
          emailError: "",
          messageError: "",
        },
        isSnackbarOpen:false
    };
    this.emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);


    this.handleSendInvites = this.handleSendInvites.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const loginId = await storage.get("loginId")
    const token = await storage.get("loginToken")
    this.setState({ token: token, userId: loginId })
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ email: value }, this.validateEmail);
};

handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ message: value }, this.validateMessage);
};
validateEmail = () => {
    const { email } = this.state;
    let emailError = false;
    let emailErrorMsg = '';
    let validations = { ...this.state.validations, validateEmail: true };

    if (!email) {
        emailError = true;
        emailErrorMsg = 'Enter a valid email address';
        validations.validateEmail = false;
    } else if (!this.emailReg.test(email)) {
        emailError = true;
        emailErrorMsg = 'Invalid email format';
        validations.validateEmail = false;
    }

    this.setState({
        emailError,
        emailErrorMsg,
        validations,
        errors: { ...this.state.errors, emailError: emailErrorMsg }
    }, this.validateForm);
};

validateMessage = () => {
    const { message } = this.state;
    let messageError = false;
    let messageErrorMsg = '';
    let validations = { ...this.state.validations, validateMessage: true };

    if (!message) {
        messageError = true;
        messageErrorMsg = 'Enter a message';
        validations.validateMessage = false;
    }

    this.setState({
        messageError,
        messageErrorMsg,
        validations,
        errors: { ...this.state.errors, messageError: messageErrorMsg }
    }, this.validateForm);
};

getHelperText = (error: boolean, errorMsg: string) => {
    return error ? errorMsg : '';
};

  validateForm = () => {
    const { email, message } = this.state;
    const formComplete = email !== '' && message !== '';
    this.setState({ formComplete });
  };

handleSendInvites() {
  let valid = true;

  if (!this.state.email) {
      this.setState({ emailErrorMsg: configJSON.errorMsgPleaseEnterEmail });
      valid = false;
  } else if (!this.emailReg.test(this.state.email)) {
      this.setState({ emailErrorMsg: configJSON.errorMsgInvalidEmail });
      valid = false;
  }

  if (!this.state.message) {
      this.setState({ messageErrorMsg: configJSON.errorMsgPleaseEnterMessage });
      valid = false;
  }
  if (!valid) return false;

  const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: this.state.token,
  };

  const attrs = {
      email: this.state.email,
      message: this.state.message,
  };

  const httpBody = {
      account_id: this.state.userId,
      invitation: attrs,
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.sendInvitesApiId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendInvitesAPIEndPoint
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  this.setState({
    isSnackbarOpen:true,
    email: "",
    message: "",
    emailError: false,
    emailErrorMsg: "",
    messageError: false,
    messageErrorMsg: "",
    formComplete: false, 
});

  return true;
}
 
  handleMailAction = () => {
    let phoneOS = Platform.OS === "android" ? "?" : "&";
    let mailTo = `mailto:foo@example.com?cc=${phoneOS}subject=yourSubject${phoneOS}body=yourMessage`;
  };

  handleSMSAction = () => {
    let phoneOS = Platform.OS === "android" ? "?" : "&";
    let sms = `sms:8885555512${phoneOS}body=This is a Dummy Message`;
  };

  handleWhatsAppAction = () => {
    let whatsapp = `whatsapp://send?phone='8885555512'&text='Hello Message'`;
  };

  btnEmailIconProps = {
    onPress: () => {
      this.handleMailAction();
    }
  };

  btnWhatsAppIconProps = {
    onPress: () => {
      this.handleWhatsAppAction();
    }
  };

  btnSMSIconProps = {
    onPress: () => {
      this.handleSMSAction();
    }
  };
  sendInvitesApiId: string = "";
  emailReg: RegExp;
  token: string = "";

  // Customizable Area End

}
