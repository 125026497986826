// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from '../../../framework/src/StorageProvider';

export const configJSON = require("./config");

export interface Props {
    navigation: any;
}

interface S {
    cardNumber: string;
    valid: string;
    cvvnumber: string;
    cardHolderName: string;
    dollarAmount: string;
    creditAmount: number;
    creditPublish: boolean;
    cardNumberError: string;
    validError: string;
    cvvError: string;
    cardHolderNameError: string;
    dollarAmountError: string;
    jobTitle: string;
    organisation: string;
    education: string;
    experience: number | string;
    jobdescription: string;
    state: string;
    city: string;
    zipcode: string;
    country: string;
    aboutOrg: string;
    street: string;
    openPreview:boolean;
    firstname:string;
    lastname:string;
    notenough:boolean;
    savedAddress:boolean;
    postingFee:number;
}

interface SS {
    id: any;
}

export default class PaymentsController extends BlockComponent<
    Props,
    S,
    SS
> {
    addCreditid: string = ""
    addePostId:string=''
    getPostingfeeId:string=''
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            cardNumber: '',
            valid: '',
            cvvnumber: '',
            cardHolderName: '',
            dollarAmount: '',
            creditAmount: 0,
            creditPublish: false,
            cardNumberError: '',
            validError: '',
            cvvError: '',
            cardHolderNameError: '',
            dollarAmountError: '',
            jobTitle: '',
            organisation: '',
            education: '',
            experience: '',
            jobdescription: '',
            state: '',
            city: '',
            zipcode: '',
            country: '',
            aboutOrg: '',
            street: '',
            openPreview:false,
            firstname:'',
            lastname:'',
            notenough:false,
            savedAddress:false,
            postingFee:0
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(authTokenReq);
        this.setState({jobTitle: await storage.get('jobTitle'), organisation:await storage.get('organisation'), education:await storage.get('education'), experience:await storage.get('experience'),
            jobdescription:await storage.get('jobdescription'), city:await storage.get('city'), street:await storage.get('street'), country:await storage.get('country'), zipcode: await storage.get('zipcode'),
            state:await storage.get('state'), aboutOrg:await storage.get('aboutOrg'), firstname: await storage.get('firstname'), lastname:await storage.get('lastname'), savedAddress:await storage.get('savedAddress')
        })
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            runEngine.debugLog("API Message Recived", message);
            if (apiRequestCallId == this.addCreditid) {
                if (responseJson.success) {
                    this.createPost()
                }
            }
            if(apiRequestCallId==this.addePostId){
                if(responseJson.error){
                    this.setState({notenough:true})
                }else {
                    storage.remove('jobTitle'); storage.remove('organisation'); storage.remove('education'); storage.remove('experience'); storage.remove('jobdescription');storage.remove('lastname');
                    storage.remove('street'); storage.remove('city'); storage.remove('country'); storage.remove('state'); storage.remove('zipcode'); storage.remove('aboutOrg'); storage.remove('firstname');
                    storage.remove('endDate'); storage.remove('startDate'); storage.remove('savedAddress')
                    this.setState({ creditPublish: true })
                }
            }
            if(apiRequestCallId==this.getPostingfeeId){
                if(responseJson.deducted_credits)
                    {this.setState({postingFee:responseJson.deducted_credits})}
            }
        }
    }

    createPost=async ()=>{
        const header = {
            token: await storage.get('loginToken')
          };
          const formData = new FormData();
          formData.append("job_creation[education_level]", this.state.education);
          formData.append("job_creation[organization]", this.state.organisation);
          formData.append("job_creation[job_title]", this.state.jobTitle);
          formData.append("job_creation[country]", this.state.country);
          formData.append("job_creation[city]", this.state.city);
          formData.append("job_creation[state]", this.state.state);
          formData.append("job_creation[account_id]", await storage.get("loginId"));
          formData.append("job_creation[zip_code]", this.state.zipcode+'');
          formData.append("job_creation[experience_required]", this.state.experience.toString());
          formData.append("job_creation[street_address]", this.state.street);
          formData.append("job_creation[job_description]", this.state.jobdescription);
          formData.append("job_creation[about_organization]", this.state.aboutOrg);
          formData.append("job_creation[start_date]", await storage.get('startDate'));
          formData.append("job_creation[end_date]", await storage.get('endDate'));
          formData.append("job_creation_params[use_my_save_address]", this.state.savedAddress.toString());
          const workingDays = JSON.parse(await storage.get('workingDays'));
          if (workingDays && workingDays.length > 0) {
            workingDays.forEach((day: { working_day_id: { toString: () => string | Blob; }; start_time: string | Blob; end_time: string | Blob; full_day: any; }, index: any) => {
              formData.append(`job_creation[working_days_attributes][][working_day_id]`, day.working_day_id.toString());
              formData.append(`job_creation[working_days_attributes][][start_time]`, day.start_time);
              formData.append(`job_creation[working_days_attributes][][end_time]`, day.end_time);
              formData.append(`job_creation[working_days_attributes][][full_day]`, day.full_day ? 'true' : 'false');
            });
          }
    
          const setIntroMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
          this.addePostId = setIntroMsg.messageId;
          setIntroMsg.addData(  getName(MessageEnum.RestAPIResponceEndPointMessage),`bx_block_joblisting/job_creations` );
          setIntroMsg.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );
          setIntroMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
          setIntroMsg.addData( getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
          runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    }
    navigateToHome = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'JobListing'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    }
    formatCardNumber = (value: string) => {
        return value
            .replace(/\s?/g, '')
            .replace(/(\d{4})/g, '$1 ')
            .trim();
    };

    handleCardNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value.replace(/\D/g, '');
        if (input.length <= 16) {
            this.setState({ cardNumber: this.formatCardNumber(input) });
        }
    };

    formatValidDate = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d{0,2})$/, '$1/$2')
            .slice(0, 5);
    };

    handleValidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        this.setState({ valid: this.formatValidDate(input) });
    };

    handleCVVChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value.replace(/\D/g, '');
        if (input.length <= 3) {
            this.setState({ cvvnumber: input });
        }
    };

    handleCardHolderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;

        const sanitizedInput = input
            .replace(/[^a-zA-Z\s]/g, '')
            .slice(0, 32);

        this.setState({ cardHolderName: sanitizedInput });
    };

    handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value.replace(/[^\d.]/g, '');
        const dollarAmount = parseFloat(input);
        const creditAmount = isNaN(dollarAmount) ? 0 : dollarAmount / 5;

        this.setState({ dollarAmount: input, creditAmount });
    };
    validateDate = () => {
        const { valid } = this.state;
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear() % 100;
        const currentMonth = currentDate.getMonth() + 1;
      
        const [month, year] = valid.split('/').map(Number);
      
        if (year < currentYear || (year === currentYear && month < currentMonth)) {
          this.setState({ validError: 'The card is expired.' });
          return false;
        } else if (month < 1 || month > 12) {
          this.setState({ validError: 'Invalid month.' });
          return false;
        }
      
        this.setState({ validError: '' });
        return true;
      };
      

    publishCredit = async () => {
        let valid = true;

        if (this.state.cardNumber.length < 19) {
            this.setState({ cardNumberError: "Please enter a valid card number" });
            valid = false;
        } else {
            this.setState({ cardNumberError: "" });
        }

        if (this.state.valid.length < 5) {
            this.setState({ validError: "Please enter a valid expiration date" });
            valid = false;
        } else {
            this.setState({ validError: "" });
        }


        if (this.state.cvvnumber.length < 3) {
            this.setState({ cvvError: 'Please enter a valid CVV' });
            valid = false;
        } else {
            this.setState({ cvvError: "" });
        }


        if (!this.state.cardHolderName.trim()) {
            this.setState({ cardHolderNameError: "Card holder name cannot be empty" });
            valid = false;
        } else {
            this.setState({ cardHolderNameError: "" });
        }

        if (!this.state.dollarAmount || parseFloat(this.state.dollarAmount) <= 0) {
            this.setState({ dollarAmountError: "Please enter a valid amount" });
            valid = false;
        } else {
            this.setState({ dollarAmountError: "" });
        }

        if (!this.validateDate()) {
            valid = false;
        }

        if (valid) {
            const [exp_month, exp_year] = this.state.valid.split("/");
            const header = {
                "Content-Type": configJSON.exampleApiContentType,
                token: await storage.get('loginToken'),
            };
            const body = {
                "amount": this.state.dollarAmount,
                "card": {
                    "number": this.state.cardNumber,
                    "exp_month": exp_month,
                    "exp_year": exp_year,
                    "cvc": this.state.cvvnumber,
                    "card_holder_name": this.state.cardHolderName
                }
            }

            const setIntroMsg = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.addCreditid = setIntroMsg.messageId;

            setIntroMsg.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `bx_block_joblisting/job_creations/add_credit`
            );

            setIntroMsg.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            setIntroMsg.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );

            setIntroMsg.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "POST"
            );

            runEngine.sendMessage(setIntroMsg.id, setIntroMsg);

        }
    };


    navigateBack = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'PostCreation'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    }

    openPreview=async ()=>{
        this.setState({openPreview:true})
        const header = {
            "Content-Type": 'application/json',
            token: await storage.get('loginToken')
        };

        const setIntroMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPostingfeeId = setIntroMsg.messageId;
        setIntroMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_joblisting/job_creations/show_deducted_credits`
        );

        setIntroMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        setIntroMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(setIntroMsg.id, setIntroMsg);   
    }

}
// Customizable Area End
