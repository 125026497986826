// Customizable Area Start
import React from "react";
import VerificationController, {
    Props
} from "./VerificationController";

import {
    Box,
    Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
export const configJSON = require("./config");
import { wholeBackgroundImage, wholeBackgroundImageforMobile, valid, warn } from "./assets";
// Customizable Area End

export default class VerificationSentPending extends VerificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <MainBox>
                <InnerBoxOne>
                    {this.state.IsApplied && 
                    <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={valid}></img>
                    </div>
                    <HeadingOne>Successfully applied!</HeadingOne>
                    <HeadingTwo>Thank You for your application,
                        We'll review it and
                        let you know when you are Approved!
                    </HeadingTwo>
                    </>
                    }
                    {
                        this.state.IsApproved && <>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={warn}></img>
                        </div>
                        <HeadingOne>Verification in progress...</HeadingOne>
                        <HeadingTwo>Your account is being validated by the admin, please try once you have received the confirmation email
                        </HeadingTwo>
                        </>
                    }
                    <Typography style={webStyle.backlogin} data-test-id="handlelogin" onClick={this.handlelogin}>
                        Back to login
                    </Typography>

                </InnerBoxOne>
            </MainBox>
            // Customizable Area End
        );
    }
}
// Customizable Area Start

export const MainBox = styled(Box)({
    justifyContent: 'center',
    background: `url(${wholeBackgroundImage})`,
    height: "100vh",
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    backgroundSize: 'cover',
    alignItems: 'center',
    backgroundPosition: 'center',
    position: "relative",
    "@media only screen and (max-width: 450px)": {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        background: `url(${wholeBackgroundImageforMobile})`,
    },
});

const HeadingOne = styled(Typography)({
    color: '#1E293B',
    fontWeight: 700,
    lineHeight: '40px',
    size: '30px',
    fontFamily: "Inter",
    textAlign: 'center',
    fontSize: '30px',
    marginTop:'16px',
    '@media only screen and (max-width: 502px)': {
        lineHeight: '32px',
        fontSize: '24px',
    },
})
const HeadingTwo = styled(Typography)({
    color: '#334155',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: "Inter",
    fontWeight: 400,
    textAlign: 'center',
    marginTop:'16px',
    '@media only screen and (max-width: 502px)': {
        lineHeight: '22px',
        fontSize: '14px',
    },
})
const InnerBoxOne = styled(Box)({
    width: "450px",
    '@media only screen and (max-width: 439px)': {
        width: "327px"
    },
    '@media only screen and (max-width: 341px)': {
        width: "278"
    },
})

const webStyle = {
    createBtn: {
        marginBottom: '96px',
        color: '#FFFFFF',
        fontWeight: 700,
        marginTop: '48px',
        lineHeight: '24px',
        fontFamily: "Inter",
        fontSize: '16px',
        textTransform: 'none' as 'none',
        background: '#395169',
        height: '56px',
        borderRadius: '8px',
        widht: '100%',
    },

    backlogin: {
        color: '#395169',
        fontWeight: 700,
        lineHeight: '24px',
        fontFamily: "Inter",
        fontSize: '16px',
        marginTop: '32px',
        display: 'flex',
        justifyContent: 'center',
        cursor:"pointer"
    }
}

// Customizable Area End
