import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Dialog,
    Typography,
    styled,
    useMediaQuery,
    Slide
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
export const NoInternet = () => {
    const [isActive , setIsActive] = useState(false)
    const isMobile = useMediaQuery("(max-width: 600px)");
    useEffect(() => {
        const updateNetworkStatus = () => {
            if (navigator.onLine) {
                setIsActive(false)
            } else {
               setIsActive(true)
            }
        };

        updateNetworkStatus();

        window.addEventListener('online', updateNetworkStatus);
        window.addEventListener('offline', updateNetworkStatus);

        return () => {
            window.removeEventListener('online', updateNetworkStatus);
            window.removeEventListener('offline', updateNetworkStatus);
        };
    }, []);

    const handleSubmit = ()=>{
        const path = location.pathname
        location.href = path
    }   
    return (
        <>
            <CustomDialog
                open={isActive}
                TransitionComponent={isMobile ? SlideUpTransition : undefined}
                fullScreen={isMobile}
                PaperProps={{
                    style: {
                        position:isMobile?"fixed":"relative",
                        bottom:isMobile ? '0':"",
                        height :"376px",
                        overflow:"hidden",
                        borderRadius: isMobile?"24px 24px 0px 0px":"24px"
                    }
                }}
            >
                <Box style={{ gap: "15px", padding: "50px 25px", display: "flex", flexDirection: "column" ,position:"relative"}}>
                <CloseIcon 
                style={{
                    position:'absolute',
                    right:"20px",
                    top:"20px",
                }}
                onClick={()=> setIsActive(!isActive)}
                />
                    <Box>
                        <img src={require("./nointernet.png")} alt="" />
                    </Box>
                    <Box>
                        <Typography
                            style={{
                                fontWeight: 700,
                                color: "#1E293B",
                                fontFamily: "inter",
                                fontSize: "24px",
                                lineHeight: "32px",
                                letterSpacing: "-0.12px"
                            }}
                        >
                            Oops! No internet <br /> connection
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            style={{
                                fontWeight: 400,
                                color: "#475569",
                                fontFamily: "inter",
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "-0.12px"
                            }}
                        >
                            Make sure wi-fi or cellular data is turned <br /> on and then please try again.
                        </Typography>
                    </Box>
                    <Box style={{marginTop:"26px"}}>
                        <Button
                            style={webStyle.submitBtn}
                            onClick={() => handleSubmit()} 
                            data-test-id="submitTestId"
                        >
                            Try Again
                        </Button>
                    </Box>
                </Box>
            </CustomDialog>
        </>
    );
};

const CustomDialog = styled(Dialog)({
    borderRadius: "16px",
    "& .MuiDialog-paperWidthSm": {
        minWidth: "360px",
        borderRadius: "16px",
        "@media only screen and (max-width: 816px)": {
            minWidth: "360px"
        },
        "@media only screen and (max-width: 553px)": {
            minWidth: "300px"
        }
    }
});

const SlideUpTransition = (props: any) => {
    return <Slide direction="up" {...props} />;
};

const webStyle = {
    submitBtn: {
        color: "#fff",
        background: "#395169",
        width: "100%",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        cursor: "pointer",
        textTransform: "none" as "none",
        height: "56px"
    }
};