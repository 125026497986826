// Customizable Area Start
import React from "react";
import { Box, TextField, Typography, styled, Grid } from "@material-ui/core"
import TransactionController, { Props } from "./TransactionController";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web"
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { logo, noData } from './assets'
import CustomePagination from "../../../components/src/CustomPagination.web";


export default class Transaction extends TransactionController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { paginatedTransactions, totalCount, currentPage, totalPages } = this.state;

        return (
            <>
                <CustomNavbar>
                    <Navbar navigation={this.props.navigation} id={""} NaviagationPage={""} />
                </CustomNavbar>
                <CustomProfileHead>
                    <ArrowBackIos data-test-id='btnBack' onClick={this.navigateToHome} style={webStyle.backIcon} />
                    <NavbarTitle>
                        <img src={logo} />
                    </NavbarTitle>
                </CustomProfileHead>
                <MainBox>
                    <div>
                        <Navigation>
                            <Typography data-test-id="navigateToHome" onClick={this.navigateToHome} style={webStyle.home}>Home</Typography>
                            <ArrowForwardIos style={webStyle.forwardArrow} />
                            <Typography style={webStyle.joblisting}>Transactions</Typography>
                        </Navigation>
                        <ProfileHeading>
                            <PostHeading>Transactions</PostHeading>
                        </ProfileHeading>

                        <InnerBox container spacing={2}>
                            {this.state.transactions.length > 0 ? <>
                                {this.state.paginatedTransactions.map((item) => (
                                    <>
                                        <Grid item xs={12} sm={5} style={{ paddingBottom: "15px", paddingTop: '15px' }}>
                                            <Box>
                                                <Typography style={webStyle.name}>{item.title}</Typography>
                                            </Box>
                                        </Grid >
                                        <Grid item xs={4} sm={3} style={{ paddingBottom: "15px", paddingTop: '15px' }}>
                                            <Box style={webStyle.align}>
                                                <Typography style={{ ...webStyle.name, fontSize: '16px' }}>{item.name}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={6} sm={4} style={webStyle.lastBox}>
                                            <Typography style={webStyle.amount}>{item.amount}</Typography>
                                            <Typography style={{ ...webStyle.amount, fontSize: '14px' }}>{item.time}</Typography>
                                        </Grid>
                                    </>
                                ))}

                            </> :
                                <>
                                    <Box style={webStyle.noResultBox}>
                                        <Box>
                                            <img src={noData} />
                                            <Typography style={webStyle.noResultText}>
                                                No data found!
                                            </Typography>

                                        </Box>
                                    </Box>
                                </>}

                        </InnerBox>
                        {totalCount >= this.itemsPerPage && (
                            <CustomePagination
                                total_page={totalPages}
                                current_page={currentPage}
                                total_count={totalCount}
                                onchange={this.handlePageChange}
                            />
                        )}

                    </div>
                </MainBox>
                <Footer navigation={this.props.navigation} id={""} NaviagationPage={""} />
            </>
        );
    }
}


const PostHeading = styled(Typography)({
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    "@media only screen and (max-width: 414px)": {
        fontSize: '16px'
    },
})
const CustomNavbar = styled(Box)({
    "@media only screen and (max-width: 657px)": {
        display: 'none'
    },
})
const CustomProfileHead = styled(Box)({
    display: 'none',
    "@media only screen and (max-width: 657px)": {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#395169',
        padding: 26,
        position: 'relative',
        paadingBottom: 37
    }
})
const MainBox = styled(Box)({
    background: "#F8F8F8",
    position: "relative",
    padding: "140px 208px 120px 208px",
    "@media only screen and (max-width: 1330px)": {
        padding: 100,
    },
    "@media only screen and (max-width: 688px)": {
        paddingLeft: 29,
        paddingRight: 29
    },
    "@media only screen and (max-width: 571px)": {
        padding: '29px'
    },
    "@media only screen and (max-width: 404px)": {
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: '29px',
        paddingTop: '29px'
    },
    "@media only screen and (max-width: 375px)": {
        paddingLeft: 4,
        paddingRight: 4,
        paddingBottom: '29px',
        paddingTop: '29px'
    }
})
const InnerBox = styled(Grid)({
    background: '#fff',
    borderRadius: '16px',
    marginTop: '20px',
    padding: '30px 15px',
    display: 'flex',
    justifyContent: 'center'
})
const Navigation = styled(Box)({
    display: "flex",
    gap: "10px",
    position: "absolute" as "absolute",
    top: 32,
    left: 114,
    "@media only screen and (max-width: 657px)": {
        left: 38
    },
    "@media only screen and (max-width: 571px)": {
        display: 'none',
    }
})
const ProfileHeading = styled(Box)({
    marginBottom: "32px",
    display: "flex",
    justifyContent: 'space-between',

})
const NavbarTitle = styled(Box)({
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#FFFFFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  });
const webStyle = {
    joblisting: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    backIcon:{
        color:'#fff'
    },
    home: {
        color: "#475569",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    forwardArrow: {
        color: "#395169",
        height: "15px",
        marginTop: 4
    },
    steps: {
        color: '#475569',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    textheading: {
        color: '#64748B',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        marginBottom: 6
    },
    name: {
        color: '#1E293B',
        fontWeight: 700,
        fontSize: '20px',
        fontFamily: 'Inter'
    },
    align: {
        display: 'flex',
        alignItems: 'center'
    },
    amount: {
        color: '#64748B',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Inter',
    },
    lastBox: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: "15px", paddingTop: '15px'
    },
    noResultBox: {
        background: "#fff",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center" as "center",
        alignItem: "center" as "center",
        paddingTop: 88,
        paddingBottom: 128,
    },
    noResultText: {
        color: "0F172A",
        fontWeight: 600,
        marginTop: "16px",
        fontFamily: "Inter",
        fontSize: "20px",
        lineHeight: "28px",
    },
}

export const InputField = styled(TextField)({
    marginBottom: "32px",

    "& .MuiOutlinedInput-input": {
        "&::placeholder": {
            fontWeight: 500,
            fontFamily: 'Inter',
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'left',
        },
    },
    "& .MuiInputBase-input": {
        color: '#1E293B',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
    }
})
// Customizable Area End
