Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "rolesandpermissions2";
exports.labelBodyText = "rolesandpermissions2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.heading="Select your role";
exports.subHeading="Please select user role to continue...";
exports.continueBtn="Continue";
exports.alreadyAccount="Already have an account?";
exports.login="Login";
exports.back="Back"
// Customizable Area End